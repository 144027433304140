import { h, Component } from "preact";

import Chart from "chart.js/auto";
import { useEffect, useRef } from "preact/hooks";

const BarChart = ({ label, data }) => {
  const chartRef = useRef(null);
  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    const maxIndex = data.indexOf(Math.max(...data));
    new Chart(ctx, {
      type: "bar",
      data: {
        labels: label,
        datasets: [
          {
            data: data,
            backgroundColor: data.map((_, index) =>
              index === maxIndex ? "rgba(21, 73, 203, 1)" : "#E9EDF7"
            ),
            borderRadius: 10,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false,
            },
          },
          x: {
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        maxBarThickness: 32,
      },
    });
  }, []);

  return <canvas ref={chartRef} />;
};

export default BarChart;
