import { h, Component } from "preact";
import { useState } from "preact/hooks";
import ReactDatamaps from "react-india-states-map";

const MapChart = ({ STATES }) => {
  const [activeState, setactiveState] = useState({
    data: STATES.Maharashtra.value,
    name: "Maharashtra",
  });

  const [stateLists, setStateLists] = useState(STATES);

  const stateOnClick = (data, name) => {
    setactiveState({ data, name });
  };

  return (
    <ReactDatamaps
      regionData={STATES}
      mapLayout={{
        hoverTitle: "Count",
        noDataColor: "#4782E0",
        borderColor: "#ffffff",
        hoverBorderColor: "pink",
        hoverColor: "#1549CB",
      }}
      hoverComponent={({ value }) => {
        return (
          <>
            <p className="text-white">{value.name}</p>
            <p className="text-white">{STATES[value.name]?.value}</p>
          </>
        );
      }}
      onHover={({ value }) => {
        stateHover(value.name);
      }}
    />
  );
};

export default MapChart;
