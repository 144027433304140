import { h } from "preact";
import style from "../manage-teams/style.css";
import { Icon } from "@iconify/react";
import { useState } from "preact/hooks";
import Header from "../../../components/header";
import SpocDashboardTop from "../../../components/spocDashboardTop";
import { Link } from "react-router-dom";
import Table from "../../../components/table";
import Modal from "../../../components/modal";
import {
  createNewIdea,
  deleteIdea,
  editIdea,
  getIdea,
  getIdeas,
  getTeamsForSubmission,
} from "../../../modules/spoc/actions";
import { useEffect } from "react";
import { uploadFile } from "../../../modules/upload/actions";
import Pagination from "../../../components/pagination";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../../redux/slices/loadingSlice";
import DonutChart from "../../../components/graphs/donutChart";

const ManageSubmission = () => {
  const [ideaModalOpen, setIdeaModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [showTango, setShowTango] = useState(true);
  const createIdea = () => {
    try {
      setSubmissionValues({
        title: "",
        processFlow: "",
        category: "",
        theme: "",
        technologyBucket: "",
        document: "",
        supportDocument: "",
        videoLink: "",
        team: "",
      });
      setIdeaModalOpen(true);
      setModalType("createIdea");
    } catch (e) {
      console.log(e);
    }
  };
  const editSubmission = async (id) => {
    try {
      const response = await getIdea(id);
      if (response?.success === true) {
        setIdeaModalOpen(true);
        setModalType("updateIdea");
        setSubmissionValues({
          title: "",
          processFlow: "",
          category: "",
          theme: "",
          technologyBucket: "",
          document: "",
          supportDocument: "",
          videoLink: "",
          team: "",
          ...response?.data,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const previewSubmission = async (id) => {
    try {
      const response = await getIdea(id);
      if (response?.success === true) {
        setIdeaModalOpen(true);
        setModalType("previewIdea");
        setSubmissionValues(response?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const updateSubmission = async () => {
    try {
      // console.log("submissionValues", submissionValues);
      setModalLoading(true);
      const urlFilter =
        /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})+)(:\d{1,5})?([/?].*)?$/;
      const valURL = urlFilter.test(submissionValues?.videoLink);
      if (submissionValues.title === "") {
        toast.error("Title is required");
      } else if (submissionValues.team === "") {
        toast.error("Team is required");
      } else if (submissionValues.theme === "") {
        toast.error("Theme is required");
      } else if (submissionValues.document === "") {
        toast.error("Idea PPT is required");
      } else if (submissionValues.processFlow === "") {
        toast.error("Description is required");
      } else if (submissionValues.technologyBucket === "") {
        toast.error("Technology Bucket is required");
      } else if (submissionValues.category === "") {
        toast.error("Category is required");
      } else if (
        submissionValues?.videoLink != "" &&
        submissionValues?.videoLink?.includes(" ")
      ) {
        toast.error("Spaces are not allowed in the Video Link.");
      } else if (submissionValues?.videoLink != "" && !valURL) {
        toast.error("Video Link is not Valid");
      } else {
        const response = await editIdea({
          ...submissionValues,
          submission: submissionValues._id,
        });
        if (response?.success === true) {
          setIdeaModalOpen(false);
          fetchData(currentPage, searchFilter, statusFilter);
        } else {
          toast.error(response?.message);
        }
      }
    } catch (e) {
      console.log(e);
    }
    setModalLoading(false);
  };
  const [submissionValues, setSubmissionValues] = useState({
    title: "",
    processFlow: "",
    category: "",
    theme: "",
    technologyBucket: "",
    document: "",
    supportDocument: "",
    videoLink: "",
    team: "",
  });

  // Change Handler
  const changeHandler = (e) => {
    let val = e.target.value;
    setSubmissionValues((prevValues) => {
      return { ...prevValues, [e.target.name]: val };
    });
  };

  const [modalLoading, setModalLoading] = useState(false);
  // Create Submission
  const createNewSubmission = async () => {
    try {
      setModalLoading(true);
      const urlFilter =
        /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})+)(:\d{1,5})?([/?].*)?$/;
      const valURL = urlFilter.test(submissionValues.videoLink);
      if (submissionValues.title === "") {
        toast.error("Title is required");
      } else if (submissionValues.team === "") {
        toast.error("Team is required");
      } else if (submissionValues.theme === "") {
        toast.error("Theme is required");
      } else if (submissionValues.document === "") {
        toast.error("Idea PPT is required");
      } else if (submissionValues.processFlow === "") {
        toast.error("Description is required");
      } else if (submissionValues.technologyBucket === "") {
        toast.error("Technology Bucket is required");
      } else if (submissionValues.category === "") {
        toast.error("Category is required");
      } else if (
        submissionValues?.videoLink != "" &&
        submissionValues?.videoLink?.includes(" ")
      ) {
        toast.error("Spaces are not allowed in the Video Link.");
      } else if (submissionValues?.videoLink != "" && !valURL) {
        toast.error("Video Link is not Valid");
      } else {
        const response = await createNewIdea(submissionValues);

        if (response?.success === true) {
          setIdeaModalOpen(false);
          fetchData(currentPage, searchFilter, statusFilter);
        } else {
          toast.error(response?.message);
        }
      }
    } catch (e) {
      console.log(e);
    }
    setModalLoading(false);
  };

  // get Teams
  const [allTeams, setAllTeams] = useState([]);
  const getTeams = async () => {
    try {
      const response = await getTeamsForSubmission();
      if (response?.success === true) {
        setAllTeams(response?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getTeams();
  }, []);

  // Upload PPT
  const uploadPPT = async (event) => {
    setModalLoading(true);
    const file = event.target.files[0];
    const fileSize = file.size;
    const maxSize = 1024 * 1024 * 2;
    if (fileSize > maxSize) {
      toast.error("File size should be less than 2 MB.");
      event.target.value = "";
      event.target.files = [];
      return;
    }

    if (file.type === "application/pdf") {
      if (file) {
        dispatch(setGlobalLoading(true));
        try {
          const formData = new FormData();
          formData.append("file", file);
          const response = await uploadFile(formData);
          if (response?.success === true) {
            setSubmissionValues((prevValues) => {
              return { ...prevValues, document: response?.URL };
            });
          } else {
            toast.error("File Uploading Error try uploading another file.");
          }
        } catch (error) {
          console.error(`Error uploading file: ${error}`);
        }
      }
    } else {
      toast.error("Consent Letter must be a PDF");
    }
    setModalLoading(false);
    dispatch(setGlobalLoading(false));
  };
  // Upload Support
  const uploadSupport = async (event) => {
    setModalLoading(true);
    const file = event.target.files[0];
    const fileSize = file.size;
    const maxSize = 1024 * 1024 * 2;
    if (fileSize > maxSize) {
      toast.error("File size should be less than 2 MB.");
      event.target.value = "";
      event.target.files = [];
      return;
    }

    if (file.type === "application/pdf") {
      if (file) {
        dispatch(setGlobalLoading(true));
        try {
          const formData = new FormData();
          formData.append("file", file);
          const response = await uploadFile(formData);
          if (response?.success === true) {
            // console.log(response);
            setSubmissionValues((prevValues) => {
              return { ...prevValues, supportDocument: response?.URL };
            });
          } else {
            toast.error("File Uploading Error try uploading another file.");
          }
        } catch (error) {
          console.error(`Error uploading file: ${error}`);
        }
      }
    } else {
      toast.error("Consent Letter must be a PDF");
    }
    setModalLoading(false);
    dispatch(setGlobalLoading(false));
  };

  // Filter
  const [statusFilter, setStatusFilter] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const pageChangeHandler = (page) => {
    try {
      setCurrentPage(page);
    } catch (e) {
      console.log(e);
    }
  };
  // Idea listing
  const [tableContent, setTableContent] = useState([]);
  const [tier1Submissions, setTier1Submissions] = useState(0);
  const [tier2Submissions, setTier2Submissions] = useState(0);
  const [graphData, setGraphData] = useState(null);

  const [mainLoading, setMainLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const dispatch = useDispatch();
  const fetchData = async (page, searchFilter, statusFilter) => {
    dispatch(setGlobalLoading(true));
    try {
      setMainLoading(true);
      const response = await getIdeas(page, searchFilter, statusFilter);
      if (response?.success === true) {
        setTableContent(response?.data);
        setTotalPages(response?.pages);
        setTier1Submissions(response?.stats.submissionsTier1);
        setTier2Submissions(response?.stats.submissionsTier2);
        setGraphData([
          response?.stats.nominationsTier1 + response?.stats.nominationsTier2,
          response?.stats.submissionsTier1 +
            response?.stats.submissionsTier2 -
            (response?.stats.nominationsTier1 +
              response?.stats.nominationsTier2),
        ]);
      }
    } catch (e) {
      console.log(e);
    }
    setMainLoading(false);
    dispatch(setGlobalLoading(false));
  };

  useEffect(() => {
    fetchData(currentPage, searchFilter, statusFilter);
  }, [currentPage]);

  // Confirmation
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [activeTeam, setActiveTeam] = useState("");
  const confirmation = (id) => {
    setIsConfirmationModalOpen(true);
    setActiveTeam(id);
  };
  const deleteSubmission = async (id) => {
    try {
      const response = await deleteIdea({ submission: id });
      if (response?.success === true) {
        fetchData(currentPage, searchFilter, statusFilter);
        setIsConfirmationModalOpen(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const filterFetching = () => {
    let newStatusFilter = "";
    if (document.getElementById("pending").checked === true) {
      newStatusFilter = newStatusFilter + "CREATED" + ",";
    }
    if (document.getElementById("created").checked === true) {
      newStatusFilter = newStatusFilter + "COMPLETED" + ",";
    }
    if (document.getElementById("nominated").checked === true) {
      newStatusFilter = newStatusFilter + "NOMINATED" + ",";
    }
    newStatusFilter = newStatusFilter.slice(0, -1);
    setStatusFilter(newStatusFilter);
    setCurrentPage(1);

    fetchData(1, searchFilter, newStatusFilter);
  };

  return (
    <>
      <Header page="spoc-dashboard" />
      <img
        src="https://saxenaprachi.github.io/publicAssets/images/shuttleRocket.webp"
        className={style.rocketRight}
      />
      <div className={style.spocDashboard}>
        <SpocDashboardTop />

        <div className={style.spocDasboardContent}>
          <div className={style.tabsOuterBox}>
            {/* Tab buttons */}
            <div className={style.tabs}>
              <Link to="/spoc/dashboard">DASHBOARD</Link>
              <Link to="/spoc/stats">STATISTICS</Link>
              <Link to="/spoc/manage-teams">MANAGE TEAMS</Link>
              <Link to="/spoc/manage-submissions" className={`${style.active}`}>
                MANAGE SUBMISSIONS
              </Link>
              {/* <Link to="/spoc/others">OTHERS</Link> */}
            </div>

            <hr className={style.horizontalLine} />
            {/* Tab content */}
            <div className={style.allTabsContentOuter}>
              <div>
                <h4 className={style.pageTitle}>MANAGE SUBMISSIONS</h4>
                <div className={style.mainConatiner}>
                  {/* Stats */}
                  <div className={style.statConatiner}>
                    <div className={style.stat}>
                      Submissions - Track 1
                      <div className={style.statNumber}>{tier1Submissions}</div>
                    </div>
                    <div className={style.stat}>
                      Submissions - Track 2
                      <div className={style.statNumber}>{tier2Submissions}</div>
                    </div>
                    <div className={style.stat}>
                      Total
                      <div className={style.statNumber}>
                        {tier1Submissions + tier2Submissions}
                      </div>
                    </div>
                  </div>

                  {/* Search and Create Button */}
                  <div className={style.tableHeadConatiner}>
                    <div className={style.tableTitle}>
                      Submission Management
                    </div>
                    <div className={style.searchConatiner}>
                      <div className={`search`}>
                        <input
                          name="search"
                          id="search"
                          placeholder="Search By Idea Title,Team & Theme Name,"
                          defaultValue={searchFilter}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              document.getElementById("searchButton").click();
                            }
                          }}
                        />
                        <div
                          id="searchButton"
                          className="searchIcon"
                          onClick={() => {
                            setSearchFilter(
                              document.getElementById("search").value
                            );
                            setCurrentPage(1);
                            fetchData(
                              1,
                              document.getElementById("search").value,
                              statusFilter
                            );
                          }}
                        >
                          <Icon icon="carbon:search" />
                        </div>
                      </div>
                      <div
                        className="filterContainer"
                        onClick={() => {
                          setFilterOpen(true);
                        }}
                      >
                        <img
                          src="https://saxenaprachi.github.io/publicAssets/icons/filter.svg"
                          alt="svg"
                        />
                        {filterOpen && (
                          <div
                            className="filter"
                            onMouseLeave={() => {
                              setFilterOpen(false);
                            }}
                          >
                            {/* Filter By Status */}
                            <div>
                              Filter By Status
                              <p>
                                {statusFilter.includes("CREATED") ? (
                                  <input
                                    type="checkbox"
                                    value="pending"
                                    id="pending"
                                    onChange={filterFetching}
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    value="pending"
                                    id="pending"
                                    onChange={filterFetching}
                                  />
                                )}
                                &nbsp; <label htmlFor="pending">Pending</label>
                              </p>
                              <p>
                                {statusFilter.includes("COMPLETED") ? (
                                  <input
                                    type="checkbox"
                                    value="created"
                                    id="created"
                                    onChange={filterFetching}
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    value="created"
                                    id="created"
                                    onChange={filterFetching}
                                  />
                                )}
                                &nbsp; <label htmlFor="created">Created</label>
                              </p>
                              <p>
                                {statusFilter.includes("NOMINATED") ? (
                                  <input
                                    type="checkbox"
                                    value="nominated"
                                    id="nominated"
                                    onChange={filterFetching}
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    value="nominated"
                                    id="nominated"
                                    onChange={filterFetching}
                                  />
                                )}
                                &nbsp;{" "}
                                <label htmlFor="nominated">Nominated</label>
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={` btn orangeBtn`} onClick={createIdea}>
                        Create Idea
                      </div>
                    </div>
                  </div>

                  {mainLoading ? (
                    <>Loading...</>
                  ) : (
                    <>
                      {/* Table  */}
                      <Table
                        tableName="submissionsList"
                        tableHeader={[
                          "Submission Title",
                          "Team Name",
                          "Theme",
                          "Status",
                          "PPT",
                          "Preview",
                          "Action",
                        ]}
                        tableContent={tableContent}
                        editSubmission={editSubmission}
                        previewSubmission={previewSubmission}
                        confirmation={confirmation}
                      />
                    </>
                  )}
                </div>

                <div className={style.paginationContainer}>
                  {/* Pagination */}
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={pageChangeHandler}
                  />
                </div>

                {/* Reference STATUS */}
                <div className={style.referenceBox}>
                  <div className={style.referenceHeading}>REFERENCES</div>
                  <table>
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Description</th>
                        <th>Edit Access</th>
                        <th>View Access</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span className={`${style.statusBtn} ${style.orangeStatus}`}>PENDING</span>
                        </td>
                        <td>Idea submission was started, but all the required details were not filled.</td>
                        <td><Icon icon="charm:tick" /></td>
                        <td><Icon icon="charm:tick" /></td>
                      </tr>
                      <tr>
                        <td>
                          <span className={`${style.statusBtn} ${style.blueStatus}`}> CREATED</span>
                        </td>
                        <td>The idea has been successfully submitted on the platform.</td>
                        <td><Icon icon="charm:tick" /></td>
                        <td><Icon icon="charm:tick" /></td>
                      </tr>
                      <tr>
                        <td>
                          <span className={`${style.statusBtn} ${style.extraGreenStatus}`}>NOMINATED</span>
                        </td>
                        <td>The idea has been successfully nominated for the next round of evaluation.</td>
                        <td className={style.crossIcon}><Icon icon="charm:cross"  /></td>
                        <td><Icon icon="charm:tick" /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {tableContent.length > 0 && (
                  <div className={style.nominateButtonContainer}>
                    <div className={style.nominationText}>
                      <h4>NOMINATE TEAM IDEAS</h4>
                      <p>
                        After you've uploaded all your idea submissions, the
                        next step is to select the top 30 ideas from each track,
                        i.e. Track 1 and Track 2, from your school for
                        evaluation in the upcoming round of SIH Junior Hackathon
                        2023.
                      </p>
                      <p className="textRed">
                        Please note: Once an idea is nominated, it will be
                        locked, and no further edits can be made to the idea or
                        the associated teams. Proceed only after carefully
                        reviewing all the ideas.
                      </p>
                      <br />
                      <div>
                        <Link
                          to="/spoc/nominate-team"
                          className={`btn orangeBtn`}
                        >
                          Nominate Idea
                        </Link>
                      </div>
                    </div>
                    <div className={style.nominationStats}>
                      <h4>Nominated Ideas</h4>
                      <div className={style.graphConatiner}>
                        {graphData && (
                          <DonutChart
                            label={["Ideas Nominated", "Ideas not Nominated"]}
                            data={graphData}
                            colors={["#F69E45", "#1C1777"]}
                            cutout={"65%"}
                          />
                        )}
                      </div>
                      <h5 className="text-center">
                        {graphData && (
                          <>
                            {graphData[0]} out of {graphData[0] + graphData[1]}{" "}
                            Ideas nominated
                          </>
                        )}
                      </h5>
                      <div className={style.labelsConatiner}>
                        <h5>Ideas Nominated</h5>
                        <h5>Ideas Not Nominated</h5>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {ideaModalOpen && (
        <>
          <Modal
            type={"ideaModal"}
            close={() => {
              setIdeaModalOpen(false);
            }}
            confirmationValue={modalType}
            submissionValues={submissionValues}
            changeHandler={changeHandler}
            allTeams={allTeams}
            createNewSubmission={createNewSubmission}
            uploadPPT={uploadPPT}
            uploadSupport={uploadSupport}
            updateSubmission={updateSubmission}
            loading={modalLoading}
          />
        </>
      )}
      {isConfirmationModalOpen && (
        <>
          <Modal
            confirmationMessage={"Do you want to delete this Submission?"}
            confirmationValue={"deleteSubmission"}
            deleteSubmission={deleteSubmission}
            type={"confimationMail"}
            teamToDelete={activeTeam}
            close={() => {
              setIsConfirmationModalOpen(false);
            }}
          />
        </>
      )}
      {tier1Submissions + tier2Submissions === 0 && showTango && (
        <div className={style.modal}>
          <div className={style.modal_content}>
            <div className={style.headerOfModal}>
              <h4>Platform Guide - SIH Junior</h4>
              <span
                className={style.close}
                onClick={() => {
                  setShowTango(false);
                }}
              >
                &times;
              </span>
            </div>
            <div>
              <iframe
                src="https://app.tango.us/app/embed/8a8a3064-7813-47b5-92a1-d856e9652c85?skipCover=false&defaultListView=false&skipBranding=true"
                sandbox="allow-scripts allow-top-navigation-by-user-activation allow-popups allow-same-origin"
                security="restricted"
                title="SIH JuniorHackathon - Team Creation and Idea Submission"
                width="100%"
                height="100%"
                referrerpolicy="strict-origin-when-cross-origin"
                frameborder="0"
                webkitallowfullscreen="webkitallowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                allowfullscreen="allowfullscreen"
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageSubmission;
