import { h, Component } from "preact";
import Header from "../../../components/header";
import style from "./style.css";
import { useState } from "preact/hooks";
import { Icon } from "@iconify/react";
import { Link, resolvePath, useNavigate } from "react-router-dom";
import { uploadFile } from "../../../modules/upload/actions";
import { toast } from "react-hot-toast";
import {
  addTeamMember,
  createTeam,
  deleteTeamMember,
  getTeam,
  updateTeam,
  updateTeamMember,
} from "../../../modules/spoc/actions";
import { useEffect } from "react";
import AddMember from "../../../components/addMember";
import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../../redux/slices/loadingSlice";

const CreateTeam = () => {
  const navigate = useNavigate();
  //  Mentor & Team Member Count
  const [mentorCount, setMentorCount] = useState(0);
  const [memberCount, setMemberCount] = useState(0);

  const [teamId, setTeamId] = useState("");
  const [members, setMembers] = useState([]);

  // Get Team
  const getThisTeam = async (id) => {
    try {
      const response = await getTeam(id);

      setTeamId(id);
      setCreateTeamValues({
        teamName: response?.data.teamName,
        teamTrack: response?.data.tier,
        consentLetter: response?.data.consentLetter,
      });
      let mentCount = 0;
      let mem = [];
      for (let i = 0; i < response?.data?.members.length; i++) {
        if (response?.data?.members[i].role === "Mentor") {
          mentCount++;
          if (mentCount === 1) {
            setMentor1Values({
              class: response?.data?.members[i].class,
              email: response?.data?.members[i].email,
              experience: response?.data?.members[i].experience,
              expertise: response?.data?.members[i].expertise,
              gender: response?.data?.members[i].gender,
              lastPerformance: response?.data?.members[i].lastPerformance,
              medium: response?.data?.members[i].medium,
              mentorType: response?.data?.members[i].mentorType,
              name: response?.data?.members[i].name,
              phone: response?.data?.members[i].phone,
              role: response?.data?.members[i].role,
              stream: response?.data?.members[i].stream,
              member: response?.data?.members[i]._id,
            });
          }
          if (mentCount === 2) {
            setMentor2Values({
              class: response?.data?.members[i].class,
              email: response?.data?.members[i].email,
              experience: response?.data?.members[i].experience,
              expertise: response?.data?.members[i].expertise,
              gender: response?.data?.members[i].gender,
              lastPerformance: response?.data?.members[i].lastPerformance,
              medium: response?.data?.members[i].medium,
              mentorType: response?.data?.members[i].mentorType,
              name: response?.data?.members[i].name,
              phone: response?.data?.members[i].phone,
              role: response?.data?.members[i].role,
              stream: response?.data?.members[i].stream,
              member: response?.data?.members[i]._id,
            });
          }
        }
        if (
          response?.data?.members[i].role === "Member" ||
          response?.data?.members[i].role === "Leader"
        ) {
          mem.push(response?.data?.members[i]);
        }
      }

      setMentorCount(mentCount);
      if (mem.length === 0) {
        mem.push({
          class: "",
          email: "",
          experience: "",
          expertise: "",
          gender: "",
          lastPerformance: "",
          medium: "",
          mentorType: "",
          name: "",
          phone: "",
          role: "",
          stream: "",
          dob: "",
        });
      }
      setMembers([...mem]);
      setMemberCount(mem.length);
    } catch (e) {
      console.log(e);
    }
  };

  // UseEffect
  useEffect(() => {
    if (localStorage.getItem("teamId")) {
      getThisTeam(localStorage.getItem("teamId"));
    }
  }, []);

  // Stepper
  const [step, setStep] = useState(1);

  // Create Team Form
  const [createTeamValues, setCreateTeamValues] = useState({
    teamName: "",
    teamTrack: "",
    consentLetter: "",
  });

  const createTeamChangeHandler = (e) => {
    setCreateTeamValues((prevValues) => {
      return { ...prevValues, [e.target.name]: e.target.value };
    });
  };
  const [teamLoading, setTeamLoading] = useState(false);
  const createNewTeam = async () => {
    setTeamLoading(true);
    try {
      if (createTeamValues.teamName === "") {
        toast.error("Team Name is required");
      } else if (createTeamValues.teamTrack === "") {
        toast.error("Team Track is required");
      } else if (createTeamValues.consentLetter === "") {
        toast.error("Consent Letter is required");
      } else {
        const response = await createTeam({
          teamName: createTeamValues.teamName,
          tier: createTeamValues.teamTrack,
          consentLetter: createTeamValues.consentLetter,
        });
        if (response?.success === true) {
          setTeamId(response?.data);
          localStorage.setItem("teamId", response?.data);
          setStep(2);
          getThisTeam(response?.data);
        } else {
          toast.error(response?.message);
        }
      }
    } catch (e) {
      console.log(e);
    }
    setTeamLoading(false);
  };

  const updateThisTeam = async () => {
    setTeamLoading(true);
    try {
      if (createTeamValues.teamName === "") {
        toast.error("Team Name is required");
      } else if (createTeamValues.teamTrack === "") {
        toast.error("Team Track is required");
      } else if (createTeamValues.consentLetter === "") {
        toast.error("Consent Letter is required");
      } else {
        const response = await updateTeam({
          team: localStorage.getItem("teamId"),
          teamName: createTeamValues.teamName,
          tier: createTeamValues.teamTrack,
          consentLetter: createTeamValues.consentLetter,
        });
        if (response?.success === true) {
          setTeamId(response?.data);
          localStorage.setItem("teamId", response?.data);
          setStep(2);
          getThisTeam(response?.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
    setTeamLoading(false);
  };

  // Add Mentor Form
  const [mentor1Values, setMentor1Values] = useState({
    member: "",
    name: "",
    role: "Mentor",
    email: "",
    phone: "",
    stream: "",
    class: "",
    gender: "",
    medium: "",
    lastPerformance: "",
    mentorType: "",
    experience: "",
    expertise: "",
    dob: "",
  });

  const mentor1ChangeHandler = (e) => {
    let val = e.target.value;
    if (e.target.name === "phone") {
      val = val.slice(0, 10);
      e.target.value = val;
    }
    setMentor1Values((prevValues) => {
      return { ...prevValues, [e.target.name]: val };
    });
  };
  const [mentor2Values, setMentor2Values] = useState({
    member: "",
    name: "",
    role: "Mentor",
    email: "",
    phone: "",
    stream: "",
    class: "",
    gender: "",
    medium: "",
    lastPerformance: "",
    mentorType: "",
    experience: "",
    expertise: "",
    dob: "",
  });

  const mentor2ChangeHandler = (e) => {
    let val = e.target.value;
    if (e.target.name === "phone") {
      val = val.slice(0, 10);
      e.target.value = val;
    }
    setMentor2Values((prevValues) => {
      return { ...prevValues, [e.target.name]: val };
    });
  };

  const addMentor1 = async () => {
    try {
      // Email Testing
      let filter =
        /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
      var valEmailMentor1 = String(mentor1Values.email).search(filter) != -1;

      // Phone Testing
      let phoneFilter = /^\d{10}$/;
      var valPhoneMentor1 = phoneFilter.test(mentor1Values.phone);

      if (mentor1Values.name === "") {
        toast.error("Mentor 1 Name is required.");
        return false;
      } else if (mentor1Values.email === "") {
        toast.error("Mentor 1 email is required.");
        return false;
      } else if (!valEmailMentor1) {
        toast.error("Mentor 1 email is not Valid.");
        return false;
      } else if (mentor1Values.phone === "") {
        toast.error("Mentor 1 Phone is required.");
        return false;
      } else if (!valPhoneMentor1) {
        toast.error("Mentor 1 Phone is not Valid.");
        return false;
      } else if (mentor1Values.mentorType === "") {
        toast.error("Mentor Type is required.");
        return false;
      } else if (mentor1Values.member === "") {
        const response = await addTeamMember({
          team: teamId,
          ...mentor1Values,
        });
        if (response?.success === true) {
          setMentor1Values((prevValues) => {
            return {
              ...prevValues,
              member: response?.data,
            };
          });
          return true;
        }
      } else if (mentor1Values.member != "") {
        const response = await updateTeamMember({
          team: teamId,
          ...mentor1Values,
        });
        if (response?.success === true) {
          return true;
        } else {
          toast.error(response?.message);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const addMentor2 = async () => {
    // Email Testing
    let filter =
      /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    var valEmailMentor1 = String(mentor2Values.email).search(filter) != -1;

    // Phone Testing
    let phoneFilter = /^\d{10}$/;
    var valPhoneMentor1 = phoneFilter.test(mentor2Values.phone);

    if (mentor2Values.name === "") {
      toast.error("Mentor 2 Name is required.");
    } else if (mentor2Values.email === "") {
      toast.error("Mentor 2 email is required.");
    } else if (!valEmailMentor1) {
      toast.error("Mentor 2 email is not Valid.");
    } else if (mentor2Values.phone === "") {
      toast.error("Mentor 2 Phone is required.");
    } else if (!valPhoneMentor1) {
      toast.error("Mentor 2 Phone is not Valid.");
    } else if (mentor2Values.mentorType === "") {
      toast.error("Mentor Type is required.");
    } else if (mentor2Values.member === "") {
      const response = await addTeamMember({
        team: teamId,
        ...mentor2Values,
      });
      if (response?.success === true) {
        setMentor2Values((prevValues) => {
          return {
            ...prevValues,
            member: response?.data,
          };
        });
        return true;
      } else {
        toast.error(response?.message);
      }
    } else if (mentor2Values.member != "") {
      const response = await updateTeamMember({
        team: teamId,
        ...mentor2Values,
      });
      if (response?.success === true) {
        return true;
      } else {
        toast.error(response?.message);
      }
    }
  };

  const [mentorLoading, setMentorLoading] = useState(false);

  const addMentors = async () => {
    setMentorLoading(true);
    if (mentorCount === 1 || mentorCount === 0) {
      const res = await addMentor1();
      if (res) {
        setStep(3);
      }
    } else if (mentorCount === 2) {
      const res = await addMentor1();
      const res2 = await addMentor2();
      if (res && res2) {
        setStep(3);
      }
    }
    setMentorLoading(false);
  };

  const removeMentor2 = async () => {
    if (mentor2Values.member === "") {
      setMentor2Values({
        member: "",
        name: "",
        role: "Mentor",
        email: "",
        phone: "",
        stream: "",
        class: "",
        gender: "",
        medium: "",
        lastPerformance: "",
        mentorType: "",
        experience: "",
        expertise: "",
        dob: "",
      });
      setMentorCount(1);
    } else if (mentor2Values.member != "") {
      try {
        const response = await deleteTeamMember({
          team: teamId,
          member: mentor2Values.member,
        });
        if (response?.success === true) {
          setMentor2Values({
            member: "",
            name: "",
            role: "Mentor",
            email: "",
            phone: "",
            stream: "",
            class: "",
            gender: "",
            medium: "",
            lastPerformance: "",
            mentorType: "",
            experience: "",
            expertise: "",
            dob: "",
          });
          setMentorCount(1);
        } else {
          toast.error(response?.message);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  // Upload Images
  const dispatch = useDispatch();
  const uploadConsent = async (event) => {
    const file = event.target.files[0];
    const fileSize = file.size;
    const maxSize = 1024 * 1024;
    if (fileSize > maxSize) {
      toast.error("File size should be less than 1 MB.");
      event.target.value = "";
      event.target.files = [];
      return;
    }

    if (file.type === "application/pdf") {
      if (file) {
        dispatch(setGlobalLoading(true));
        try {
          const formData = new FormData();
          formData.append("file", file);
          const response = await uploadFile(formData);
          if (response?.success === true) {
            setCreateTeamValues((prevValues) => {
              return { ...prevValues, consentLetter: response?.URL };
            });
          } else {
            toast.error("File Uploading Error try uploading another file.");
          }
        } catch (error) {
          console.error(`Error uploading file: ${error}`);
        }
      }
    } else {
      toast.error("Consent Letter must be a PDF");
    }
    dispatch(setGlobalLoading(false));
  };

  //   Add Member
  const addMem = async () => {
    for (let i = 0; i < members.length; i++) {
      // Email Testing
      let filter =
        /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
      var valEmailMentor1 = String(members[i]?.email).search(filter) != -1;

      // Phone Testing
      let phoneFilter = /^\d{10}$/;
      var valPhoneMentor1 = phoneFilter.test(members[i]?.phone);

      const currentDate = new Date("2020-12-31");
      const minDate = new Date("1995-01-01");
      const dobDate = new Date(members[i]?.dob);
      // console.log(dobDate, minDate,currentDate);
      if (members[i]?.name === "") {
        toast.error(`Member ${i + 1} Name is required.`);
        return false;
      } else if (members[i]?.email === "") {
        toast.error(`Member ${i + 1} email is required.`);
        return false;
      } else if (!valEmailMentor1) {
        toast.error(`Member ${i + 1} email is not Valid.`);
        return false;
      } else if (members[i]?.phone === "") {
        toast.error(`Member ${i + 1} Phone is required.`);
        return false;
      } else if (!valPhoneMentor1) {
        toast.error(`Member ${i + 1} Phone is not Valid.`);
        return false;
      }
      // else if (members[i]?.dob === "") {
      //   toast.error(`Member ${i + 1} Date of Birth is required.`);
      //   return false;
      // }
      else if (!members[i]?.dob) {
        toast.error(`Member ${i + 1} Date of Birth is required.`);
        return false;
      } else if (dobDate < minDate) {
        toast.error(
          `Member ${i + 1} Date of Birth must be after January 1, 1995.`
        );
        return false;
      } else if (dobDate > currentDate) {
        toast.error(
          `Member ${i + 1} Date of Birth must be before 31st December 2020`
        );
        return false;
      } else if (members[i]?.gender === "") {
        toast.error(`Member ${i + 1} Gender is required.`);
        return false;
      } else if (members[i]?.class === "") {
        toast.error(`Member ${i + 1} Class is required.`);
        return false;
      } else if (members[i]?.class > 10 && members[i]?.stream === "") {
        toast.error(`Member ${i + 1} Stream is required.`);
        return false;
      } else if (members[i]?.role === "") {
        toast.error(`Member Type is required.`);
        return false;
      } else if (members[i]?._id) {
        try {
          const response = await updateTeamMember({
            ...members[i],
            team: teamId,
            member: members[i]?._id,
            class: parseInt(members[i]?.class),
          });
          if (response?.success != true) {
            toast.error(response?.message);
            return false;
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          const response = await addTeamMember({ ...members[i], team: teamId });
          members[i]._id = response?.data;
          let count = memberCount + 1;
          setMemberCount(count);
          if (response?.success != true) {
            toast.error(response?.message);
            return false;
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
    return true;
  };
  const [addAnotherLoading, setAddAnotherLoading] = useState();
  const addAnotherMember = async () => {
    setAddAnotherLoading(true);
    const response = await addMem();
    if (response) {
      const allMembers = members;
      allMembers.push({
        class: "",
        email: "",
        experience: "",
        expertise: "",
        gender: "",
        lastPerformance: "",
        medium: "",
        mentorType: "",
        name: "",
        phone: "",
        role: "",
        stream: "",
        member: "",

        dob: "",
        _id: "",
      });
      setMembers([...allMembers]);
    }
    setAddAnotherLoading(false);
  };
  const updateMember = (index, property, value, event) => {
    let val = value;
    if (property === "phone") {
      val = val.slice(0, 10);
      event.target.value = val;
      value = val;
    }
    if (property === "dob") {
      val = new Date(value).toISOString();
    }
    if (property === "class") {
      val = parseInt(value);
    }
    const updatedArray = [...members];
    updatedArray[index][property] = val;
    setMembers(updatedArray);
  };
  const [addMembersLoading, setAddMembersLoading] = useState(false);
  const done = async () => {
    if (members.length < 3) {
      toast.error(`Atleast 3 Members are required.`);
      return false;
    }

    setAddMembersLoading(true);
    let noLeader = true;
    let leaderCount = 0;
    for (let i = 0; i < members.length; i++) {
      if (members[i].role === "Leader") {
        noLeader = false;
        leaderCount += leaderCount;
      }
    }
    if (noLeader) {
      toast.error(`Atleast 1 Leader is required.`);
      setAddMembersLoading(false);
      return false;
    } else if (leaderCount > 1) {
      toast.error(`You cannot add more than 1 Leader`);
      setAddMembersLoading(false);
      return false;
    }
    const response = await addMem();
    if (response) {
      localStorage.removeItem("teamId");
      navigate("/spoc/manage-teams");
    }
    setAddMembersLoading(false);
  };

  const removeMember = async (index) => {
    if (members[index]?._id != "") {
      try {
        const response = await deleteTeamMember({
          team: teamId,
          member: members[index]?._id,
        });
        if (response?.success === true) {
          const updatedArray = members.filter((_, i) => i !== index);
          setMembers(updatedArray);
          setMentorCount(1);
        } else {
          toast.error(response?.message);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      const updatedArray = members.filter((_, i) => i !== index);
      setMembers(updatedArray);
      setMemberCount(1);
    }
  };

  const goBack = () => {
    localStorage.removeItem("teamId");
    navigate("/spoc/manage-teams");
  };
  return (
    <>
      <Header page="spoc-dashboard" />
      <img
        src="https://saxenaprachi.github.io/publicAssets/images/shuttleRocket.webp"
        className={style.rocketRight}
      />

      <div className={style.pageContainer}>
        <div className={style.formConatiner}>
          <div onClick={goBack} className={style.close}>
            <Icon icon="maki:cross" />
          </div>

          {/* Heading */}
          <div className={style.Heading}> Add Team</div>
          <p className={style.description}>
            * You can only add a maximum of 2 mentors and 6 team members,
            including the team leader.
          </p>
          <div className="redStarFields my-05">* Indicates required fields</div>

          {/* Stats */}
          <div className={style.statConatiner}>
            <div className={style.stat}>
              Total Mentors{" "}
              <div className={style.statNumber}> {mentorCount} </div>
            </div>
            <div className={style.stat}>
              Total Members{" "}
              <div className={style.statNumber}> {memberCount} </div>
            </div>
          </div>

          {/* Wizard */}
          <div className={style.wizardContainer}>
            {/* 1 */}
            <div
              className={`${style.singleStepContainer} ${
                step >= 1 ? style.active : ""
              }`}
            >
              <div className={style.numberContainer}>
                {step > 1 ? (
                  <Icon icon="material-symbols:done-rounded" color="white" />
                ) : (
                  1
                )}
              </div>
              <div className={style.stepNameContainer}>Team Creation</div>
            </div>
            {/* divider */}
            <div className={`${style.stepDivider}`}></div>
            {/* 2 */}
            <div
              className={`${style.singleStepContainer} ${
                step >= 2 ? style.active : ""
              }`}
            >
              <div className={style.numberContainer}>
                {step > 2 ? (
                  <Icon icon="material-symbols:done-rounded" color="white" />
                ) : (
                  2
                )}
              </div>
              <div className={style.stepNameContainer}>Mentor Details</div>
            </div>
            {/* divider */}
            <div
              className={`${style.stepDivider} ${
                step === 3 ? style.active : ""
              }`}
            ></div>
            {/* 3 */}
            <div
              className={`${style.singleStepContainer} ${
                step >= 3 ? style.active : ""
              }`}
            >
              <div className={style.numberContainer}>
                {step > 3 ? (
                  <Icon icon="material-symbols:done-rounded" color="white" />
                ) : (
                  3
                )}
              </div>
              <div className={style.stepNameContainer}>Member Details</div>
            </div>
          </div>
          <br />
          <br />
          {/* Step 1 */}
          {step === 1 && (
            <div className={style.overallStepFormContainer}>
              <div className={`${style.individualForm}`}>
                {/* Team Name */}
                <div className={style.formGroup}>
                  <label>
                    Team Name <span>*</span>
                  </label>
                  <input
                    className={style.formInput}
                    type="text"
                    onChange={createTeamChangeHandler}
                    name="teamName"
                    defaultValue={createTeamValues.teamName}
                    maxLength={255}
                  />
                </div>

                {/* Team Track */}
                <div className={style.formGroup}>
                  <label>
                    Team Track <span>*</span>
                  </label>
                  <select
                    onChange={createTeamChangeHandler}
                    name="teamTrack"
                    value={createTeamValues.teamTrack}
                  >
                    <option value="" selected disabled>
                      --Select One --
                    </option>
                    <option value="Tier-1"> Track 1(Class 6-9)</option>
                    <option value="Tier-2"> Track 2(Class 10-12)</option>
                  </select>
                </div>

                {/* Consent Letter */}
                <div className={style.formGroup}>
                  <label>
                    Consent Letter <span>*</span>
                  </label>
                  <input
                    className={style.formInput}
                    type="file"
                    accept=".pdf"
                    onChange={uploadConsent}
                  />
                  {createTeamValues.consentLetter ? (
                    <span className={style.letterDispaly}>
                      Letter uploaded sucessfully! &nbsp; &nbsp;
                      <Link to={createTeamValues.consentLetter} target="_blank">
                        Preview
                      </Link>
                    </span>
                  ) : (
                    <span className={style.letterDispaly}>
                      School Consent Letter &nbsp;
                      <Link
                        className={style.guideLink}
                        to="https://saxenaprachi.github.io/publicAssets/teamConsentLetter/School Authorization letter_SIH2023.docx"
                        download
                      >
                        Download Template
                      </Link>
                    </span>
                  )}
                </div>
              </div>
              <div className={style.buttonContainer}>
                {teamId ? (
                  <>
                    {teamLoading ? (
                      <button className={`btn orangeBtn`}>Loading...</button>
                    ) : (
                      <button
                        className={`btn orangeBtn`}
                        onClick={updateThisTeam}
                      >
                        Next Section
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    {teamLoading ? (
                      <button className={`btn orangeBtn`}>Loading...</button>
                    ) : (
                      <button
                        className={`btn orangeBtn`}
                        onClick={createNewTeam}
                      >
                        Next Section
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          {/* Step 2*/}
          {step === 2 && (
            <div className={style.overallStepFormContainer}>
              {/* Mentor 1 */}
              <div className={`${style.individualForm} ${style.memberForm}`}>
                <div className={style.overallFormHeading}>Mentor 1 Details</div>
                {/* Mentor Name */}
                <div className={style.formGroup}>
                  <label>
                    Mentor Name <span>*</span>
                  </label>
                  <input
                    className={style.formInput}
                    type="text"
                    onChange={mentor1ChangeHandler}
                    name="name"
                    defaultValue={mentor1Values.name}
                    maxLength={255}
                  />
                </div>

                {/* Mentor Email */}
                <div className={style.formGroup}>
                  <label>
                    Mentor Email <span>*</span>
                  </label>
                  <input
                    className={style.formInput}
                    type="text"
                    onChange={mentor1ChangeHandler}
                    name="email"
                    defaultValue={mentor1Values.email}
                  />
                </div>

                {/* Mentor Phone */}
                <div className={style.formGroup}>
                  <label>
                    Mentor Phone <span>*</span>
                  </label>
                  <input
                    className={style.formInput}
                    type="text"
                    onChange={mentor1ChangeHandler}
                    name="phone"
                    defaultValue={mentor1Values.phone}
                  />
                </div>

                {/* Mentor Type */}
                <div className={style.formGroup}>
                  <label>
                    Mentor Type <span>*</span>
                  </label>
                  <select
                    onChange={mentor1ChangeHandler}
                    name="mentorType"
                    value={mentor1Values.mentorType}
                  >
                    <option value="" selected disabled>
                      --Select One --
                    </option>
                    <option> Parent</option>
                    <option> Industry</option>
                    <option> Acedemic</option>
                  </select>
                </div>

                {/* Mentor Experience */}
                <div className={style.formGroup}>
                  <label>Mentor Experience</label>
                  <input
                    className={style.formInput}
                    type="text"
                    onChange={mentor1ChangeHandler}
                    name="experience"
                    defaultValue={mentor1Values.experience}
                  />
                </div>

                {/* Mentor Expertise */}
                <div className={style.formGroup}>
                  <label>Mentor Expertise</label>
                  <input
                    className={style.formInput}
                    type="text"
                    onChange={mentor1ChangeHandler}
                    name="expertise"
                    defaultValue={mentor1Values.expertise}
                    maxLength={255}
                  />
                </div>
              </div>
              {mentorCount < 2 && (
                <div
                  className={style.addAnother}
                  onClick={async () => {
                    const res = await addMentor1();
                    if (res) {
                      setMentorCount(2);
                    }
                  }}
                >
                  Add Another Mentor
                </div>
              )}
              {mentorCount === 2 && (
                <>
                  {/* Mentor 2 */}
                  <div
                    className={`${style.individualForm} ${style.memberForm}`}
                  >
                    <div
                      className={style.closeIndividualForm}
                      onClick={removeMentor2}
                    >
                      <Icon icon="maki:cross" />
                    </div>
                    <div className={style.overallFormHeading}>
                      Mentor 2 Details
                    </div>
                    {/* Mentor Name */}
                    <div className={style.formGroup}>
                      <label>
                        Mentor Name <span>*</span>
                      </label>
                      <input
                        className={style.formInput}
                        type="text"
                        onChange={mentor2ChangeHandler}
                        name="name"
                        defaultValue={mentor2Values.name}
                        maxLength={255}
                      />
                    </div>

                    {/* Mentor Email */}
                    <div className={style.formGroup}>
                      <label>
                        Mentor Email <span>*</span>
                      </label>
                      <input
                        className={style.formInput}
                        type="text"
                        onChange={mentor2ChangeHandler}
                        name="email"
                        defaultValue={mentor2Values.email}
                      />
                    </div>

                    {/* Mentor Phone */}
                    <div className={style.formGroup}>
                      <label>
                        Mentor Phone <span>*</span>
                      </label>
                      <input
                        className={style.formInput}
                        type="text"
                        onChange={mentor2ChangeHandler}
                        name="phone"
                        defaultValue={mentor2Values.phone}
                      />
                    </div>

                    {/* Mentor Type */}
                    <div className={style.formGroup}>
                      <label>
                        Mentor Type <span>*</span>
                      </label>
                      <select
                        onChange={mentor2ChangeHandler}
                        name="mentorType"
                        value={mentor2Values.mentorType}
                      >
                        <option value="" selected disabled>
                          --Select One --
                        </option>
                        <option> Parent</option>
                        <option> Industry</option>
                        <option> Acedemic</option>
                      </select>
                    </div>

                    {/* Mentor Experience */}
                    <div className={style.formGroup}>
                      <label>Mentor Experience</label>
                      <input
                        className={style.formInput}
                        type="text"
                        onChange={mentor2ChangeHandler}
                        name="experience"
                        defaultValue={mentor2Values.experience}
                        maxLength={255}
                      />
                    </div>

                    {/* Mentor Expertise */}
                    <div className={style.formGroup}>
                      <label>Mentor Expertise</label>
                      <input
                        className={style.formInput}
                        type="text"
                        onChange={mentor2ChangeHandler}
                        name="expertise"
                        defaultValue={mentor2Values.expertise}
                        maxLength={255}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className={style.buttonContainer}>
                <button
                  className={`btn secondaryBtn `}
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  Back
                </button>
                {mentorLoading ? (
                  <button className={`btn orangeBtn`}>Loading...</button>
                ) : (
                  <button className={`btn orangeBtn`} onClick={addMentors}>
                    Next Section
                  </button>
                )}
              </div>
            </div>
          )}
          {/* Step 3*/}
          {step === 3 && (
            <div className={style.overallStepFormContainer}>
              {members.map((member, key) => {
                return (
                  <>
                    <AddMember
                      changeHandler={updateMember}
                      name={member.name}
                      email={member.email}
                      phone={member.phone}
                      dob={member.dob}
                      gender={member.gender}
                      teamTrack={createTeamValues.teamTrack}
                      MemberClass={member.class}
                      className={style.full}
                      count={key}
                      role={member.role}
                      stream={member.stream}
                      removeMember={removeMember}
                    />
                  </>
                );
              })}
              {members.length < 6 && (
                <>
                  {addAnotherLoading ? (
                    <div className={style.addAnother}>Loading...</div>
                  ) : (
                    <div
                      className={style.addAnother}
                      onClick={addAnotherMember}
                    >
                      Add Another Member
                    </div>
                  )}
                </>
              )}
              <div className={style.buttonContainer}>
                <button
                  className={`btn secondaryBtn `}
                  onClick={() => {
                    setStep(2);
                  }}
                >
                  Back
                </button>

                {addMembersLoading ? (
                  <button className={`btn orangeBtn`}>Loading...</button>
                ) : (
                  <button className={`btn orangeBtn`} onClick={done}>
                    Finish
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateTeam;
