import { h, Component } from "preact";
import style from "./style.css";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import ActivityLog from "../activityLog";
import { useEffect, useState } from "preact/hooks";
import toast from "react-hot-toast";
import AddMember from "../addMember";
import MailHTML from "../mailHTML";

const ThemeList = [
  "AGRICULTURE, FOODTECH & RURAL DEVELOPMENT",
  "BLOCKCHAIN & CYBERSECURITY",
  "CLEAN & GREEN TECHNOLOGY",
  "DISASTER MANAGEMENT",
  "FITNESS & SPORTS",
  "HERITAGE & CULTURE",
  "MEDTECH/ BIOTECH/ HEALTHTECH",
  "MISCELLANEOUS",
  "RENEWABLE/ SUSTAINABLE ENERGY",
  "ROBOTICS AND DRONES",
  "SMART AUTOMATION",
  "SMART EDUCATION",
  "SMART VEHICLES",
  "SPACE TECHNOLOGY",
  "TRAVEL & TOURISM",
  "TOYS & GAMES",
  "TRANSPORTATION & LOGISTICS",
];
const BucketList = [
  "HEALTHCARE & BIOMEDICAL DEVICES",
  "AGRICULTURE & RURAL DEVELOPMENT",
  "SMART VEHICLES/ ELECTRIC VEHICLE/ ELECTRIC VEHICLES",
  "FOOD PROCESSING/NUTRITION/BIOTECH",
  "ROBOTICS AND DRONES",
  "WASTE MANAGEMENT/WASTE TO WEALTH CREATION",
  "CLEAN & POTABLE WATER",
  "RENEWABLE AND AFFORDABLE ENERGY",
  "IOT BASED TECHNOLOGIES",
  "ICT, CYBER-PHYSICAL SYSTEMS, BLOCKCHAIN",
  "OTHER EMERGING AREAS INNOVATION FOR STARTRUP",
  "SOFTWARE - MOBILE APP DEVELOPMENT",
  "SOFTWARE - WEB APP DEVELOPMENT",
  "TRAVEL & TOURISM",
  "LIFE SCIENCES",
  "SMART EDUCATION",
  "SMART CITIES",
  "SPORTS & FITNESS",
  "SMART TEXTILES",
  "SUSTAINABLE ENVIROMENT",
  "INFRASTRUCTURE",
  "MANUFACTURING",
  "DEFENCE & SECURITY",
  "MINING, METALS, MATERIALS",
  "CONSUMER GOODS AND RETAIL",
  "SPACE TECHNOLOGY",
  "GAMING AND GAME DEVELOPMENT",
  "FINTECH",
  "ENVIRONMENTAL, SOCIAL, AND GOVERNANCE",
  "CYBER SECURITY",
];

const Modal = ({
  close,
  confirmationMessage,
  disable,
  enable,
  confirmationValue,
  type,
  spocDetails,
  spocEmail,
  spocApproval,
  spocReject,
  deleteTeam,
  teamToDelete,
  submissionValues,
  changeHandler,
  allTeams,
  createNewSubmission,
  uploadPPT,
  uploadSupport,
  updateSubmission,
  deleteSubmission,
  loading,
  activityValues,
  rejectionReason,
  setRejectionReason,
  viewMore,
  activeAdminId,
  activeAdminEmail,
  totalPages,
  activityPage,
  previewMailContent,
  objectToTeamName,
  submissionIds,
  submissionTitles,
  nominateIdeaSubmission,
  teamDetails,
  sendEmail,
}) => {
  const [rejectModal, setRejectModal] = useState(false);

  // All Data ====================>

  return (
    <>
      <div
        id="myModal"
        class={`${style.modal} ${
          type === "viewActivity" ? style.viewActivity : ""
        }`}
      >
        <div
          class={`${style.modalContent} ${
            (type === "confimationMail" || type === "deadlineModal") &&
            style.confirmation
          } ${type === "viewActivity" ? style.viewActivity : ""}`}
        >
          <span class={style.close} onClick={close}>
            &times;
          </span>
          {type === "confimationMail" && (
            <>
              <div>{confirmationMessage}</div>
              <div className={style.buttonContainer}>
                {confirmationValue === "disable" && (
                  <button className={style.actionButton} onClick={disable}>
                    Disable
                  </button>
                )}
                {confirmationValue === "enable" && (
                  <button className={style.actionButton} onClick={enable}>
                    Enable
                  </button>
                )}
                {confirmationValue === "deleteTeam" && (
                  <button
                    className={style.actionButton}
                    onClick={() => {
                      deleteTeam(teamToDelete);
                    }}
                  >
                    Delete
                  </button>
                )}
                {confirmationValue === "deleteSubmission" && (
                  <button
                    className={style.actionButton}
                    onClick={() => {
                      deleteSubmission(teamToDelete);
                    }}
                  >
                    Delete
                  </button>
                )}
                <button className={style.cancelButton} onClick={close}>
                  {" "}
                  Cancel
                </button>
              </div>
            </>
          )}
          {type === "spocApplication" && (
            <>
              {rejectModal ? (
                <>
                  <form className={style.rejectForm}>
                    <p> Are You Sure? You want to reject this request.</p>
                    <div className="redStarFields mb-1">
                      * Indicates required fields
                    </div>
                    <div className={`${style.textOnInput} ${style.mb2}`}>
                      <label for="">
                        Reason for rejection <span className="textRed">*</span>
                      </label>
                      <textarea
                        className={style.form_control}
                        name="rejectionReason"
                        type="text"
                        placeholder="Enter rejection reason"
                        onChange={changeHandler}
                        value={rejectionReason}
                      ></textarea>

                      <div className={style.buttonContainer}>
                        <div
                          className={style.rejectButton}
                          // onClick={() => {
                          //   spocReject(spocDetails?._id);
                          // }}
                          onClick={() => {
                            spocReject(spocDetails?._id, rejectionReason);
                          }}
                        >
                          Submit
                        </div>
                        <div
                          className={style.rejectFormCancelButton}
                          onClick={() => {
                            setRejectModal(false);
                          }}
                        >
                          Cancel
                        </div>
                      </div>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <div className={style.modalTitle}>SPOC Application</div>
                  <div className={style.infoConatiner}>
                    <div className={style.leftColumn}>
                      <div className={style.infoGroup}>
                        <label>Education Board</label>
                        <div>{spocDetails?.schoolBoardName}</div>
                      </div>
                      <div className={style.infoGroup}>
                        <label>School Registration Code</label>
                        <div>{spocDetails?.schoolCode}</div>
                      </div>
                      <div className={style.infoGroup}>
                        <label>School Name</label>
                        <div>{spocDetails?.schoolName}</div>
                      </div>
                      <div className={style.infoGroup}>
                        <label>State</label>
                        <div>{spocDetails?.state}</div>
                      </div>
                      {/* <div className={style.infoGroup}>
                  <label>District</label>
                  <div>info aayegi Yanaha</div>
                </div> */}
                      <div className={style.infoGroup}>
                        <label>City</label>
                        <div>{spocDetails?.city}</div>
                      </div>
                      <div className={style.infoGroup}>
                        <label>School Faculty First Name</label>
                        <div>{spocDetails?.facultyFirstName}</div>
                      </div>
                      <div className={style.infoGroup}>
                        <label>School Faculty Last Name</label>
                        <div>{spocDetails?.facultyLastName}</div>
                      </div>
                      <div className={style.infoGroup}>
                        <label>Email</label>
                        <div>{spocEmail}</div>
                      </div>
                      <div className={style.infoGroup}>
                        <label>Mobile Number</label>
                        <div>{spocDetails?.facultyPhone}</div>
                      </div>
                      {(spocDetails?.status === "REJECTED" ||
                        spocDetails?.status === "REAPPLIED") && (
                        <>
                          <div className={style.infoGroup}>
                            <label>Rejection Reason</label>
                            <div>{spocDetails?.rejectionReason}</div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className={style.rightColumn}>
                      <div
                        className={`${style.infoGroup} ${style.previewLogo}`}
                      >
                        <label>School Logo</label>
                        <div>
                          {/* {spocDetails?.schoolLogo} */}
                          <img
                            className={style.schoolLogoPreview}
                            src={spocDetails?.schoolLogo}
                          />
                        </div>
                      </div>
                      <div className={style.infoGroup}>
                        <label>Consent Letter</label>
                        <div>
                          <iframe
                            src={spocDetails?.consentLetter}
                            style="width:100%; height:350px;"
                            frameborder="0"
                          ></iframe>
                        </div>
                        <Link
                          className={style.ConcentPreview}
                          to={spocDetails?.consentLetter}
                          target="_blank"
                        >
                          Open in new tab
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className={style.buttonContainer}>
                    {spocDetails?.status === "IN REVIEW" ||
                    spocDetails?.status === "REAPPLIED" ? (
                      <></>
                    ) : (
                      <>
                        <div
                          className={style.rejectFormCancelButton}
                          onClick={close}
                        >
                          Cancel
                        </div>
                      </>
                    )}
                    {spocDetails?.status != "REJECTED" && (
                      <div
                        className={style.rejectButton}
                        onClick={() => {
                          setRejectModal(true);
                          setRejectionReason("");
                        }}
                      >
                        Reject
                      </div>
                    )}

                    {spocDetails?.status != "VERIFIED" && (
                      <div
                        className={style.approveButton}
                        onClick={() => {
                          spocApproval(spocDetails?._id);
                        }}
                      >
                        Approve
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}

          {type === "ideaModal" && (
            <>
              <div className={style.modalTitle}>
                {confirmationValue === "createIdea" && "Create"}
                {confirmationValue === "updateIdea" && "Update"}
                {confirmationValue === "previewIdea" && "Preview"} Submission
                <div className="redStarFields my-05">
                  * Indicates required fields
                </div>
              </div>

              <div className={style.infoConatiner}>
                {/* Submission Title */}
                <div className={style.formGroup}>
                  <label>
                    Submission Title <span>*</span>
                  </label>
                  <input
                    className={style.formInput}
                    type="text"
                    onChange={changeHandler}
                    name="title"
                    defaultValue={submissionValues.title}
                    maxLength={255}
                  />
                </div>
                {/* Team Name */}
                <div className={style.formGroup}>
                  <label>
                    <span className={style.hoverText}>
                      <Icon
                        icon="material-symbols:help-outline"
                        fontSize={"12px"}
                      />
                      <span className={style.tooltipText} id="right">
                        Teams with completed status and less than 3 submissions
                        will be listed here.
                      </span>
                    </span>
                    &nbsp;Select Team<span>*</span>
                  </label>
                  {confirmationValue === "createIdea" ? (
                    <select
                      className={style.formInput}
                      type="text"
                      onChange={changeHandler}
                      name="team"
                      value={submissionValues.team}
                    >
                      <option value="">--Select a Team ---</option>
                      {allTeams.map((team, key) => (
                        <option value={team.value} key={key}>
                          {team.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <select
                      className={style.formInput}
                      type="text"
                      // onChange={changeHandler}
                      name="team"
                      value={submissionValues.team}
                      disabled
                    >
                      <option value="">--Select a Team ---</option>
                      {allTeams.map((team, key) => (
                        <option value={team.value} key={key}>
                          {team.name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>

                {/* Submission Theme */}
                <div className={style.formGroup}>
                  <label>
                    Submission Theme <span>*</span>
                  </label>
                  <select
                    className={style.formInput}
                    type="text"
                    onChange={changeHandler}
                    name="theme"
                    value={submissionValues.theme}
                  >
                    <option value="" selected disabled>
                      --Select an Option ---
                    </option>
                    {ThemeList.map((theme, key) => (
                      <option value={theme} key={key} className="progTitle">
                        {theme}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Idea PPT */}
                <div className={style.formGroup}>
                  <label>
                    Idea PPT <span>*</span>
                  </label>
                  <input
                    className={style.formInput}
                    type="file"
                    accept=".pdf"
                    name="document"
                    defaultValue={submissionValues.document}
                    onChange={uploadPPT}
                  />
                  <small className="redStarFields">
                    Only pdf file allowed (less than 2 MB)
                  </small>
                  <div className={style.previewContainer}>
                    {submissionValues.document ? (
                      <>
                        <div>File Uploaded Sucessfully</div>
                        <Link
                          to={submissionValues.document}
                          target="_blank"
                          className={style.textLink}
                        >
                          Preview
                        </Link>
                      </>
                    ) : (
                      <>
                        <div>Sample PPT</div>
                        <Link
                          to="https://varsha685.github.io/publicAssets/SIH_Jr/Idea-Presentation-Format-SIH2023-School.pptx"
                          download
                          className={style.textLink}
                        >
                          Download
                        </Link>
                      </>
                    )}
                  </div>
                </div>

                {/* Description */}
                <div className={style.formGroup}>
                  <label>
                    Description <span>*</span>
                  </label>
                  <textarea
                    className={style.formInput}
                    type="text"
                    onChange={changeHandler}
                    name="processFlow"
                    defaultValue={submissionValues.processFlow}
                    maxLength={1024}
                  ></textarea>
                  {/* <input
                    className={style.formInput}
                    type="text"
                    onChange={changeHandler}
                    name="processFlow"
                    defaultValue={submissionValues.processFlow}
                  /> */}
                </div>

                {/* Technology Bucket */}
                <div className={style.formGroup}>
                  <label>
                    Technology Bucket<span>*</span>
                  </label>
                  <select
                    className={style.formInput}
                    type="text"
                    onChange={changeHandler}
                    name="technologyBucket"
                    value={submissionValues.technologyBucket}
                  >
                    <option value="" selected disabled>
                      --Select an Option ---
                    </option>
                    {BucketList.map((bucket, key) => (
                      <option key={key} className="uppercase">
                        {bucket}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Category */}
                <div className={style.formGroup}>
                  <label>
                    Category <span>*</span>
                  </label>
                  <select
                    className={style.formInput}
                    type="text"
                    onChange={changeHandler}
                    name="category"
                    value={submissionValues.category}
                  >
                    <option value="" selected disabled>
                      --Select an Option ---
                    </option>
                    <option value={"HARDWARE"} className="progTitle">
                      HARDWARE
                    </option>
                    <option value={"SOFTWARE"} className="progTitle">
                      SOFTWARE
                    </option>
                  </select>
                </div>

                {/* Supporting Document */}
                <div className={style.formGroup}>
                  <label>Supporting Document (optional)</label>
                  <input
                    className={style.formInput}
                    type="file"
                    accept=".pdf"
                    name="supportDocument"
                    defaultValue={submissionValues.supportDocument}
                    onChange={uploadSupport}
                  />
                  <div className={style.previewContainer}>
                    {submissionValues.supportDocument && (
                      <>
                        <div>File Uploaded Sucessfully</div>
                        <Link
                          to={submissionValues.supportDocument}
                          target="_blank"
                          className={style.textLink}
                        >
                          Preview
                        </Link>
                      </>
                    )}
                  </div>
                </div>

                {/* Youtube Link */}
                <div className={style.formGroup}>
                  <label>Youtube Link (optional)</label>
                  <input
                    className={style.formInput}
                    type="url"
                    onChange={changeHandler}
                    name="videoLink"
                    defaultValue={submissionValues.videoLink}
                    maxLength={255}
                  />
                </div>
              </div>
              <div className={style.buttonContainer}>
                {confirmationValue === "createIdea" && (
                  <>
                    {loading ? (
                      <button className={style.actionButton}>Loading..</button>
                    ) : (
                      <button
                        className={style.actionButton}
                        onClick={createNewSubmission}
                      >
                        Create Idea
                      </button>
                    )}
                  </>
                )}
                {confirmationValue === "updateIdea" && (
                  <>
                    {loading ? (
                      <button className={style.actionButton}>Loading...</button>
                    ) : (
                      <button
                        className={style.actionButton}
                        onClick={updateSubmission}
                      >
                        Update Idea
                      </button>
                    )}
                  </>
                )}
                <button className={style.cancelButton} onClick={close}>
                  Cancel
                </button>
              </div>
            </>
          )}
          {type === "viewActivity" && (
            <>
              <div className={style.activityHeader}>
                <div className={style.modalTitle}> Activity Logs </div>
                <p>
                  <Icon icon="iconamoon:profile-circle-fill" /> &nbsp;
                  {activeAdminEmail}
                </p>
              </div>
              <div className={style.activitysContainer}>
                <div className="log" id="log"></div>
                <div className={style.logsConatiner}>
                  {activityValues.map((activity, key) => (
                    <ActivityLog activity={activity} key={key} />
                  ))}
                </div>
                <br />
                {activityPage < totalPages && (
                  <div
                    onClick={() => {
                      viewMore(activeAdminId);
                    }}
                    className={style.textLink}
                  >
                    View More
                  </div>
                )}
              </div>
            </>
          )}

          {type === "testMail" && (
            <>
              <div className={style.testMailDiv}>
                <div className={style.testMailHeader}>
                  <div className={style.testMailTitle}>
                    Enter upto 3 email separated by commas
                  </div>
                </div>
                <form>
                  <div className={style.emailIdsBox}>
                    <textarea
                      className={`form_control`}
                      name="testEmails"
                      placeholder="Type email here..."
                      onChange={changeHandler}
                    />

                    <div
                      className={`d-flex align-center ${style.actionButtons}`}
                    >
                      <div className={`btn secondaryBtn`} onClick={close}>
                        Cancel
                      </div>
                      <div
                        className={`btn orangeBtn`}
                        onClick={() => {
                          sendEmail("test");
                        }}
                      >
                        Next
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
          {(type === "previewMail" || type === "reviewMail") && (
            <>
              <div className={style.previewMail}>
                <div className={style.testMailHeader}>
                  {type === "previewMail"? (
                    <div className={style.testMailTitle}>
                    Are you sure you want to send this email ?{" "}
                  </div>
                  ):(
                    <div className={style.testMailTitle}>
                    Review Email
                  </div>
                  )}
                  {type === "previewMail" ? (
                    <p className={`text_Blue ${style.previewMailCount}`}>
                    THIS EMAIL WILL BE SEND TO {previewMailContent.emailCount}{" "}
                    Emails.
                  </p>
                  ):(<p className={`text_Blue ${style.previewMailCount}`}>
                  THIS EMAIL WAS SENT TO {previewMailContent.emailCount}{" "}
                  Emails.
                </p>)}
                  <p className={style.previewMailSubject}>
                    Subject: {previewMailContent.emailSubject}
                  </p>
                </div>
                <form>
                  <div className={style.previewEmailContentBox}>
                    <MailHTML body={previewMailContent.emailBody} />
                    {/* <p
                      dangerouslySetInnerHTML={{
                        __html: previewMailContent.emailBody,
                      }}
                    /> */}
                  </div>
                  <div className={`d-flex align-center ${style.actionButtons}`}>
                    <div className={`btn secondaryBtn`} onClick={close}>
                      Cancel
                    </div>
                    {type === "previewMail" && (
                      <div
                      className={`btn orangeBtn`}
                      onClick={() => sendEmail("all")}
                    >
                      Save
                    </div>
                    )}
                  </div>
                </form>
              </div>
            </>
          )}
          {type === "nominateTeams" && (
            <>
              <div className={style.activityHeader}>
                <div className={style.modalTitle}>
                  Are you sure you want to nominate?
                </div>
                <div className="text_Blue">
                  Once you click "Nominate," it means you're making a final
                  submission of the ideas listed below. You won't be able to
                  make any more changes to these ideas or the teams connected to
                  them. <strong>This action cannot be reversed.</strong>
                </div>
              </div>
              <div className={style.IdeaNameConatiner}>
                <ul>
                  {submissionIds.map((id, key) => (
                    <li key={key}>
                      {objectToTeamName[id]} - {submissionTitles[id]}
                    </li>
                  ))}
                </ul>
              </div>
              <div className={style.buttonContainer}>
                <>
                  {loading ? (
                    <button className={style.actionButton}>Loading..</button>
                  ) : (
                    <button
                      className={style.actionButton}
                      onClick={nominateIdeaSubmission}
                    >
                      Nominate Ideas
                    </button>
                  )}
                </>
                <button className={style.cancelButton} onClick={close}>
                  Cancel
                </button>
              </div>
            </>
          )}
          {type === "teamPreview" && (
            <>
              <div className={style.modalTitle}>View Team </div>
              <div className="w-100">
                <small className="my-05">Team Details are Visible below</small>
              </div>
              {/* Stats */}
              {/* <div className="w-100">
                <div className={style.statConatiner}>
                  <div className={style.stat}>
                    Total Mentors <div className={style.statNumber}> 10 </div>
                  </div>
                  <div className={style.stat}>
                    Total Members <div className={style.statNumber}> 20 </div>
                  </div>
                </div>
              </div> */}
              <div className={`w-100 ${style.allInfoConatier}`}>
                {/* Team Info */}
                <div className={`${style.individualForm}`}>
                  <div className={style.title}> Team Details</div>
                  {/* Team Name */}
                  <div className={style.formGroup}>
                    <label>
                      Team Name <span>*</span>
                    </label>
                    <input
                      className={style.formInput}
                      type="text"
                      name="teamName"
                      defaultValue={teamDetails?.teamName}
                      readOnly
                    />
                  </div>

                  {/* Team Track */}
                  <div className={style.formGroup}>
                    <label className={style.editTeamTrack}>
                      Team Track <span>*</span>
                    </label>
                    <select name="teamTrack" value={teamDetails?.tier} disabled>
                      <option value="" selected disabled>
                        --Select One --
                      </option>
                      <option value="Tier-1"> Track 1(Class 6-9)</option>
                      <option value="Tier-2"> Track 2(Class 10-12)</option>
                    </select>
                  </div>

                  {/* Consent Letter */}
                  <div className={style.formGroup}>
                    <label>
                      Consent Letter <span>*</span>
                    </label>
                    <input className={style.formInput} type="file" disabled />
                    <span className={style.letterDispaly}>
                      Letter uploaded sucessfully! &nbsp; &nbsp;
                      <Link to={teamDetails?.consentLetter} target="_blank">
                        Preview
                      </Link>
                    </span>
                  </div>
                </div>

                {/* Mentor Info */}
                <div className={`${style.individualForm}`}>
                  <div className={style.title}> Mentor Details</div>
                  {teamDetails?.members.map((member, key) => (
                    <>
                      {member?.role === "Mentor" && (
                        <>
                          <div className={style.individualComponent} key={key}>
                            {/* Mentor Name */}
                            <div className={style.formGroup}>
                              <label>
                                Mentor Name <span>*</span>
                              </label>
                              <input
                                className={style.formInput}
                                type="text"
                                name="name"
                                defaultValue={member.name}
                                readOnly
                              />
                            </div>

                            {/* Mentor Email */}
                            <div className={style.formGroup}>
                              <label>
                                Mentor Email <span>*</span>
                              </label>
                              <input
                                className={style.formInput}
                                type="text"
                                name="email"
                                defaultValue={member.email}
                                readOnly
                              />
                            </div>

                            {/* Mentor Phone */}
                            <div className={style.formGroup}>
                              <label>
                                Mentor Phone <span>*</span>
                              </label>
                              <input
                                className={style.formInput}
                                type="text"
                                name="phone"
                                defaultValue={member.phone}
                                readOnly
                              />
                            </div>

                            {/* Mentor Type */}
                            <div className={style.formGroup}>
                              <label>
                                Mentor Type <span>*</span>
                              </label>
                              <select
                                name="mentorType"
                                value={member.mentorType}
                                disabled
                              >
                                <option value="" selected disabled>
                                  --Select One --
                                </option>
                                <option> Parent</option>
                                <option> Industry</option>
                                <option> Acedemic</option>
                              </select>
                            </div>

                            {/* Mentor Experience */}
                            <div className={style.formGroup}>
                              <label>Mentor Experience</label>
                              <input
                                className={style.formInput}
                                type="number"
                                name="experience"
                                defaultValue={member.experience}
                                readOnly
                              />
                            </div>

                            {/* Mentor Expertise */}
                            <div className={style.formGroup}>
                              <label>Mentor Expertise</label>
                              <input
                                className={style.formInput}
                                type="text"
                                name="expertise"
                                defaultValue={member.expertise}
                                readOnly
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ))}
                </div>

                {/* Member Info */}
                <div className={`${style.individualForm}`}>
                  <div className={style.title}> Member Details</div>
                  <div className={style.individualComponent}>
                    {teamDetails?.members.map((member, key) => (
                      <>
                        {member?.role != "Mentor" && (
                          <AddMember
                            type="displayOnly"
                            name={member.name}
                            email={member.email}
                            phone={member.phone}
                            dob={member.dob}
                            gender={member.gender}
                            teamTrack={teamDetails.tier}
                            MemberClass={member.class}
                            className={style.full}
                            // count={key}
                            role={member.role}
                            stream={member.stream}
                          />
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
          {type === "deadlineModal" && (
            <div className={style.deadLineModalContainer}>
              <div className={style.topOrangeLine}></div>
              <div>
                <img src="https://saxenaprachi.github.io/publicAssets/home/deadline.png" />
              </div>
              <h2>DEADLINE EXTENDED</h2>
              <div className={style.grayText}>
                For Registration, Idea Submission , Idea Evaluation
              </div>
              <strong className="textLightBlue">For Updated Timeline </strong>
              <button
                className={style.OrangeRoundedButon}
                onClick={() => {
                  setTimeout(() => {
                    const targetElement = document.getElementById("timeline");
                    if (targetElement) {
                      const headerHeight =
                        document.querySelector("header").offsetHeight;
                      const targetPosition =
                        targetElement.offsetTop - headerHeight;
                      window.scrollTo({
                        top: targetPosition,
                        behavior: "smooth",
                      });
                    }
                  }, 10);
                  close();
                }}
              >
                CLICK HERE
              </button>
              <div className={style.littleLine}></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Modal;
