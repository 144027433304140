import { h, Component } from "preact";
import style from "./style.css";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "preact/hooks";
import { useSelector } from "react-redux/es/hooks/useSelector";
import useLogout from "../../hooks/useLogout";

const Header = ({ page }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const logout = useLogout();
  const [isAuth, setIsAuth] = useState(false);

  const roles = useSelector((state) => state.user.role);

  const [allRoles, setAllRoles] = useState([]);

  useEffect(() => {
    let auth = localStorage.getItem("isAuth");
    if (auth && auth !== null) {
      setIsAuth(true);
    }
    const myRoles = [];
    for (let i = 0; i < roles.length; i++) {
      if (roles[i].status === "VERIFIED") {
        myRoles.push(roles[i].role);
      }
    }
    setAllRoles(myRoles);
  }, []);

  const [selectedSection, setSelectedSection] = useState("");

  const handleLinkClick = (section) => {
    setSelectedSection(section);

    // Delay the scroll operation slightly to ensure the state has updated
    setTimeout(() => {
      const targetElement = document.getElementById(section);
      if (targetElement) {
        const headerHeight = document.querySelector("header").offsetHeight;
        const targetPosition = targetElement.offsetTop - headerHeight;
        window.scrollTo({
          top: targetPosition,
          behavior: "smooth",
        });
      }
    }, 10); // You can adjust the delay (in milliseconds) based on your needs
  };

  return (
    <header className={style.header}>
      <nav>
        <div>
          <Link to="/">
            <img
              src="https://saxenaprachi.github.io/publicAssets/SIH2023-logo.webp"
              alt="sih"
              className={style.sihLogo}
            />
          </Link>
        </div>
        <div className={style.Toggle}>
          <Icon
            icon="ci:hamburger-md"
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
          />
          {menuOpen && (
            <>
              <div className={style.menuModal}>
                {isAuth ? (
                  <>
                    {/* Edit Profile */}
                    {allRoles.includes("SPOC") && (
                      <Link className={style.menu} to="/edit-profile">
                        <div>
                          <Icon icon="iconamoon:edit" />
                          &nbsp; Edit profile
                        </div>
                      </Link>
                    )}
                    {/* Role */}
                    <Link className={style.menu} to="/role">
                      <div>
                        <Icon icon="eos-icons:role-binding-outlined" />
                        &nbsp; Roles Dashboard
                      </div>
                    </Link>
                  </>
                ) : (
                  <>
                    <Link className={style.menu} to="/">
                      <div>
                        <Icon icon="material-symbols:home-outline" />
                        &nbsp; Home
                      </div>
                    </Link>
                  </>
                )}
                {allRoles.includes("SPOC") && (
                  <>
                    {/* spoc */}
                    <Link className={style.menu} to="/spoc/dashboard">
                      <div>
                        <Icon icon="mdi:teacher" />
                        &nbsp; SPOC Dashboard
                      </div>
                    </Link>
                  </>
                )}
                {allRoles.includes("ADMIN") && (
                  <>
                    {/* admin */}
                    <Link className={style.menu} to="/sih-admin/dashboard">
                      <div>
                        <Icon icon="eos-icons:admin" />
                        &nbsp; Admin Dashboard
                      </div>
                    </Link>
                  </>
                )}
                {allRoles.includes("SUPER_ADMIN") && (
                  <>
                    {/* super admin */}
                    <Link className={style.menu} to="/super-admin/dashboard">
                      <div>
                        <Icon icon="eos-icons:admin-outlined" />
                        &nbsp; Super Admin Dashboard
                      </div>
                    </Link>
                  </>
                )}
                <hr />
                {isAuth ? (
                  <div
                    className={`${style.Logout} textOrange`}
                    onClick={logout}
                  >
                    Logout
                  </div>
                ) : (
                  <Link to="/login" className={`${style.Logout} textOrange`}>
                    Login/Register
                  </Link>
                )}
              </div>
            </>
          )}
        </div>
        <div className={style.rightContainer}>
          <div>
            <Link to={"/"}>Home</Link>
          </div>
          {page === "home" && (
            <>
              <div>
                <Link href="/#about" onClick={() => handleLinkClick("about")}>
                  About
                </Link>
              </div>
              <div>
                <Link
                  href="/#timeline"
                  onClick={() => handleLinkClick("timeline")}
                >
                  Timeline
                </Link>
              </div>
              <div>
                <Link href="/#theme" onClick={() => handleLinkClick("theme")}>
                  Theme
                </Link>
              </div>
              <div>
                <Link
                  href="/#committee"
                  onClick={() => handleLinkClick("committee")}
                >
                  Committee
                </Link>
              </div>
              <div>
                <Link
                  href="/#partner"
                  onClick={() => handleLinkClick("partner")}
                >
                  Partner
                </Link>
              </div>
            </>
          )}
          {isAuth ? (
            <>
              <div
                onClick={() => {
                  setProfileMenuOpen(!profileMenuOpen);
                }}
                className={style.profile}
              >
                <Icon icon="gg:profile" fontSize={"16px"} /> &nbsp;
                {!profileMenuOpen ? (
                  <Icon icon="icon-park-outline:down" />
                ) : (
                  <Icon icon="icon-park-outline:up" />
                )}
              </div>
              {profileMenuOpen && (
                <>
                  <div
                    id="overlay"
                    onClick={() => {
                      setProfileMenuOpen(false);
                    }}
                  ></div>
                  <div className={style.menuModal}>
                    {/* Edit Profile */}

                    {allRoles.includes("SPOC") && (
                      <Link className={style.menu} to="/edit-profile">
                        <div>
                          <Icon icon="iconamoon:edit" />
                          &nbsp; Edit profile
                        </div>
                      </Link>
                    )}
                    {/* Role */}
                    <Link className={style.menu} to="/role">
                      <div>
                        <Icon icon="eos-icons:role-binding-outlined" />
                        &nbsp; Roles Dashboard
                      </div>
                    </Link>
                    {allRoles.includes("SPOC") && (
                      <>
                        {/* spoc */}
                        <Link className={style.menu} to="/spoc/dashboard">
                          <div>
                            <Icon icon="mdi:teacher" />
                            &nbsp; SPOC Dashboard
                          </div>
                        </Link>
                      </>
                    )}
                    {allRoles.includes("ADMIN") && (
                      <>
                        {/* admin */}
                        <Link className={style.menu} to="/sih-admin/dashboard">
                          <div>
                            <Icon icon="eos-icons:admin" />
                            &nbsp; Admin Dashboard
                          </div>
                        </Link>
                      </>
                    )}
                    {allRoles.includes("SUPER_ADMIN") && (
                      <>
                        {/* super admin */}
                        <Link
                          className={style.menu}
                          to="/super-admin/dashboard"
                        >
                          <div>
                            <Icon icon="eos-icons:admin-outlined" />
                            &nbsp; Super Admin Dashboard
                          </div>
                        </Link>
                      </>
                    )}
                    <hr />
                    <div
                      className={`${style.Logout} textOrange`}
                      onClick={logout}
                    >
                      Logout
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <Link to={"/login"} className={style.orangeButton}>
              Login/Register
            </Link>
          )}
          {/* {page === "home" || page === "login" || page === "register" ? (
            <Link to={"/login"} className={style.orangeButton}>
              Login/Register
            </Link>
          ) : (
            <button className={style.orangeButton} onClick={logout}>
              Logout
            </button>
          )} */}
        </div>
      </nav>
    </header>
  );
};

export default Header;
