import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useEffect, useState } from "react";

const RequireAuth = ({ allowedRoles }) => {
  const roles = useSelector((state) => state.user.role);
  const accessToken = useSelector((state) => state.user.accessToken);
  const location = useLocation();
  const [allow, setAllow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    for (let i = 0; i < roles.length; i++) {
      if (allowedRoles?.includes(roles[i].role)) {
        if (roles[i].status === "VERIFIED") {
          setAllow(true);
          setIsLoading(false);
          return;
        }
      }
    }
    setAllow(false);
    setIsLoading(false);
  }, []);

  return (
    <>
      {isLoading ? (
        <p>Loading...</p>
      ) : allow ? (
        <Outlet />
      ) : accessToken ? (
        <Navigate to="/role" state={{ from: location }} replace />
      ) : (
        <Navigate to="/login" state={{ from: location }} replace />
      )}
    </>
  );
};

export default RequireAuth;
