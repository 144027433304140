import { h } from "preact";
import { Toaster } from "react-hot-toast";

// Routes
import Home from "../routes/home";
import Login from "../routes/login";
import Registration from "../routes/registration";
import Dashboard from "../routes/spoc/dashboard";
import Profile from "../routes/spoc/profile";
import SchoolRegistration from "../routes/spoc/school-registration";
import Role from "../routes/role";
import Footer from "./footer";

import { Provider } from "react-redux";
import { store } from "../redux/store";
import AdminManagement from "../routes/superAdmin/adminManagement";
import SpocReqManagement from "../routes/sihAdmin/spocReqManagement";
import NoticeBoard from "../routes/sihAdmin/noticeBoard";
import SuperDashboard from "../routes/superAdmin/dashobard";
import PersistLogin from "./PersistLogin";

import { BrowserRouter, Routes, Route,useLocation } from "react-router-dom";
import {useLayoutEffect} from 'react';
import SihAdminDashboard from "../routes/sihAdmin/dashboard";
import NotFound from "../routes/notFound";
import SPOCStats from "../routes/spoc/stats";
import ManageTeams from "../routes/spoc/manage-teams";
import ManageSubmission from "../routes/spoc/manage-submissions";
import RequireAuth from "./RequiredAuth";
import EditProfile from "../routes/profile";
import SPOCs from "../routes/sihAdmin/spocs";
import CreateTeam from "../routes/spoc/create-team";
import EditTeam from "../routes/spoc/edit-team";
import PlatformGuide from "../routes/platformGuide";
import EmailService from "../routes/sihAdmin/emailService";
import NominateTeam from "../routes/spoc/nominate-team";
import TickerManagement from "../routes/sihAdmin/tickerManagement";


const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  // console.log("inside wrapper")
  return children
} 
export default function App() {
  return (
    <>
      <BrowserRouter>
      <Wrapper>
        <Provider store={store}>
          <Routes>
            {/* Landing Page */}
            <Route path="/" element={[<Toaster />, <Home />, <Footer />]} />

            {/* PlatformGuide */}
            <Route
              path="/junior-sih-spoc-guide"
              element={[<PlatformGuide />]}
            />

            {/* Auth Pages */}
            <Route
              path="/login/"
              element={[<Toaster />, <Login />, <Footer />]}
            />
            <Route
              path="/register/"
              element={[<Toaster />, <Registration />, <Footer />]}
            />

            {/* Procted Routes */}
            <Route element={<PersistLogin />}>
              <Route
                path="/role/"
                element={[<Toaster />, <Role />, <Footer />]}
              />

              <Route
                element={
                  <RequireAuth allowedRoles={["SPOC", "JURY", "EVALUATOR"]} />
                }
              >
                <Route
                  path="/edit-profile/"
                  element={[<Toaster />, <EditProfile />, <Footer />]}
                />
              </Route>

              {/* SPOC Pages */}
              <Route
                path="/spoc/school-registration/"
                element={[<Toaster />, <SchoolRegistration />, <Footer />]}
              />

              <Route element={<RequireAuth allowedRoles={["SPOC"]} />}>
                <Route
                  path="/spoc/dashboard/"
                  element={[<Toaster />, <Dashboard />, <Footer />]}
                />
                <Route
                  path="/spoc/stats/"
                  element={[<Toaster />, <SPOCStats />, <Footer />]}
                />
                <Route
                  path="/spoc/manage-teams/"
                  element={[<Toaster />, <ManageTeams />, <Footer />]}
                />
                <Route
                  path="/spoc/manage-submissions/"
                  element={[<Toaster />, <ManageSubmission />, <Footer />]}
                />
                <Route
                  path="/spoc/profile/"
                  element={[<Toaster />, <Profile />, <Footer />]}
                />
                <Route
                  path="/spoc/create-team/"
                  element={[<Toaster />, <CreateTeam />, <Footer />]}
                />
                <Route
                  path="/spoc/edit-team/"
                  element={[<Toaster />, <EditTeam />, <Footer />]}
                />
                <Route
                  path="/spoc/nominate-team/"
                  element={[<Toaster />, <NominateTeam />, <Footer />]}
                />
              </Route>

              {/* Super Admin Pages */}
              <Route element={<RequireAuth allowedRoles={["SUPER_ADMIN"]} />}>
                <Route
                  path="/super-admin/dashboard/"
                  element={[<Toaster />, <SuperDashboard />, <Footer />]}
                />
                <Route
                  path="/super-admin/admin-management/"
                  element={[<Toaster />, <AdminManagement />, <Footer />]}
                />
              </Route>

              {/* SIH Admin Pages */}
              <Route element={<RequireAuth allowedRoles={["ADMIN"]} />}>
                <Route
                  path="/sih-admin/dashboard"
                  element={[<Toaster />, <SihAdminDashboard />, <Footer />]}
                />
                <Route
                  path="/sih-admin/spoc-requests"
                  element={[<Toaster />, <SpocReqManagement />, <Footer />]}
                />
                <Route
                  path="/sih-admin/notice-board"
                  element={[<Toaster />, <NoticeBoard />, <Footer />]}
                />
                <Route
                  path="/sih-admin/landing-ticker"
                  element={[<Toaster />, <TickerManagement />, <Footer />]}
                />
                <Route
                  path="/sih-admin/spocs"
                  element={[<Toaster />, <SPOCs />, <Footer />]}
                />
                <Route
                  path="/sih-admin/email-service"
                  element={[<Toaster />, <EmailService />, <Footer />]}
                />
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Provider>
      </Wrapper>
      </BrowserRouter>
    </>
  );
}
