import axios from "axios";
axios.defaults.withCredentials = true;
import constant from "../constant";
export default axios.create({
  baseURL: constant.backend,
});

export const axiosPrivate = axios.create({
  baseURL: constant.backend,
  credentials: "include",
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
