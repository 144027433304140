import { h } from "preact";
import style from "./style.css";

const Profile = () => {
  return (
    <div>
      <h1>Profile</h1>
      <section></section>
    </div>
  );
};

export default Profile;
