import { h, Component } from "preact";

import style from "./style.css";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";

import { useEffect, useState } from "preact/hooks";
import {
  getNoticeText,
  postNoticeText,
} from "../../../modules/sih-admin/actions";

import toast from "react-hot-toast";
import Switch from "../../../components/switch";

const AdminNoticeBoard = () => {
  const [isLoading, setisLoading] = useState(false);

  const fetchData = async () => {
    try {
      setisLoading(true);
      const response = await getNoticeText();
      // console.log(response);
      setAllValues({ ...response?.data });
      setisLoading(false);
    } catch (e) {
      // console.log(e);
      setisLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const updateDashboard = async () => {
    try {
      setisLoading(true);
      if (allValues.text === "") {
        toast.error("Notice Field is required");
        setisLoading(false);
      } else if (allValues.theme == "") {
        toast.error("Please Select Notice Theme");
        setisLoading(false);
      } else {
        const response = await postNoticeText({
          noticeText: allValues.text,
          noticeTheme: allValues.theme,
          noticeVisibility: allValues.visibility,
        });
        if (response?.success === true) {
          // console.log(response);
          toast.success("Notice Board Updated.");
          fetchData();
        } else {
          // toast.error(response?.message);
          fetchData();
        }
      }
      setisLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const cancelDashboard = async () => {
    fetchData();
  };

  const [allValues, setAllValues] = useState({
    text: "",
    theme: "",
    visibility: false,
  });

  const changeHandler = (e) => {
    if (e.target.name === "visibility") {
      setAllValues((prevValues) => {
        return { ...prevValues, [e.target.name]: e.target.checked };
      });
    } else {
      setAllValues((prevValues) => {
        return { ...prevValues, [e.target.name]: e.target.value };
      });
    }
  };

  return (
    <>
      <Header />
      <div className={style.main}>
        <div className={style.sideBarConatiner}>
          <Sidebar role="sih-admin" activeTab="noticeBoard" />
        </div>
        <div className={style.mainContent}>
          <h3>SPOC Notice Board</h3>
          {/* Notice Board */}
          <div className={style.createNoticeForm}>
            <h4>Create Notice</h4>
            <form className={style.createForm}>
              {/* Visibility */}
              <div className={style.visibilityContainer}>
                <Switch
                  isOn={allValues.visibility}
                  handleToggle={changeHandler}
                  name="visibility"
                  colorOne="#52af52"
                  colorTwo="gray"
                />
                <label>
                  Ticker Visibility <span className="textRed">*</span>
                </label>
              </div>
              {/* Notice */}
              <div className={style.inputGroup}>
                <label>
                  Notice <span className="textRed">*</span>
                </label>
                <input
                  name="text"
                  id="text"
                  type="text"
                  onInput={changeHandler}
                  placeholder="Enter your notice"
                  value={allValues.text}
                />
              </div>

              <div className={style.radioGroup}>
                <label for="themeDanger" className={style.dangerTheme}>
                  <input
                    type="radio"
                    name="theme"
                    id="themeDanger"
                    value="DANGER"
                    onChange={changeHandler}
                    checked={allValues.theme == "DANGER"}
                  />
                  &nbsp; DANGER
                </label>
                <br />
                <label for="themeSuccess" className={style.successTheme}>
                  <input
                    type="radio"
                    name="theme"
                    id="themeSuccess"
                    value="SUCCESS"
                    onChange={changeHandler}
                    checked={allValues.theme == "SUCCESS"}
                  />
                  &nbsp; SUCCESS
                </label>
                <br />
                <label for="themeInfo" className={style.infoTheme}>
                  <input
                    type="radio"
                    name="theme"
                    id="themeInfo"
                    value="INFO"
                    onChange={changeHandler}
                    checked={allValues.theme == "INFO"}
                  />
                  &nbsp; INFO
                </label>
                <br />
                <label for="themeWarning" className={style.warningTheme}>
                  <input
                    type="radio"
                    name="theme"
                    id="themeWarning"
                    value="WARNING"
                    onChange={changeHandler}
                    checked={allValues.theme == "WARNING"}
                  />
                  &nbsp; WARNING
                </label>
              </div>

              <div className={style.buttonContainer}>
                <button
                  className={style.createButton}
                  onClick={updateDashboard}
                  type="button"
                >
                  {!isLoading ? "Update Notice" : "Loading..."}
                </button>
                <div
                  className={style.cancelButton}
                  onClick={cancelDashboard}
                  type="button"
                >
                  Cancel
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminNoticeBoard;
