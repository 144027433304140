import { h, Component } from "preact";
import style from "./style.css";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import Table from "../../../components/table";
import Modal from "../../../components/modal";
import { useEffect, useState } from "preact/hooks";
import Pagination from "../../../components/pagination";
import { Icon } from "@iconify/react";

// APIS
import {
  getSpocReqs,
  getSpocReqData,
  approveSpocReq,
  rejectSpocReq,
  getSPOCReqStats,
} from "../../../modules/sih-admin/actions";
import toast from "react-hot-toast";

const SpocReqManagement = () => {
  // Fetching SPOC Requests
  const [isLoading, setisLoading] = useState(false);
  const [spocReqData, setSpocReqData] = useState([]);

  // Filters
  const [filterOpen, setFilterOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [emailSearch, setEmailSearch] = useState("");
  const [recordsPerPage, setRecordsPerPage] = useState(10);

  const filterFetching = () => {
    let newStatusFilter = "";
    if (document.getElementById("inReview").checked === true) {
      newStatusFilter = newStatusFilter + "IN REVIEW" + ",";
    }
    if (document.getElementById("verified").checked === true) {
      newStatusFilter = newStatusFilter + "VERIFIED" + ",";
    }
    if (document.getElementById("rejected").checked === true) {
      newStatusFilter = newStatusFilter + "REJECTED" + ",";
    }
    if (document.getElementById("reapplied").checked === true) {
      newStatusFilter = newStatusFilter + "REAPPLIED";
    }

    setStatusFilter(newStatusFilter);
    setCurrentPage(1);
    // console.log("newStatusFilter ", newStatusFilter);

    fetchData(1, newStatusFilter, recordsPerPage, emailSearch);
  };

  const fetchData = async (page, statusFilter, recordsPerPage, emailSearch) => {
    try {
      setisLoading(true);
      const response = await getSpocReqs(
        page,
        statusFilter,
        recordsPerPage,
        emailSearch
      );
      // console.log(response);
      setSpocReqData(response?.data);
      setTotalPages(response?.pages);
      setisLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  // Review SPOC Reqs
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [reviewLoading, setReviewLoading] = useState(false);
  const [spocDetails, setSpocDetails] = useState({});
  const [activeReq, setActiveReq] = useState("");

  const review = async (id, email, status) => {
    try {
      setReviewLoading(true);
      const response = await getSpocReqData({ spoc: id });
      // console.log(response);
      if (response?.success === true) {
        setIsReviewModalOpen(true);
        setSpocDetails({ ...response?.data, status: status });
        setActiveReq(email);
      } else {
        toast.error(response?.message);
        setIsReviewModalOpen(false);
        setActiveReq("");
      }
      setReviewLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  // Approve SPOC

  const approval = async (id) => {
    try {
      setisLoading(true);
      const response = await approveSpocReq({ spoc: id });
      if (response?.success === true) {
        toast.success("SPOC request Approved.");

        fetchData(currentPage, statusFilter, recordsPerPage, emailSearch);
        setIsReviewModalOpen(false);
      } else {
        toast.error(response?.message);

        fetchData(currentPage, statusFilter, recordsPerPage, emailSearch);
        setIsReviewModalOpen(false);
      }
      setisLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  // Reject SPOC
  const [rejectionReason, setRejectionReason] = useState();
  // Change Handler
  const changeHandler = (e) => {
    let val = e.target.value;
    setRejectionReason(e.target.value);
  };

  const reject = async (id, rejectionReason) => {
    setisLoading(true);
    if (rejectionReason === "") {
      toast.error("Rejection Reason Field is required");
      setisLoading(false);
    } else if (rejectionReason.length < 1) {
      toast.error("Rejection reason must be at least 1 characters long.");
    } else if (rejectionReason.length >= 255) {
      toast.error("Rejection reason cannot be more than 255 characters.");
    } else {
      try {
        const response = await rejectSpocReq({
          spoc: id,
          reason: rejectionReason,
        });
        // console.log(response);
        if (response?.success === true) {
          toast.success("SPOC request Rejected.");

          fetchData(currentPage, statusFilter, recordsPerPage, emailSearch);
          setIsReviewModalOpen(false);
        } else {
          toast.error(response?.message);

          fetchData(currentPage, statusFilter, recordsPerPage, emailSearch);
          setIsReviewModalOpen(false);
        }
      } catch (e) {
        console.log(e);
      }
    }

    setisLoading(false);
  };

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const pageChangeHandler = (page) => {
    try {
      setCurrentPage(page);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData(currentPage, statusFilter, recordsPerPage, emailSearch);
  }, [currentPage]);

  // Get SPOC REq Stats

  const [verifiedCount, setVerifiedCount] = useState(0);
  const [rejectedCount, setRejectedCount] = useState(0);
  const [reappliedCount, setReappliedCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

  const getStats = async () => {
    try {
      const response = await getSPOCReqStats();
      // console.log(response);
      if (response?.success === true) {
        for (let i = 0; i < response?.data.length; i++) {
          if (response?.data[i]._id === "REJECTED") {
            setRejectedCount(response?.data[i].count);
          } else if (response?.data[i]._id === "VERIFIED") {
            setVerifiedCount(response?.data[i].count);
          } else if (response?.data[i]._id === "REAPPLIED") {
            setReappliedCount(response?.data[i].count);
          } else if (response?.data[i]._id === "IN REVIEW") {
            setPendingCount(response?.data[i].count);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <>
      <Header />
      <div className={style.main}>
        <div className={style.sideBarConatiner}>
          <Sidebar role="sih-admin" activeTab="sopcReqs" />
        </div>
        <div className={style.mainContent}>
          <h3>Manage SPOC Requests</h3>

          {/* Stats */}
          <div className={style.statConatiner}>
            <div className={style.stat}>
              Verified Requests
              <div className={style.statNumber}> {verifiedCount} </div>
            </div>
            <div className={style.stat}>
              Rejected Requests
              <div className={style.statNumber}> {rejectedCount} </div>
            </div>
            <div className={style.stat}>
              Reapplied Requests
              <div className={style.statNumber}> {reappliedCount} </div>
            </div>
            <div className={style.stat}>
              Pending Requests
              <div className={style.statNumber}> {pendingCount} </div>
            </div>
          </div>

          {/* Search and Create Button */}
          <div className={style.tableHeadConatiner}>
            <div className={style.tableTitle}>SPOC Requests</div>
            <div className={style.searchConatiner}>
              <div className={`search`}>
                <input
                  name="search"
                  id="search"
                  placeholder="Search by Email"
                  defaultValue={emailSearch}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("searchButton").click();
                    }
                  }}
                />
                <div
                  id="searchButton"
                  className="searchIcon"
                  onClick={() => {
                    setEmailSearch(document.getElementById("search").value);
                    setCurrentPage(1);
                    fetchData(
                      1,
                      statusFilter,
                      recordsPerPage,
                      document.getElementById("search").value
                    );
                  }}
                >
                  <Icon icon="carbon:search" />
                </div>
              </div>
              <div
                className="filterContainer"
                onClick={() => {
                  setFilterOpen(true);
                }}
              >
                <img
                  src="https://saxenaprachi.github.io/publicAssets/icons/filter.svg"
                  alt="svg"
                />
                {filterOpen && (
                  <div
                    className="filter"
                    onMouseLeave={() => {
                      setFilterOpen(false);
                    }}
                  >
                    <div>
                      Filter By Status
                      <p>
                        {statusFilter.includes("IN REVIEW") ? (
                          <input
                            type="checkbox"
                            value="inReview"
                            id="inReview"
                            onChange={filterFetching}
                            checked
                          />
                        ) : (
                          <input
                            type="checkbox"
                            value="inReview"
                            id="inReview"
                            onChange={filterFetching}
                          />
                        )}
                        &nbsp; <label htmlFor="inReview">Pending</label>
                      </p>
                      <p>
                        {statusFilter.includes("VERIFIED") ? (
                          <input
                            type="checkbox"
                            value="verified"
                            id="verified"
                            onChange={filterFetching}
                            checked
                          />
                        ) : (
                          <input
                            type="checkbox"
                            value="verified"
                            id="verified"
                            onChange={filterFetching}
                          />
                        )}
                        &nbsp; <label htmlFor="verified">Verified</label>
                      </p>
                      <p>
                        {statusFilter.includes("REJECTED") ? (
                          <input
                            type="checkbox"
                            value="rejected"
                            id="rejected"
                            onChange={filterFetching}
                            checked
                          />
                        ) : (
                          <input
                            type="checkbox"
                            value="rejected"
                            id="rejected"
                            onChange={filterFetching}
                          />
                        )}
                        &nbsp; <label htmlFor="rejected">Rejected</label>
                      </p>
                      <p>
                        {statusFilter.includes("REAPPLIED") ? (
                          <input
                            type="checkbox"
                            value="reapplied"
                            id="reapplied"
                            onChange={filterFetching}
                            checked
                          />
                        ) : (
                          <input
                            type="checkbox"
                            value="reapplied"
                            id="reapplied"
                            onChange={filterFetching}
                          />
                        )}
                        &nbsp; <label htmlFor="reapplied">Re-Applied</label>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Admin Table */}
          {!isLoading ? (
            <>
              <Table
                tableName="spocReqs"
                tableHeader={[
                  "SCHOOL NAME",
                  "SCHOOL REG. CODE",
                  "SPOC NAME",
                  "EMAIL",
                  "TRACK 1 TEAMS",
                  "TRACK 2 TEAMS",
                  "STATUS",
                  "Action",
                ]}
                tableContent={spocReqData}
                review={review}
                reviewLoading={reviewLoading}
              />
              <div className={style.paginationContainer}>
                {/* Records Per Page */}
                <div>
                  Records Per Page &nbsp; &nbsp;
                  <select
                    onChange={(e) => {
                      setRecordsPerPage(e.target.value);
                      setCurrentPage(1);
                      fetchData(1, statusFilter, e.target.value, emailSearch);
                    }}
                    value={recordsPerPage}
                  >
                    <option value={parseInt(10)}>10</option>
                    <option value={parseInt(50)}>50</option>
                    <option value={parseInt(100)}>100</option>
                  </select>
                </div>
                {/* Pagination */}
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={pageChangeHandler}
                />
              </div>
            </>
          ) : (
            "Loading..."
          )}
        </div>
      </div>
      {isReviewModalOpen && (
        <Modal
          close={() => setIsReviewModalOpen(false)}
          confirmationMessage={"yo"}
          confirmationValue={"enable"}
          type="spocApplication"
          spocDetails={spocDetails}
          spocEmail={activeReq}
          spocApproval={approval}
          spocReject={reject}
          rejectionReason={rejectionReason}
          setRejectionReason={setRejectionReason}
          changeHandler={changeHandler}
        />
      )}
    </>
  );
};

export default SpocReqManagement;
