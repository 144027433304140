import { h, Component } from "preact";
import style from "./style.css";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
// import CustomPagination from "../pagination";

const Table = ({
  tableName,
  tableHeader,
  tableContent,
  confirmation,
  review,
  reviewLoading,
  viewEdit,
  viewActivity,
  editSubmission,
  previewSubmission,
  page
}) => {
  return (
    <>
      <div className={style.tableContainer}>
        <table className={style.table}>
          <thead>
            <tr>
              {tableHeader?.map((header, index) => (
                <td key={index}>{header}</td>
              ))}
            </tr>
          </thead>
          {tableName === "adminList" && (
            <tbody>
              {tableContent?.map((content, index) => (
                <tr key={index}>
                  <td className={style.submissionsList}>{content.username}</td>
                  <td className={style.submissionsList}>{content.email}</td>
                  <td className={style.textLink}>
                    <p
                      onClick={() => {
                        viewActivity(content._id, content.email);
                      }}
                      className={style.textLink}
                    >
                      View Activity
                    </p>
                  </td>
                  <td>
                    {content.status === "VERIFIED" ? (
                      <button
                        className={style.dangerButton}
                        onClick={() => {
                          confirmation("disable", `${content._id}`);
                        }}
                      >
                        Disable
                      </button>
                    ) : (
                      <button
                        className={style.verifiedButton}
                        onClick={() => {
                          confirmation("enable", `${content._id}`);
                        }}
                      >
                        Enable
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          {tableName === "spocReqs" && (
            <tbody>
              {tableContent?.map((content, index) => (
                <tr key={index}>
                  <td className={style.submissionsList}>
                    {content.schoolName}
                  </td>
                  <td>{content.schoolCode}</td>
                  <td className={style.submissionsList}>
                    {content.facultyFirstName} {content.facultyLastName}
                  </td>
                  <td className={style.submissionsList}>{content.email}</td>
                  <td className={style.centerAlign}>{content.teamsTier1}</td>
                  <td className={style.centerAlign}>{content.teamsTier2}</td>
                  <td>
                    {content.status === "REJECTED" && (
                      <button
                        className={`${style.dangerButton} ${style.disabled}`}
                      >
                        Rejected
                      </button>
                    )}
                    {content.status === "VERIFIED" && (
                      <button
                        className={`${style.verifiedButton} ${style.disabled} `}
                      >
                        Verified
                      </button>
                    )}
                    {content.status === "IN REVIEW" && (
                      <button
                        className={`${style.grayButton} ${style.disabled}`}
                      >
                        Pending
                      </button>
                    )}
                    {content.status === "REAPPLIED" && (
                      <button
                        className={`${style.yellowButton} ${style.disabled}`}
                      >
                        Reapplied
                      </button>
                    )}
                  </td>
                  <td>
                    {reviewLoading ? (
                      <button className={style.reviewButton}>Loading...</button>
                    ) : (
                      <button
                        className={style.reviewButton}
                        onClick={() => {
                          review(content._id, content.email, content.status);
                        }}
                      >
                        REVIEW
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}

          {tableName === "emailLogs" && (
            <tbody>
              {tableContent?.map((content, index) => (
                <tr key={index}>
                  <td className={style.submissionsList}>{(index + 1) + (10*page -10)}</td>
                  <td className={style.submissionsList}>{content?.subject}</td>
                  <td className={style.submissionsList}>{content?.sentTo}</td>
                  <td className={style.submissionsList}>{content?.sentCount}</td>
                  <td className={style.submissionsList}>{content?.sentBy}</td>
                  <td className={style.submissionsList}>{new Date(content?.sentOn).toLocaleString()}</td>
                  <td>
                    <p
                      className="clickable"
                      onClick={() => {
                        review(content?._id);
                      }}
                    >
                      Preview
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          {tableName === "teamList" && (
            <tbody>
              {tableContent?.map((content, index) => (
                <tr key={index}>
                  <td className={style.submissionsList}>{content?.teamName}</td>
                  <td className={style.submissionsList}>
                    {content?.mentor[0]?.name}
                  </td>
                  <td className={style.submissionsList}>
                    {content?.leader[0]?.name}
                  </td>
                  <td>{content?.submissions.length}</td>
                  <td>{content?.tier === "Tier-1" ? "Track 1" : "Track 2"}</td>
                  <td>
                    <div
                      className={`${style.statusConatiner} ${
                        content?.status === "COMPLETED" ? style.blue : ""
                      } ${
                        content?.status === "NOMINATED" ? style.darkgreen : ""
                      }`}
                    >
                      {content?.status === "NOMINATED"
                        ? "FREEZED"
                        : content?.status}
                    </div>
                  </td>
                  <td>
                    <p
                      className="clickable"
                      onClick={() => {
                        previewSubmission(content?._id);
                      }}
                    >
                      Preview
                    </p>
                  </td>
                  <td>
                    <div className={style.actionsContainer}>
                      {content?.status === "NOMINATED" ? (
                        <>
                          <div className={style.disabled}>
                            <Icon icon="bxs:edit" />
                          </div>
                          <div className={style.disabled}>
                            <Icon icon="ic:round-delete" />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            onClick={() => {
                              viewEdit(content?._id);
                            }}
                          >
                            <Icon icon="bxs:edit" />
                          </div>
                          <div
                            onClick={() => {
                              confirmation(content?._id);
                            }}
                          >
                            <Icon icon="ic:round-delete" />
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          {tableName === "submissionsList" && (
            <tbody>
              {tableContent?.map((content, index) => (
                <tr key={index}>
                  <td className={style.submissionsList}>{content?.title}</td>
                  <td className={style.submissionsList}>{content?.teamName}</td>
                  <td>{content?.theme}</td>
                  <td>
                    {content?.status === "CREATED" && (
                      <div
                        className={`${style.statusConatiner} ${style.disabled}`}
                      >
                        Pending
                      </div>
                    )}
                    {content?.status === "COMPLETED" && (
                      <button
                        className={`${style.grayButton} ${style.disabled} `}
                      >
                        Created
                      </button>
                    )}
                    {content?.status === "NOMINATED" && (
                      <button
                        className={`${style.nominatedButton} ${style.disabled} `}
                      >
                        Nominated
                      </button>
                    )}
                  </td>
                  <td>
                    <Link
                      to={content?.document}
                      target="_blank"
                      className={style.reviewButton}
                    >
                      View PPT
                    </Link>
                  </td>
                  <td>
                    <div
                      onClick={() => {
                        previewSubmission(content?._id);
                      }}
                      className={style.textLink}
                    >
                      Preview
                    </div>
                  </td>
                  <td>
                    <div className={style.actionsContainer}>
                      {content?.status === "NOMINATED" ? (
                        <>
                          <div className={style.disabled}>
                            <Icon icon="bxs:edit" />
                          </div>
                          <div className={style.disabled}>
                            <Icon icon="ic:round-delete" />
                          </div>
                        </>
                      ) : (
                        <>
                          {" "}
                          <div
                            onClick={() => {
                              editSubmission(content?._id);
                            }}
                          >
                            <Icon icon="bxs:edit" />
                          </div>
                          <div
                            onClick={() => {
                              confirmation(content?._id);
                            }}
                          >
                            <Icon icon="ic:round-delete" />
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        <div>{/* <CustomPagination /> */}</div>
      </div>
    </>
  );
};

export default Table;
