import { h, Component } from "preact";
import style from "./style.css";
import { useEffect } from "preact/hooks";
import { Icon } from "@iconify/react";
const AddMember = ({
  changeHandler,
  name,
  email,
  phone,
  dob,
  gender,
  teamTrack,
  MemberClass,
  stream,
  count,
  role,
  removeMember,
  type,
}) => {
  return (
    <>
      <div
        className={`${style.individualForm} ${
          type === "displayOnly" ? "" : style.memberForm
        } ${type === "displayOnly" ? style.removePadding : ""}`}
        key={count}
      >
        {count > 2 && (
          <div
            className={style.closeIndividualForm}
            onClick={() => {
              removeMember(count);
            }}
          >
            <Icon icon="maki:cross" />
          </div>
        )}
        {type === "displayOnly" ? (
          <></>
        ) : (
          <div className={style.overallFormHeading}>
            Member {count + 1} Details
          </div>
        )}

        {/* Member Name */}
        <div className={style.formGroup}>
          <label>
            Member Name <span>*</span>
          </label>
          {type === "displayOnly" ? (
            <input
              className={style.formInput}
              type="text"
              onChange={(e) => {
                changeHandler(count, "name", e.target.value, e);
              }}
              name="name"
              defaultValue={name}
              maxLength={255}
              readOnly
            />
          ) : (
            <input
              className={style.formInput}
              type="text"
              onChange={(e) => {
                changeHandler(count, "name", e.target.value, e);
              }}
              name="name"
              defaultValue={name}
              maxLength={255}
            />
          )}
        </div>

        {/* Member Email */}
        <div className={style.formGroup}>
          <label>
            Member Email <span>*</span>
          </label>
          {type === "displayOnly" ? (
            <input
              className={style.formInput}
              type="text"
              onChange={(e) => {
                changeHandler(count, "email", e.target.value, e);
              }}
              name="email"
              defaultValue={email}
              readOnly
            />
          ) : (
            <input
              className={style.formInput}
              type="text"
              onChange={(e) => {
                changeHandler(count, "email", e.target.value, e);
              }}
              name="email"
              defaultValue={email}
            />
          )}
        </div>

        {/* Member Phone */}
        <div className={style.formGroup}>
          <label>
            Member Phone <span>*</span>
          </label>
          {type === "displayOnly" ? (
            <input
              className={style.formInput}
              type="text"
              onChange={(e) => {
                changeHandler(count, "phone", e.target.value, e);
              }}
              name="phone"
              defaultValue={phone}
              readOnly
            />
          ) : (
            <input
              className={style.formInput}
              type="text"
              onChange={(e) => {
                changeHandler(count, "phone", e.target.value, e);
              }}
              name="phone"
              defaultValue={phone}
            />
          )}
        </div>

        {/* Date of Birth */}
        <div className={style.formGroup}>
          <label>
            Date of Birth <span>*</span>
          </label>
          {type === "displayOnly" ? (
            <input
              className={style.formInput}
              type="date"
              min="1995-01-01"
              max="2020-12-31"
              onChange={(e) => {
                changeHandler(count, "dob", e.target.value, e);
              }}
              name="dob"
              defaultValue={`${new Date(dob).getFullYear()}-${String(
                new Date(dob).getMonth() + 1
              ).padStart(2, "0")}-${String(new Date(dob).getDate()).padStart(
                2,
                "0"
              )}`}
              readOnly
            />
          ) : (
            <input
              className={style.formInput}
              type="date"
              min="1995-01-01"
              max="2020-12-31"
              onChange={(e) => {
                changeHandler(count, "dob", e.target.value, e);
              }}
              name="dob"
              defaultValue={`${new Date(dob).getFullYear()}-${String(
                new Date(dob).getMonth() + 1
              ).padStart(2, "0")}-${String(new Date(dob).getDate()).padStart(
                2,
                "0"
              )}`}
            />
          )}
        </div>

        {/* Class */}
        <div className={style.formGroup}>
          <label>
            Class <span>*</span>
          </label>
          <select
            onChange={(e) => {
              changeHandler(count, "class", e.target.value, e);
            }}
            name="MemberClass"
            value={MemberClass}
            disabled={type === "displayOnly" ? true : false}
          >
            <option value="" disabled>
              --Select One --
            </option>
            {teamTrack === "Tier-1" && (
              <>
                <option value={parseInt(6)}>6</option>
                <option value={parseInt(7)}>7</option>
                <option value={parseInt(8)}>8</option>
                <option value={parseInt(9)}>9</option>
              </>
            )}
            {teamTrack === "Tier-2" && (
              <>
                <option value={10}>10</option>
                <option value={11}>11</option>
                <option value={12}>12</option>
              </>
            )}
          </select>
        </div>
        {MemberClass > 10 && (
          <>
            {/* Stream */}
            <div className={style.formGroup}>
              <label>
                Stream <span>*</span>
              </label>
              <select
                onChange={(e) => {
                  changeHandler(count, "stream", e.target.value, e);
                }}
                name="stream"
                value={stream}
                disabled={type === "displayOnly" ? true : false}
              >
                <option value="" disabled>
                  --Select One --
                </option>
                <option>Science</option>
                <option>Commerce</option>
                <option>Arts</option>
              </select>
            </div>
          </>
        )}

        {/* Gender */}
        <div className={style.formGroup}>
          <label>
            Gender <span>*</span>
          </label>
          <select
            onChange={(e) => {
              changeHandler(count, "gender", e.target.value, e);
            }}
            disabled={type === "displayOnly" ? true : false}
            name="gender"
            value={gender}
          >
            <option value="" disabled>
              --Select One --
            </option>
            <option>Male</option>
            <option>Female</option>
          </select>
        </div>

        {/* Member Type */}
        <div className={style.formGroup}>
          <label>
            Member Type <span>*</span>
          </label>
          {type === "displayOnly" ? (
            <select
              onChange={(e) => {
                changeHandler(count, "role", e.target.value, e);
              }}
              name="role"
              value={role}
              disabled
            >
              <option value="" disabled>
                --Select One --
              </option>
              <option>Leader</option>
              <option>Member</option>
            </select>
          ) : (
            <select
              onChange={(e) => {
                changeHandler(count, "role", e.target.value, e);
              }}
              name="role"
              value={role}
            >
              <option value="" disabled>
                --Select One --
              </option>
              <option>Leader</option>
              <option>Member</option>
            </select>
          )}
        </div>
        {type === "displayOnly" && <hr className="w-100" />}
      </div>
    </>
  );
};

export default AddMember;
