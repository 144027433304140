import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: false,
};

export const loadingSlice = createSlice({
  name: "globalLoader",
  initialState,
  reducers: {
    setGlobalLoading: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setGlobalLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
