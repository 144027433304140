import { h, Component } from "preact";
import style from "./style.css";

const GlobalLoader = () => {
  return (
    <div className={style.loaderOverlay}>
      <div className="loader">
        <img
          src="https://saxenaprachi.github.io/publicAssets/icons/sih.webp"
          alt="sih"
        />
      </div>
      <div className="loader">Loading...</div>
    </div>
  );
};

export default GlobalLoader;
