import { h, Component } from "preact";
import style from "./style.css";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import Table from "../../../components/table";
import { useState, useEffect } from "preact/hooks";
import toast from "react-hot-toast";

// APIs
import {
  addAdmin,
  disableAdmin,
  enableAdmin,
  getActivity,
} from "../../../modules/super-admin/actions";
import { getAdmins } from "../../../modules/super-admin/actions";
import Modal from "../../../components/modal";

const AdminManagement = () => {
  // Fetching Admins Data
  const [isAdminLoading, setIsAdminLoading] = useState(false);
  const [adminData, setAdminData] = useState([]);

  const fetchData = async () => {
    try {
      setIsAdminLoading(true);
      const response = await getAdmins(1);
      // console.log(response);
      setAdminData(response?.data);
      setIsAdminLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [allValues, setAllValues] = useState({
    name: "",
    email: "",
  });

  const changeHandler = (e) => {
    setAllValues((prevValues) => {
      return { ...prevValues, [e.target.name]: e.target.value };
    });
  };

  // Loading
  const [isLoading, setIsLoading] = useState(false);

  // CreateAdmin
  const createAdmin = async (e) => {
    e.preventDefault();
    let filter =
      /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    var valEmail = String(allValues.email).search(filter) != -1;

    if (allValues.name === "") {
      toast.error(`Kindly fill a Name.`);
    } else if (allValues.email === "") {
      toast.error(`Kindly fill an Email.`);
    } else if (!valEmail) {
      // valid email
      toast.error(`Email is not valid`);
    } else {
      try {
        setIsLoading(true);

        let data = {
          name: allValues.name,
          email: allValues.email,
        };
        const response = await addAdmin(data);
        // console.log("yeh aarah h response: ", response);
        if (response?.success === true) {
          toast.success("Admin added Successfully!");
          setIsLoading(false);
          fetchData();
        } else {
          toast.error(response?.message);
          setIsLoading(false);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const cancelAdmin = () => {
    setAllValues({
      name: "",
      email: "",
    });
    document.getElementById("name").value = "";
    document.getElementById("email").value = "";
  };

  // Enable or Disable admin
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [confirmationValue, setConfirmationValue] = useState("");
  const [activeAdminId, setActiveAdminId] = useState("");
  const [activeAdminEmail, setActiveAdminEmail] = useState("");

  const confirmation = (value, id) => {
    setConfirmationValue(value);
    setIsConfirmationModalOpen(true);
    setActiveAdminId(id);
    if (value === "disable") {
      setConfirmationMessage("Are you sure you want to disable this admin?");
    } else if (value === "enable") {
      setConfirmationMessage("Are you sure you want to enable this Admin");
    }
  };

  // View Activity
  const [viewActivityModal, setViewActivityModal] = useState(false);
  const [activityValues, setActivityValues] = useState([]);
  const [activityPage, setActivityPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const viewActivity = async (id, email) => {
    try {
      setActivityPage(1);
      const response = await getActivity(id, 1);
      // console.log(response);
      if (response?.success === true) {
        setActiveAdminId(id);
        setActiveAdminEmail(email);
        setViewActivityModal(true);
        setActivityValues(response?.data);
        setTotalPages(response?.pages);
      } else {
        toast.error(response?.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const viewMore = async (id) => {
    try {
      let page = activityPage + 1;
      const response = await getActivity(id, page);
      // console.log("viewMore: ", response);
      setActivityPage(page);
      let allActivity = activityValues;
      if (response?.success === true) {
        setActiveAdminId(id);
        setViewActivityModal(true);
        allActivity = allActivity.concat(response?.data);
        setActivityValues(allActivity);
      } else {
        toast.error(response?.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Disable admin Function
  const disable = async () => {
    try {
      setIsAdminLoading(true);

      let data = {
        admin: activeAdminId,
      };
      const response = await disableAdmin(data);
      if (response?.success === true) {
        toast.success("Admin disabled Successfully!");
        setIsAdminLoading(false);
        fetchData();
      } else {
        toast.error(response?.message);
        setIsAdminLoading(false);
      }
      setIsConfirmationModalOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  // Enable admin Function
  const enable = async () => {
    try {
      setIsAdminLoading(true);

      let data = {
        admin: activeAdminId,
      };
      const response = await enableAdmin(data);
      if (response?.success === true) {
        toast.success("Admin Enabled Successfully!");
        setIsAdminLoading(false);
        fetchData();
      } else {
        toast.error(response?.message);
        setIsAdminLoading(false);
      }
      setIsConfirmationModalOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Header />
      <div className={style.main}>
        <div className={style.sideBarConatiner}>
          <Sidebar role="super-admin" activeTab="adminManagement" />
        </div>
        <div className={style.mainContent}>
          <h3>Manage Admins</h3>
          {/* Form - Create Admin */}
          <div className={style.createAdminForm}>
            <h4>Create Admin</h4>
            <div className="redStarFields ">
              * Indicates required fields
            </div>
            <form className={style.createForm}>
              {/* Name */}
              <div className={style.inputGroup}>
                <label>Name <span className="textRed">*</span></label>
                <input
                  name="name"
                  id="name"
                  onInput={changeHandler}
                  placeholder="Enter your Name"
                  defaultValue={allValues.name}
                />
              </div>
              {/* Email */}
              <div className={style.inputGroup}>
                <label>Email <span className="textRed">*</span></label>
                <input
                  name="email"
                  id="email"
                  onInput={changeHandler}
                  placeholder="Enter your email"
                  defaultValue={allValues.email}
                />
              </div>

              <div className={style.buttonContainer}>
                <button className={style.createButton} onClick={createAdmin}>
                  {!isLoading ? "Add admin" : "Loading..."}
                </button>
                <div className={style.cancelButton} onClick={cancelAdmin}>
                  Cancel
                </div>
              </div>
            </form>
          </div>

          {/* Admin Table */}
          {!isAdminLoading ? (
            <Table
              tableName="adminList"
              tableHeader={["Name", "Email", "Activity", "Action"]}
              tableContent={adminData}
              confirmation={confirmation}
              viewActivity={viewActivity}
            />
          ) : (
            "Loading..."
          )}
        </div>
      </div>
      {isConfirmationModalOpen ? (
        <Modal
          close={() => setIsConfirmationModalOpen(false)}
          confirmationMessage={confirmationMessage}
          disable={disable}
          enable={enable}
          confirmationValue={confirmationValue}
          type="confimationMail"
        />
      ) : (
        <></>
      )}
      {viewActivityModal && (
        <>
          <Modal
            close={() => {
              setViewActivityModal(false);
            }}
            type="viewActivity"
            activityValues={activityValues}
            viewMore={viewMore}
            activeAdminId={activeAdminId}
            activeAdminEmail={activeAdminEmail}
            totalPages={totalPages}
            activityPage={activityPage}
          />
        </>
      )}
    </>
  );
};

export default AdminManagement;
