import { h } from "preact";
import style from "./style.css";
import { Icon } from "@iconify/react";
import { useState } from "preact/hooks";
import Header from "../../../components/header";
import SpocDashboardTop from "../../../components/spocDashboardTop";
import { Link } from "react-router-dom";
import { getSPOCStats } from "../../../modules/spoc/actions";
import { useEffect } from "react";

// Progress Bar
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import ProgressLine from "../../../components/graphs/progressline";
import HorizontalBarChart from "../../../components/graphs/horizontalBarChart";
import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../../redux/slices/loadingSlice";

const SPOCStats = () => {
  const [statData, setStatData] = useState({});
  const [counts, setCounts] = useState({
    tier1totalHardwareCount: 0,
    tier1totalSoftwareCount: 0,
    tier1Submissions: 0,
    tier1registeredToday: 0,
    tier1SubmissionsValue: 0,
    tier1totalNomination: 0,
    submissionWiseTeamsTier1: [0, 0, 0],
    tier2totalHardwareCount: 0,
    tier2totalSoftwareCount: 0,
    tier2Submissions: 0,
    tier2registeredToday: 0,
    tier2totalNomination: 0,
    submissionWiseTeamsTier2: [0, 0, 0, 0],
  });
  const dispatch = useDispatch();
  const [chartDisplay, setchartDisplay] = useState(false);

  const fetchData = async () => {
    dispatch(setGlobalLoading(true));
    try {
      const response = await getSPOCStats();
      // console.log(response);
      if (response?.success === true) {
        const submissionsTier1 = response?.data?.submissionsTier1 || [];
        const submissionsTier2 = response?.data?.submissionsTier2 || [];
        const teams = response?.data?.teams || [];
        let submissionWiseTeamsTier1 = [0, 0, 0, 0];
        for (
          let i = 0;
          i < response?.data.submissionWiseTeamsTier1.length;
          i++
        ) {
          if (response?.data.submissionWiseTeamsTier1[i]._id === 0) {
            submissionWiseTeamsTier1[0] =
              response?.data.submissionWiseTeamsTier1[i].count;
          } else if (response?.data.submissionWiseTeamsTier1[i]._id === 1) {
            submissionWiseTeamsTier1[1] =
              response?.data.submissionWiseTeamsTier1[i].count;
          } else if (response?.data.submissionWiseTeamsTier1[i]._id === 2) {
            submissionWiseTeamsTier1[2] =
              response?.data.submissionWiseTeamsTier1[i].count;
          } else if (response?.data.submissionWiseTeamsTier1[i]._id === 3) {
            submissionWiseTeamsTier1[3] =
              response?.data.submissionWiseTeamsTier1[i].count;
          }
        }
        let submissionWiseTeamsTier2 = [0, 0, 0, 0];
        for (
          let i = 0;
          i < response?.data.submissionWiseTeamsTier2.length;
          i++
        ) {
          if (response?.data.submissionWiseTeamsTier2[i]._id === 0) {
            submissionWiseTeamsTier2[0] =
              response?.data.submissionWiseTeamsTier2[i].count;
          } else if (response?.data.submissionWiseTeamsTier2[i]._id === 1) {
            submissionWiseTeamsTier2[1] =
              response?.data.submissionWiseTeamsTier2[i].count;
          } else if (response?.data.submissionWiseTeamsTier2[i]._id === 2) {
            submissionWiseTeamsTier2[2] =
              response?.data.submissionWiseTeamsTier2[i].count;
          } else if (response?.data.submissionWiseTeamsTier2[i]._id === 3) {
            submissionWiseTeamsTier2[3] =
              response?.data.submissionWiseTeamsTier2[i].count;
          }
        }

        // Calculate counts for Tier 1
        var tier1totalHardwareCount =
          submissionsTier1.find((submission) => submission._id === "HARDWARE")
            ?.count || 0;
        var tier1totalSoftwareCount =
          submissionsTier1.find((submission) => submission._id === "SOFTWARE")
            ?.count || 0;
        var tier1Submissions = submissionsTier1.reduce(
          (acc, curr) => acc + curr.count,
          0
        );
        var tier1registeredToday =
          teams.find((team) => team._id === "Tier-1")?.today || 0;
        var tier1totalNomination = submissionsTier1.reduce(
          (acc, curr) => acc + curr.nomimated,
          0
        );

        // Calculate counts for Tier 2
        var tier2totalHardwareCount =
          submissionsTier2.find((submission) => submission._id === "HARDWARE")
            ?.count || 0;
        var tier2totalSoftwareCount =
          submissionsTier2.find((submission) => submission._id === "SOFTWARE")
            ?.count || 0;
        var tier2Submissions = submissionsTier2.reduce(
          (acc, curr) => acc + curr.count,
          0
        );
        var tier2registeredToday =
          teams.find((team) => team._id === "Tier-2")?.today || 0;
        var tier2totalNomination = submissionsTier2.reduce(
          (acc, curr) => acc + curr.nomimated,
          0
        );

        setCounts({
          tier1totalHardwareCount: tier1totalHardwareCount,
          tier1totalSoftwareCount: tier1totalSoftwareCount,
          tier1Submissions: tier1Submissions,
          tier1registeredToday: tier1registeredToday,
          tier1totalNomination: tier1totalNomination,
          tier2totalHardwareCount: tier2totalHardwareCount,
          tier2totalSoftwareCount: tier2totalSoftwareCount,
          tier2Submissions: tier2Submissions,
          tier2registeredToday: tier2registeredToday,
          tier2totalNomination: tier2totalNomination,
          submissionWiseTeamsTier1: submissionWiseTeamsTier1,
          submissionWiseTeamsTier2: submissionWiseTeamsTier2,
        });
        setchartDisplay(true);
      }
    } catch (e) {
      console.log(e);
    }
    dispatch(setGlobalLoading(false));
  };
  useEffect(() => {
    fetchData();
  }, []);
  // console.log("c", counts);

  return (
    <>
      <Header page="spoc-dashboard" />
      <img
        src="https://saxenaprachi.github.io/publicAssets/images/shuttleRocket.webp"
        className={style.rocketRight}
      />
      <div className={style.spocDashboard}>
        <SpocDashboardTop />

        <div className={style.spocDasboardContent}>
          <div className={style.tabsOuterBox}>
            {/* Tab buttons */}
            <div className={style.tabs}>
              <Link to="/spoc/dashboard">DASHBOARD</Link>
              <Link to="/spoc/stats" className={`${style.active}`}>
                STATISTICS
              </Link>
              <Link to="/spoc/manage-teams">MANAGE TEAMS</Link>
              <Link to="/spoc/manage-submissions">MANAGE SUBMISSIONS</Link>
            </div>

            <hr className={style.horizontalLine} />
            {/* Tab content */}
            <div className={style.allTabsContentOuter}>
              <div>
                <h4 className={style.mainHeding}>Statstics</h4>
              </div>
              {/* 2 Columns */}
              <div className={style.towColumnContainer}>
                {/* Track 1 */}
                <div className={style.columnConatiner}>
                  <div className={style.columnHeading}>
                    Track 1 (Class 6 - 9)
                  </div>

                  {/* stats */}
                  <div className={style.trackStatsConatiner}>
                    <div className={style.statBlockContainer}>
                      <div className={style.statBlock}>
                        <div className={style.totalTeams}>
                          <p>Total Number of Teams</p>
                          <Icon icon="fluent:people-team-16-filled" />
                        </div>
                        <div className={style.totalTeamsCount}>
                          {counts?.submissionWiseTeamsTier1.reduce(
                            (acc, val) =>
                              acc + (typeof val === "number" ? val : 0),
                            0
                          )}
                        </div>
                        <div className={style.registeredToday}>
                          <Icon icon="ph:arrow-up" />
                          <span>{counts?.tier1registeredToday}</span>
                          Registered Today
                        </div>
                      </div>
                      <div className={style.statBlock}>
                        <p className={style.submissionHeading}>
                          Submission Category
                        </p>
                        <div
                          className={`${style.ProgressConatiner} ${style.submissionCircle}`}
                        >
                          <CircularProgressbar
                            value={
                              counts?.tier1totalHardwareCount +
                                counts?.tier1totalSoftwareCount ==
                              0
                                ? 0
                                : (counts?.tier1totalSoftwareCount /
                                    (counts?.tier1totalHardwareCount +
                                      counts?.tier1totalSoftwareCount)) *
                                  100
                            }
                            text={
                              counts?.tier1totalHardwareCount +
                              counts?.tier1totalSoftwareCount
                            }
                            className={style.progessCircle}
                            strokeWidth={4}
                            styles={buildStyles({
                              rotation: -0.25,
                              strokeLinecap: "butt",
                              textSize: "30px",
                              pathTransitionDuration: 0.5,
                              pathColor: `${
                                counts?.tier2totalSoftwareCount == 0
                                  ? "var(--spocProgressbarColor1)"
                                  : "var(--sih_blue)"
                              }`,
                              textColor: "#000",
                              trailColor: `${
                                counts?.tier2totalHardwareCount == 0
                                  ? "var(--spocProgressbarColor1)"
                                  : "var(--lightOrange)"
                              }`,
                              backgroundColor: "#3e98c7",
                            })}
                          />
                          <div className={style.category}>
                            <p>
                              Hardware -{" "}
                              <span>{counts?.tier1totalHardwareCount}</span>
                            </p>
                            <p>
                              Software -{" "}
                              <span>{counts?.tier1totalSoftwareCount}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${style.statBlock} ${style.progressBarBlock} mb-1`}
                      >
                        <p className={style.submissionHeading}>
                          Total No. of idea submitted & not submitted yet
                        </p>
                        <ProgressLine
                          label=""
                          backgroundColor="var(--spocProgressbarColor1)"
                          visualParts={[
                            {
                              // counts?.tier1totalNomination
                              percentage: `${
                                (counts?.tier1totalNomination / 30) * 100
                              }%`,
                              color: "var(--sih_blue)",
                              submissionCount: `${counts?.tier1totalNomination}`,
                            },
                            {
                              percentage: `${
                                ((counts?.tier1Submissions -
                                  counts.tier1totalNomination) /
                                  30) *
                                100
                              }%`,
                              color: "var(--spocProgressbarColor3)",
                              submissionCount: `${counts?.tier1Submissions}`,
                            },
                          ]}
                        />

                        <div className={style.caption}>
                          <p>Number of idea submission</p>
                          <p>Number of Nomination</p>
                          <p>Maximum number of submission</p>
                        </div>
                      </div>
                      <div
                        className={`${style.statBlock} ${style.HorizontalBarChart}`}
                      >
                        <div className={style.horizontalBarChartContainer}>
                          {chartDisplay && (
                            <HorizontalBarChart
                              className="mb-1"
                              submissionWiseTeamsTier={
                                counts?.submissionWiseTeamsTier1
                              }
                            />
                          )}
                          <p className={`${style.submissionHeading}`}>
                            Statistics by No. of teams with submission
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Track 2 */}
                <div className={style.columnConatiner}>
                  <div className={style.columnHeading}>
                    Track 2 (Class 10 - 12)
                  </div>

                  {/* stats */}
                  <div className={style.trackStatsConatiner}>
                    <div className={style.statBlockContainer}>
                      <div className={style.statBlock}>
                        <div className={style.totalTeams}>
                          <p>Total Number of Teams</p>
                          <Icon icon="fluent:people-team-16-filled" />
                        </div>
                        <div className={style.totalTeamsCount}>
                          {counts?.submissionWiseTeamsTier2.reduce(
                            (acc, val) =>
                              acc + (typeof val === "number" ? val : 0),
                            0
                          )}
                        </div>
                        <div className={style.registeredToday}>
                          <Icon icon="ph:arrow-up" />
                          <span>{counts?.tier2registeredToday}</span>
                          Registered Today
                        </div>
                      </div>
                      <div className={style.statBlock}>
                        <p className={style.submissionHeading}>
                          Submission Category
                        </p>
                        <div
                          className={`${style.ProgressConatiner} ${style.submissionCircle}`}
                        >
                          <CircularProgressbar
                            value={
                              counts?.tier2totalHardwareCount +
                                counts?.tier2totalSoftwareCount ==
                              0
                                ? 0
                                : (counts?.tier2totalSoftwareCount /
                                    (counts?.tier2totalHardwareCount +
                                      counts?.tier2totalSoftwareCount)) *
                                  100
                            }
                            text={
                              counts?.tier2totalHardwareCount +
                              counts?.tier2totalSoftwareCount
                            }
                            className={style.progessCircle}
                            strokeWidth={4}
                            styles={buildStyles({
                              rotation: -0.25,
                              strokeLinecap: "butt",
                              textSize: "30px",
                              pathTransitionDuration: 0.5,
                              pathColor: `${
                                counts?.tier2totalSoftwareCount == 0
                                  ? "var(--spocProgressbarColor1)"
                                  : "var(--sih_blue)"
                              }`,
                              textColor: "#000",
                              trailColor: `${
                                counts?.tier2totalHardwareCount == 0
                                  ? "var(--spocProgressbarColor1)"
                                  : "var(--lightOrange)"
                              }`,
                              backgroundColor: "#3e98c7",
                            })}
                          />
                          <div className={style.category}>
                            <p>
                              Hardware -{" "}
                              <span>{counts?.tier2totalHardwareCount}</span>
                            </p>
                            <p>
                              Software -{" "}
                              <span>{counts?.tier2totalSoftwareCount}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${style.statBlock} ${style.progressBarBlock} mb-1`}
                      >
                        <p className={style.submissionHeading}>
                          Total No. of idea submitted & not submitted yet
                        </p>
                        <ProgressLine
                          label=""
                          backgroundColor="var(--spocProgressbarColor1)"
                          visualParts={[
                            {
                              // counts?.tier1totalNomination
                              percentage: `${
                                (counts?.tier2totalNomination / 30) * 100
                              }%`,
                              color: "var(--sih_blue)",
                              submissionCount: `${counts?.tier2totalNomination}`,
                            },
                            {
                              percentage: `${
                                ((counts?.tier2Submissions -
                                  counts.tier2totalNomination) /
                                  30) *
                                100
                              }%`,
                              color: "var(--spocProgressbarColor3)",
                              submissionCount: `${counts?.tier2Submissions}`,
                            },
                          ]}
                        />

                        <div className={style.caption}>
                          <p>Number of idea submission</p>
                          <p>Number of Nomination</p>
                          <p>Maximum number of submission</p>
                        </div>
                      </div>
                      <div
                        className={`${style.statBlock} ${style.HorizontalBarChart}`}
                      >
                        <div className={style.horizontalBarChartContainer}>
                          {chartDisplay && (
                            <HorizontalBarChart
                              className="mb-1"
                              submissionWiseTeamsTier={
                                counts?.submissionWiseTeamsTier2
                              }
                            />
                          )}
                          <p className={`${style.submissionHeading}`}>
                            Statistics by No. of teams with submission
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default SPOCStats;
