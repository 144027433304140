import { h, Component } from "preact";
import style from "./styles.css";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

const Sidebar = ({ role, activeTab }) => {
  return (
    <div class={`${style.sidebar} boxShadowLight`}>
      <ul>
        {role === "super-admin" && (
          <>
            <li
              className={`${style.asideItem} ${
                activeTab === "dashboard" ? style.active : ""
              }`}
            >
              <Link to="/super-admin/dashboard" className={style.asideItem}>
                <Icon
                  icon="material-symbols:dashboard"
                  color="#aaa"
                  width="20"
                  className={style.asideItem}
                />
                <div className={style.asideItemText}>Dashboard</div>
              </Link>
            </li>
            <li
              className={`${style.asideItem} ${
                activeTab === "adminManagement" ? style.active : ""
              }`}
            >
              <Link to="/super-admin/admin-management/">
                <Icon
                  icon="ri:user-settings-fill"
                  color="#aaa"
                  width="20"
                  className={style.asideItem}
                />
                <div className={style.asideItemText}>Manage Admin</div>
              </Link>
            </li>
          </>
        )}
        {role === "sih-admin" && (
          <>
            <li
              className={`${style.asideItem} ${
                activeTab === "dashboard" ? style.active : ""
              }`}
            >
              <Link to="/sih-admin/dashboard" className={style.asideItem}>
                <Icon
                  icon="material-symbols:dashboard"
                  color="#aaa"
                  width="20"
                  className={style.asideItem}
                />
                <div className={style.asideItemText}>Dashboard</div>
              </Link>
            </li>
            <li
              className={`${style.asideItem} ${
                activeTab === "sopcReqs" ? style.active : ""
              }`}
            >
              <Link to="/sih-admin/spoc-requests" className={style.asideItem}>
                <Icon
                  icon="ri:user-settings-fill"
                  color="#aaa"
                  width="20"
                  className={style.asideItem}
                />
                <div className={style.asideItemText}>SPOC Request</div>
              </Link>
            </li>
            <li
              className={`${style.asideItem} ${
                activeTab === "spocs" ? style.active : ""
              }`}
            >
              <Link to="/sih-admin/spocs" className={style.asideItem}>
                <Icon
                  icon="ri:user-settings-fill"
                  color="#aaa"
                  width="20"
                  className={style.asideItem}
                />
                <div className={style.asideItemText}>SPOCs</div>
              </Link>
            </li>
            <li
              className={`${style.asideItem} ${
                activeTab === "noticeBoard" ? style.active : ""
              }`}
            >
              <Link to="/sih-admin/notice-board" className={style.asideItem}>
                <Icon
                  icon="pepicons-pop:bulletin-notice"
                  color="#aaa"
                  width="20"
                  className={style.asideItem}
                />
                <div className={style.asideItemText}>SPOC Notice Board</div>
              </Link>
            </li>
            <li
              className={`${style.asideItem} ${
                activeTab === "ticker" ? style.active : ""
              }`}
            >
              <Link to="/sih-admin/landing-ticker" className={style.asideItem}>
                <Icon
                  icon="gridicons:notice"
                  color="#aaa"
                  width="20"
                  className={style.asideItem}
                />
                <div className={style.asideItemText}>Landing Page Ticker</div>
              </Link>
            </li>
            <li
              className={`${style.asideItem} ${
                activeTab === "emailService" ? style.active : ""
              }`}
            >
              <Link to="/sih-admin/email-service" className={style.asideItem}>
                <Icon
                  icon="icon-park-outline:send-email"
                  color="#aaa"
                  width="20"
                  className={style.asideItem}
                />
                <div className={style.asideItemText}>Send Emails</div>
              </Link>
            </li>
            {/* <li>
                <Link to="/sih-admin/reports" className={style.asideItem}>
                  <Icon
                    icon="ri:user-settings-fill"
                    color="#aaa"
                    width="20"
                    className={style.asideItem}
                  />
                  <div className={style.asideItemText}>Reports</div>
                </Link>
              </li>
              <li>
                <Link to="/sih-admin/evaluators" className={style.asideItem}>
                  <Icon
                    icon="ri:user-settings-fill"
                    color="#aaa"
                    width="20"
                    className={style.asideItem}
                  />
                  <div className={style.asideItemText}>Evaluator</div>
                </Link>
              </li>
              <li>
                <Link
                  to="/sih-admin/national-shortlists"
                  className={style.asideItem}
                >
                  <Icon
                    icon="ri:user-settings-fill"
                    color="#aaa"
                    width="20"
                    className={style.asideItem}
                  />
                  <div className={style.asideItemText}>National Shortlist</div>
                </Link>
              </li>
              <li>
                <Link
                  to="/sih-admin/nodal-officers"
                  className={style.asideItem}
                >
                  <Icon
                    icon="ri:user-settings-fill"
                    color="#aaa"
                    width="20"
                    className={style.asideItem}
                  />
                  <div className={style.asideItemText}>Nodal Officers</div>
                </Link>
              </li> */}
          </>
        )}
      </ul>
      <div className={style.poweredByH2sLogo}>
        Powered By :
        <Link to="https://hack2skill.com" target="_blank">
          <img
            width={70}
            src="https://saxenaprachi.github.io/publicAssets/images/H2S_Gradient_Logo.webp"
          />
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
