import { h } from "preact";
import style from "./style.css";
import { useState, useEffect } from "preact/hooks";
import { updateSchool, getProfile } from "../../../modules/spoc/actions";
import toast from "react-hot-toast";

// Persist
import usePersist from "../../../hooks/usePersist";
import { useSelector } from "react-redux/es/hooks/useSelector";
import Header from "../../../components/header";
import { Link, useNavigate } from "react-router-dom";
import { uploadFile } from "../../../modules/upload/actions";
import { makeSpoc } from "../../../modules/role/actions";
import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../../redux/slices/loadingSlice";

// state city data
import stateCityData from "../../../data/states_cities.json"

const SchoolRegistration = () => {
  const navigator = useNavigate();
  const [isStateBoard, setStateBoard] = useState(false);
  const [isOthersBoard, setOthersBoard] = useState(false);

  // School Type
  const [centralGov, setCentralGov] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [spocStatus, setSpocStatus] = useState("");
  const [reapply, setReapply] = useState(false);
  const [mainLoading, setMainLoading] = useState(true);
  const [spocEmail, setSpocEmail] = useState("");

  // All Data ====================>
  const [allValues, setAllValues] = useState({
    schoolBoardType: "",
    schoolCode: "",
    schoolBoardName: "",
    schoolType: "",
    schoolGovType: "",
    address: "",
    pincode: "",
    schoolName: "",
    country: "",
    state: "",
    city: "",
    facultyFirstName: "",
    facultyLastName: "",
    facultyDesignation: "",
    email: "",
    facultyPhone: "",
    schoolLogo: "",
    consentLetter: "",
  });

  const dispatch = useDispatch();
  // Fetch Data
  const fetchData = async () => {
    try {
      const response = await getProfile();

      if (response?.message === "Profile found") {
        setSpocEmail(response?.body.email);
        setAllValues((prevValues) => {
          return { ...prevValues, email: response?.body.email };
        });
        for (let i = 0; i < response?.roles?.length; i++) {
          if (response?.roles[i].role === "SPOC") {
            // console.log("yeh aaya backend se: ", response?.roles[i].status);
            setSpocStatus(response?.roles[i].status);
            if (response?.roles[i].status === "VERIFIED") {
              navigator("/spoc/dashboard");
              // window.location.reload();
            }
          }
        }
      }
      setMainLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (spocStatus != "NOT VERIFIED") {
        // console.log("spocStatus: ", spocStatus);
        fetchData();
      }
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [spocStatus]);

  useEffect(() => {
    fetchData();
    // intialCall();
  }, []);
  // Country===============================>
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("IN");
  useEffect(() => {
    // Get the list of countries
    const countryList = [];
    // Map the country list to an array of options
    const countryOptions = countryList.map((country) => (
      <option key={country.isoCode} value={country.isoCode}>
        {country.name}
      </option>
    ));
    setCountries(countryOptions);
  }, []);
  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    setAllValues((prevValues) => {
      return { ...prevValues, country: e.target.value };
    });
  };

  // State==============================>
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  useEffect(() => {
    if (selectedCountry) {
      // Get the list of states for the selected country
      const stateList = Object.keys(stateCityData);

      // Map the state list to an array of options
      const stateOptions = stateList.map((state) => (
        <option key={state} value={state}>
          {state}
        </option>
      ));

      setStates(stateOptions);
    } else {
      setStates([]);
      setSelectedState("");
    }
  }, [selectedCountry]);
  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
    setAllValues((prevValues) => {
      return {
        ...prevValues,
        state: e.target.options[e.target.selectedIndex].label,
      };
    });
  };

  // City=============================>
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  useEffect(() => {
    if (selectedCountry && selectedState) {
      // Get the list of cities for the selected state and country
      const cityList = stateCityData[allValues.state];

      // Map the city list to an array of options
      const cityOptions = cityList.map((city) => (
        <option key={city} value={city}>
          {city}
        </option>
      ));

      setCities(cityOptions);
    } else {
      setCities([]);
      setSelectedCity("");
    }
  }, [selectedCountry, selectedState]);
  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
    setAllValues((prevValues) => {
      return {
        ...prevValues,
        city: e.target.options[e.target.selectedIndex].value,
      };
    });
  };

  // Change Handler
  const changeHandler = (e) => {
    let val = e.target.value;
    if (e.target.name === "pincode") {
      val = val.slice(0, 6);
      e.target.value = val;
    }
    if (e.target.name === "facultyPhone") {
      val = val.slice(0, 10);
      e.target.value = val;
    }
    setAllValues((prevValues) => {
      return { ...prevValues, [e.target.name]: val };
    });
  };

  // Form submission
  const [isLoading, setIsLoading] = useState(false);
  const Submit = async (e) => {
    e.preventDefault();

    // Email Testing
    let filter =
      /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    var valEmail = String(allValues.email).search(filter) != -1;

    // Phone Testing
    let phoneFilter = /^\d{10}$/;
    var valPhone = phoneFilter.test(allValues.facultyPhone);

    // Pincode Validation
    let PincodeFilter = /^[1-9]{1}[0-9]{5}$/;
    var valPincode = PincodeFilter.test(allValues.pincode);

    if (allValues.schoolBoardType === "") {
      toast.error("Select Education Board");
    } else if (
      (allValues.schoolBoardType != "CBSE" &&
        allValues.schoolBoardName === "CBSE") ||
      (allValues.schoolBoardType != "ICSE" &&
        allValues.schoolBoardName === "ICSE") ||
      (allValues.schoolBoardType != "NIOS" &&
        allValues.schoolBoardName === "NIOS") ||
      allValues.schoolBoardName === ""
    ) {
      toast.error("Add Board Name");
    } else if (allValues.schoolType === "") {
      toast.error("School Type is required");
    } else if (
      allValues.schoolType === "Central Govt School" &&
      allValues.schoolGovType === ""
    ) {
      toast.error("Central Government School Type is required");
    } else if (allValues.schoolCode === "") {
      toast.error("School Code is required");
    } else if (allValues.schoolName === "") {
      toast.error("School Name is required");
    } else if (allValues.state === "") {
      toast.error("State is required");
    } else if (allValues.city === "") {
      toast.error("City is required");
    } else if (allValues.address === "") {
      toast.error("School Address is required");
    } else if (allValues.pincode === "") {
      toast.error("School Pincode is required");
    } else if (!valPincode) {
      toast.error("School Pincode is not Valid");
    } else if (allValues.facultyFirstName === "") {
      toast.error("Faculty First Name is required");
    } else if (allValues.facultyLastName === "") {
      toast.error("Faculty Last Name is required");
    } else if (allValues.facultyDesignation === "") {
      toast.error("Faculty Designation is required");
    } else if (allValues.email === "") {
      toast.error("Email is required");
    } else if (!valEmail) {
      toast.error("Email is invalid");
    } else if (allValues.facultyPhone === "") {
      toast.error("Phone Number is required");
    } else if (!valPhone) {
      toast.error("Phone Number must be a 10 digit number");
    } else if (allValues.consentLetter === "") {
      toast.error("Consent Letter is required");
    } else if (allValues.schoolLogo === "") {
      toast.error("School Logo is required");
    } else {
      try {
        setIsLoading(true);
        const response = await updateSchool(allValues);
        if (response?.success === true) {
          setSpocStatus("IN REVIEW");
          setReapply(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
    setIsLoading(false);
  };

  // Upload Images
  const uploadConsent = async (event) => {
    const file = event.target.files[0];
    const fileSize = file.size;
    const maxSize = 1024 * 1024;
    if (fileSize > maxSize) {
      toast.error("File size should be less than 1 MB.");
      event.target.value = "";
      event.target.files = [];
      return;
    }

    if (file.type === "application/pdf") {
      if (file) {
        dispatch(setGlobalLoading(true));
        try {
          const formData = new FormData();
          formData.append("file", file);
          const response = await uploadFile(formData);
          if (response?.success === true) {
            setAllValues((prevValues) => {
              return { ...prevValues, consentLetter: response?.URL };
            });
          } else {
            toast.error("File Uploading Error try uploading another file.");
          }
        } catch (error) {
          console.error(`Error uploading file: ${error}`);
        }
      }
    } else {
      toast.error("Consent Letter must be a PDF");
    }
    dispatch(setGlobalLoading(false));
  };
  const uploadSchoolLogo = async (event) => {
    const file = event.target.files[0];
    const fileSize = file.size;
    const maxSize = 1024 * 1024;
    if (fileSize > maxSize) {
      toast.error("File size should be less than 1 MB.");
      event.target.value = "";
      event.target.files = [];
      return;
    }

    if (file.type.startsWith("image/")) {
      if (file) {
        dispatch(setGlobalLoading(true));
        try {
          const formData = new FormData();
          formData.append("file", file);
          const response = await uploadFile(formData);
          if (response?.success === true) {
            setAllValues((prevValues) => {
              return { ...prevValues, schoolLogo: response?.URL };
            });
          } else {
            toast.error("File Uploading Error try uploading another file.");
          }
        } catch (error) {
          console.error(`Error uploading file: ${error}`);
        }
      }
    } else {
      toast.error("School Logo must be an image");
    }
    dispatch(setGlobalLoading(false));
  };

  const [showModal, setShowModal] = useState(true); // Set to true to open the modal by default
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Header page="spoc-registration" />

      <img
        src="https://saxenaprachi.github.io/publicAssets/images/Rocket.png"
        className={style.rocketRight}
      />
      <div className={style.schoolRegistrationDiv}>
        {/* Right Side Content ===================================>*/}
        {mainLoading ? (
          <>Loading...</>
        ) : (
          <>
            {spocStatus === "NOT VERIFIED" || reapply === true ? (
              <>
                <div>
                  {showModal && (
                    <div className={style.modal}>
                      <div className={style.modal_content}>
                        <div className={style.headerOfModal}>
                          <h4>Platform Guide - SIH Junior</h4>
                          <span className={style.close} onClick={closeModal}>
                            &times;
                          </span>
                        </div>
                        <div>
                          <iframe
                            src="https://app.tango.us/app/embed/0912df40-d6d0-447d-a180-049de1c500ab?skipCover=false&defaultListView=false&skipBranding=true"
                            sandbox="allow-scripts allow-top-navigation-by-user-activation allow-popups allow-same-origin"
                            security="restricted"
                            title="SIH Hackathon - Registration and Team Creation Process"
                            width="100%"
                            referrerpolicy="strict-origin-when-cross-origin"
                            frameborder="0"
                            webkitallowfullscreen="webkitallowfullscreen"
                            mozallowfullscreen="mozallowfullscreen"
                            allowfullscreen="allowfullscreen"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* Left Side Content ===================================>*/}
                <div className={style.mobileHeading}>
                  <h1>School Registration and SPOC Verification</h1>
                  <p>
                    Please enter your school details and be the part of our
                    community.
                  </p>
                  <div className="redStarFields my-05">
                    * Indicates required fields
                  </div>
                </div>
                <div class={style.links}>
                  <div className={style.rightSideNotice}>
                    <h5 className={style.redColor}>Important</h5>
                    <p>
                      <span className={style.redColor}>New Users: </span>Need to
                      enter all the data before uploading consent letter and
                      submitting.
                    </p>
                    <p>
                      <span className={style.redColor}>File Uploads: </span>
                      Kindly make sure the size of School Logo and SPOC Consent
                      Letter is <b>less than 1 MB</b>
                    </p>
                    <p style={{ marginBottom: "1.1rem" }}>
                      <span className={style.redColor}>Disclaimer: </span>The
                      college and school SPOC should be responsible to check
                      each and every data before submitting. Do check the mobile
                      number and email ID as they will be used for all future
                      communication and verification.
                    </p>
                    <a
                      href="https://saxenaprachi.github.io/publicAssets/consentLetter/spocConsent.doc"
                      className={style.linkColor}
                      download
                    >
                      Click Here for SPOC Consent letter Format.
                    </a>
                  </div>
                </div>
                <div class={style.social_media}>
                  <div className={style.desktopHeading}>
                    <h1>School Registration and SPOC Verification</h1>
                    <p>
                      Please enter your school details and be the part of our
                      community.
                    </p>
                    <div className="redStarFields my-05">
                      * Indicates required fields
                    </div>
                  </div>
                  <form id={style.schoolRegistrationForm}>
                    <div className={`${style.textOnInput} ${style.mb2}`}>
                      <label for="">
                        Education Board <span className="textRed">*</span>
                      </label>
                      {/* <input className={style.form_control} type="text" /> */}
                      <select
                        onChange={(e) => {
                          if (e.target.value === "CBSE") {
                            setStateBoard(false);
                            setOthersBoard(false);

                            setAllValues((prevValues) => {
                              return { ...prevValues, schoolBoardType: "CBSE" };
                            });
                            setAllValues((prevValues) => {
                              return { ...prevValues, schoolBoardName: "CBSE" };
                            });
                          } else if (e.target.value === "ICSE") {
                            setStateBoard(false);
                            setOthersBoard(false);

                            setAllValues((prevValues) => {
                              return { ...prevValues, schoolBoardType: "ICSE" };
                            });
                            setAllValues((prevValues) => {
                              return { ...prevValues, schoolBoardName: "ICSE" };
                            });
                          } else if (e.target.value === "NIOS") {
                            setStateBoard(false);
                            setOthersBoard(false);

                            setAllValues((prevValues) => {
                              return { ...prevValues, schoolBoardType: "NIOS" };
                            });
                            setAllValues((prevValues) => {
                              return { ...prevValues, schoolBoardName: "NIOS" };
                            });
                          } else if (e.target.value === "State Board") {
                            setStateBoard(true);
                            setOthersBoard(false);
                            setAllValues((prevValues) => {
                              return {
                                ...prevValues,
                                schoolBoardType: "State Board",
                              };
                            });
                            setAllValues((prevValues) => {
                              return { ...prevValues, schoolBoardName: "" };
                            });
                          } else if (e.target.value === "Others") {
                            setStateBoard(false);
                            setOthersBoard(true);
                            setAllValues((prevValues) => {
                              return {
                                ...prevValues,
                                schoolBoardType: "Others",
                              };
                            });
                            setAllValues((prevValues) => {
                              return { ...prevValues, schoolBoardName: "" };
                            });
                          }
                        }}
                        className={style.form_control}
                        name="schoolBoardType"
                      >
                        <option value="">-- Select an Option ---</option>
                        <option>CBSE</option>
                        <option>ICSE</option>
                        <option>NIOS</option>
                        <option>State Board</option>
                        <option>Others</option>
                      </select>
                    </div>

                    {isStateBoard ? (
                      <div className={`${style.textOnInput} ${style.mb2}`}>
                        <label for="state_board">
                          State Board <span className="textRed">*</span>
                        </label>
                        <select
                          className={style.form_control}
                          id="state_board"
                          name="schoolBoardName"
                          onChange={changeHandler}
                          defaultValue={allValues.schoolBoardName}
                        >
                          <option value="">State Board</option>
                          <option value="Board of intermediate education (Andhra Pradesh)">
                            Board of intermediate education (Andhra Pradesh)
                          </option>
                          <option value="Board of secondary education (Andhra Pradesh)">
                            Board of secondary education (Andhra Pradesh)
                          </option>
                          <option value="A.P. Open School Society Govt. Of Andhra Pradesh">
                            A.P. Open School Society Govt. Of Andhra Pradesh
                          </option>
                          <option value="Assam Higher Secondary Education Council">
                            Assam Higher Secondary Education Council
                          </option>
                          <option value="Board of Secondary Education, Assam">
                            Board of Secondary Education, Assam
                          </option>
                          <option value="Assam Sanskrit Board">
                            Assam Sanskrit Board
                          </option>
                          <option value="State Madrassa Education Board, Assam">
                            State Madrassa Education Board, Assam
                          </option>
                          <option value="Bihar School Examination Board">
                            Bihar School Examination Board
                          </option>
                          <option value="Bihar Board Of Open Schooling &amp; Examination">
                            Bihar Board Of Open Schooling &amp; Examination
                          </option>
                          <option value="Bihar State Madrasa Education Board">
                            Bihar State Madrasa Education Board
                          </option>
                          <option value="Bihar Sanskrit Shiksha Board">
                            Bihar Sanskrit Shiksha Board
                          </option>
                          <option value="Chhatisgarh Board Of Secondary Education">
                            Chhatisgarh Board Of Secondary Education
                          </option>
                          <option value="Chhatisgarh State Open School">
                            Chhatisgarh State Open School
                          </option>
                          <option value="Chhattisgarh Sanskrit Board, Raipur">
                            Chhattisgarh Sanskrit Board, Raipur
                          </option>
                          <option value="Chhattisgarh Madrasa Board">
                            Chhattisgarh Madrasa Board
                          </option>
                          <option value="Goa Board Of Secondary And Higher Secondary Education">
                            Goa Board Of Secondary And Higher Secondary
                            Education
                          </option>
                          <option value="Gujarat Secondary And Higher Secondary Education Board">
                            Gujarat Secondary And Higher Secondary Education
                            Board
                          </option>
                          <option value="Haryana Board of School Education">
                            Haryana Board of School Education
                          </option>
                          <option value="Himachal Pradesh Board of School Education">
                            Himachal Pradesh Board of School Education
                          </option>
                          <option value="Jammu and Kashmir State Board of School Education">
                            Jammu and Kashmir State Board of School Education
                          </option>
                          <option value="Jharkhand Academic Council,Ranchi">
                            Jharkhand Academic Council,Ranchi
                          </option>
                          <option value="Govt. Of Karnataka Dept. Of Pre-University Education">
                            Govt. Of Karnataka Dept. Of Pre-University Education
                          </option>
                          <option value="Karnataka Secondary Education, Examination Board">
                            Karnataka Secondary Education, Examination Board
                          </option>
                          <option value="Kerala Board Of Public Examination">
                            Kerala Board Of Public Examination
                          </option>
                          <option value="Kerala Board Of Higher Secondary Education">
                            Kerala Board Of Higher Secondary Education
                          </option>
                          <option value="Board of Secondary Education,  Madhya Pradesh">
                            Board of Secondary Education, Madhya Pradesh
                          </option>
                          <option value="M. P. State Open School Education Board">
                            M. P. State Open School Education Board
                          </option>
                          <option value="Maharishi Patanjali Sanskrit Sansthan">
                            Maharishi Patanjali Sanskrit Sansthan
                          </option>
                          <option value="Maharashtra State Board Of Secondary And Higher Secondary Education">
                            Maharashtra State Board Of Secondary And Higher
                            Secondary Education
                          </option>
                          <option value="Board of Secondary Education, Manipur">
                            Board of Secondary Education, Manipur
                          </option>
                          <option value="Council of Higher Secondary Education, Manipur">
                            Council of Higher Secondary Education, Manipur
                          </option>
                          <option value="Meghalaya Board Of School Education">
                            Meghalaya Board Of School Education
                          </option>
                          <option value="Mizoram Board of School Education">
                            Mizoram Board of School Education
                          </option>
                          <option value="Nagaland Board of School Education">
                            Nagaland Board of School Education
                          </option>
                          <option value="Council of Higher Secondary Education, Odisha">
                            Council of Higher Secondary Education, Odisha
                          </option>
                          <option value="Board of Secondary Education, Odisha">
                            Board of Secondary Education, Odisha
                          </option>
                          <option value="Punjab School Education Board">
                            Punjab School Education Board
                          </option>
                          <option value="Board of Secondary Education Rajasthan">
                            Board of Secondary Education Rajasthan
                          </option>
                          <option value="Rajasthan State Open School, Jaipur">
                            Rajasthan State Open School, Jaipur
                          </option>
                          <option value="State Board Of School Examinations(Sec.), Tamil Nadu">
                            State Board Of School Examinations(Sec.), Tamil Nadu
                          </option>
                          <option value="State Board Of Higher Secondary Examinations, Tamil Nadu">
                            State Board Of Higher Secondary Examinations, Tamil
                            Nadu
                          </option>
                          <option value="Telangana State Board Of Intermediate Education, Nampally Hyderabad-500001">
                            Telangana State Board Of Intermediate Education,
                            Nampally Hyderabad-500001
                          </option>
                          <option value="Telangana State Board Of Secondary Education, Chapel Road, Nampally, Hyderabad-500001">
                            Telangana State Board Of Secondary Education, Chapel
                            Road, Nampally, Hyderabad-500001
                          </option>
                          <option value="Telangana Open School Society">
                            Telangana Open School Society
                          </option>
                          <option value="Tripura Board Of Secondary Education">
                            Tripura Board Of Secondary Education
                          </option>
                          <option value="Board of School Education Uttarakhand">
                            Board of School Education Uttarakhand
                          </option>
                          <option value="U.P. Board Of High School &amp; Intermediate Education">
                            U.P. Board Of High School &amp; Intermediate
                            Education
                          </option>
                          <option value="U.P. Board of Sec. Sanskrit Education Council">
                            U.P. Board of Sec. Sanskrit Education Council
                          </option>
                          <option value="Board of Secondary Education Kant Shahjahanpur U.P.">
                            Board of Secondary Education Kant Shahjahanpur U.P.
                          </option>
                          <option value="West Bengal Board Of Secondary Education">
                            West Bengal Board Of Secondary Education
                          </option>
                          <option value="West Bengal Council Of Higher Secondary Education">
                            West Bengal Council Of Higher Secondary Education
                          </option>
                          <option value="West Bengal Board Of Primary Education">
                            West Bengal Board Of Primary Education
                          </option>
                          <option value="West Bengal Board Of Madrasah Education">
                            West Bengal Board Of Madrasah Education
                          </option>
                          <option value="The West Bengal Council Of Rabindra Open Schooling">
                            The West Bengal Council Of Rabindra Open Schooling
                          </option>
                          <option value="Dadra &amp; Nagar Gujarat Secondary &amp; Higher Secondary Examination Board , Vadodara">
                            Dadra &amp; Nagar Gujarat Secondary &amp; Higher
                            Secondary Examination Board , Vadodara
                          </option>
                          <option value="Higher Secondary Certificate Examination Board, Ghadinagar">
                            Higher Secondary Certificate Examination Board,
                            Ghadinagar
                          </option>
                          <option value="Gujarat Secondary Education Board (GSEB) Vadodara, Gujarat">
                            Gujarat Secondary Education Board (GSEB) Vadodara,
                            Gujarat
                          </option>
                          <option value="Gujarat Secondary and Higher Secondary Education Board (GSHEB), Gandhinagar, Gujarat">
                            Gujarat Secondary and Higher Secondary Education
                            Board (GSHEB), Gandhinagar, Gujarat
                          </option>
                          <option value="Secondary School Leaving Certificate Examination Board, Kerala">
                            Secondary School Leaving Certificate Examination
                            Board, Kerala
                          </option>
                          <option value="Board of Higher Secondary Examination, Kerala">
                            Board of Higher Secondary Examination, Kerala
                          </option>
                          <option value="Board of Secondary Education">
                            Board of Secondary Education
                          </option>
                          <option value="Tamilnadu Board of public Examination">
                            Tamilnadu Board of public Examination
                          </option>
                          <option value="Kerala Board of Secondary Education">
                            Kerala Board of Secondary Education
                          </option>
                          <option value="Andhra Pradesh Board of Matriculation Examination">
                            Andhra Pradesh Board of Matriculation Examination
                          </option>
                          <option value="Board of Hr. Sec. Education, Tamilnadu">
                            Board of Hr. Sec. Education, Tamilnadu
                          </option>
                          <option value="Board of Hr. Sec. Examinations, Kerala">
                            Board of Hr. Sec. Examinations, Kerala
                          </option>
                          <option value="Board of Intermediate Education, Andhra Pradesh">
                            Board of Intermediate Education, Andhra Pradesh
                          </option>
                          <option value="Department of Education, Puducherry">
                            Department of Education, Puducherry
                          </option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    ) : (
                      ""
                    )}

                    {isOthersBoard ? (
                      <div className={`${style.textOnInput} ${style.mb2}`}>
                        <label for="schoolBoardName">
                          Education Board Others{" "}
                          <span className="textRed">*</span>
                        </label>
                        <input
                          className={style.form_control}
                          name="schoolBoardName"
                          type="text"
                          placeholder="Enter Others Education Board"
                          onChange={changeHandler}
                          defaultValue={allValues.schoolBoardName}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    <div className={`${style.textOnInput} ${style.mb2}`}>
                      <label for="">
                        School Type <span className="textRed">*</span>
                      </label>
                      {/* <input className={style.form_control} type="text" /> */}
                      <select
                        onChange={(e) => {
                          if (e.target.value === "Central Govt School") {
                            setCentralGov(true);
                            setAllValues((prevValues) => {
                              return {
                                ...prevValues,
                                schoolType: e.target.value,
                              };
                            });
                          } else {
                            setCentralGov(false);
                            setAllValues((prevValues) => {
                              return {
                                ...prevValues,
                                schoolType: e.target.value,
                              };
                            });
                            setAllValues((prevValues) => {
                              return {
                                ...prevValues,
                                schoolGovType: "",
                              };
                            });
                          }
                        }}
                        className={style.form_control}
                        name="schoolType"
                      >
                        <option value="">-- Select an Option ---</option>
                        <option>Central Govt School</option>
                        <option>State Govt School</option>
                        <option>Local Bodies Govt School</option>
                        <option>Private</option>
                      </select>
                    </div>

                    {centralGov && (
                      <>
                        <div className={`${style.textOnInput} ${style.mb2}`}>
                          <label for="">
                            Central Govt School Type{" "}
                            <span className="textRed">*</span>
                          </label>
                          <select
                            onChange={changeHandler}
                            className={style.form_control}
                            name="schoolGovType"
                          >
                            <option value="">-- Select an Option ---</option>
                            <option>Kendriya Vidyalaya</option>
                            <option>Jawahar Navodaya Vidyalaya</option>
                            <option>
                              National Institute of Open Schooling
                            </option>
                            <option>PM SHRI School</option>
                          </select>
                        </div>
                      </>
                    )}

                    <div className={`${style.textOnInput} ${style.mb2}`}>
                      <label for="">
                        School Registration Code{" "}
                        <span className="textRed">*</span>
                      </label>
                      <input
                        className={style.form_control}
                        name="schoolCode"
                        type="text"
                        placeholder="Enter School Registration Code"
                        onChange={changeHandler}
                        defaultValue={allValues.schoolCode}
                      />
                      <small className="my-05">
                        Know your school code
                        <Link className={`${style.schoolCodeLegend} clickable`} to="https://src.udiseplus.gov.in/" target="_blank">here</Link>
                      </small>
                    </div>
                    <div className={`${style.textOnInput} ${style.mb2}`}>
                      <label for="">
                        School Name <span className="textRed">*</span>{" "}
                      </label>
                      <input
                        className={style.form_control}
                        name="schoolName"
                        type="text"
                        placeholder="Enter School Name"
                        onChange={changeHandler}
                        value={allValues.schoolName}
                      />
                    </div>
                    <div className={style.regionBox}>
                      {/* <div className={`${style.textOnInput} ${style.mb2}`}>
                <label htmlFor="country">Country</label>
                <select
                  id="country"
                  value=""
                  className={style.form_control}
                  name="state"
                >
                  <option value="">Select</option>
                  {Country.map((city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
              </div> */}
                      {/* <div className={`${style.textOnInput} ${style.mb2}`}>
                    <label htmlFor="country">
                      Country <span className="textRed">*</span>
                    </label>
                    <select
                      className={style.form_control}
                      id="country"
                      value={selectedCountry}
                      onChange={handleCountryChange}
                    >
                      <option value="">Select a country</option>
                      {countries}
                    </select>
                  </div> */}
                      <div className={`${style.textOnInput} ${style.mb2}`}>
                        <label htmlFor="state">
                          State <span className="textRed">*</span>
                        </label>
                        <select
                          className={style.form_control}
                          id="state"
                          value={selectedState}
                          onChange={handleStateChange}
                        >
                          <option value="">Select</option>
                          {states}
                        </select>
                      </div>
                      <div className={`${style.textOnInput} ${style.mb2}`}>
                        <label htmlFor="city">
                          City <span className="textRed">*</span>
                        </label>
                        <select
                          className={style.form_control}
                          id="city"
                          value={selectedCity}
                          onChange={handleCityChange}
                        >
                          <option value="">Select</option>
                          {cities}
                        </select>
                      </div>
                    </div>

                    <div className={`${style.textOnInput} ${style.mb2}`}>
                      <label for="">
                        School Address <span className="textRed">*</span>
                      </label>
                      <input
                        className={style.form_control}
                        name="address"
                        type="text"
                        placeholder="Enter School Address"
                        onChange={changeHandler}
                        defaultValue={allValues.address}
                      />
                    </div>

                    <div className={`${style.textOnInput} ${style.mb2}`}>
                      <label for="">
                        School Pincode <span className="textRed">*</span>
                      </label>
                      <input
                        className={style.form_control}
                        name="pincode"
                        placeholder="Enter School Pincode"
                        onChange={changeHandler}
                        defaultValue={allValues.pincode}
                        type="number"
                        maxlength="6"
                      />
                    </div>

                    <div className={`${style.textOnInput} ${style.mb2}`}>
                      <label for="">
                        School Faculty First Name{" "}
                        <span className="textRed">*</span>
                      </label>
                      <input
                        className={style.form_control}
                        name="facultyFirstName"
                        type="text"
                        placeholder="Enter School Faculty First Name"
                        onChange={changeHandler}
                        maxLength={100}
                      />
                      <p className={`${style.redColor} ${style.captionPara}`}>
                        Max 100 Character Allowed
                      </p>
                    </div>
                    <div className={`${style.textOnInput} ${style.mb2}`}>
                      <label for="">
                        School Faculty Last Name{" "}
                        <span className="textRed">*</span>
                      </label>
                      <input
                        className={style.form_control}
                        name="facultyLastName"
                        type="text"
                        placeholder="Enter School Faculty Last Name"
                        onChange={changeHandler}
                        maxLength={100}
                      />
                      <p className={`${style.redColor} ${style.captionPara}`}>
                        Max 100 Character Allowed
                      </p>
                    </div>
                    <div className={`${style.textOnInput} ${style.mb2}`}>
                      <label for="">
                        School Faculty Designation{" "}
                        <span className="textRed">*</span>
                      </label>
                      <input
                        className={style.form_control}
                        name="facultyDesignation"
                        type="text"
                        placeholder="Enter School Faculty Designation"
                        onChange={changeHandler}
                        maxLength={100}
                      />
                      <p className={`${style.redColor} ${style.captionPara}`}>
                        Max 100 Character Allowed
                      </p>
                    </div>
                    <div className={`${style.textOnInput} ${style.mb2}`}>
                      <label for="email">
                        Email <span className="textRed">*</span>
                      </label>
                      <input
                        className={style.form_control}
                        // name="email"
                        type="text"
                        placeholder="Enter Email Address"
                        value={spocEmail}
                        disabled
                      />
                      {/* <p className={`${style.redColor} ${style.captionPara}`}>
                        Max 100 Character Allowed
                      </p> */}
                    </div>
                    <div className={`${style.textOnInput} ${style.mb2}`}>
                      <label for="facultyPhone">
                        Mobile Number <span className="textRed">*</span>
                      </label>
                      <input
                        className={style.form_control}
                        name="facultyPhone"
                        type="number"
                        placeholder="Enter 10 digit Mobile Number"
                        onChange={changeHandler}
                      />
                    </div>
                    <div className={`${style.textOnInput} ${style.mb2}`}>
                      <label for="">
                        Upload Consent Letter <span className="textRed">*</span>
                      </label>
                      <input
                        className={style.form_control}
                        name="consent_letter"
                        type="file"
                        accept=".pdf"
                        style={{ paddingTop: "1rem", appearance: "none" }}
                        onChange={uploadConsent}
                      />
                      <small
                        className={`${style.redColor} ${style.captionPara}`}
                      >
                        Only pdf file allowed (less than 1 MB)
                      </small>
                    </div>
                    <div className={`${style.textOnInput} ${style.mb2}`}>
                      <label for="">
                        Upload School Logo <span className="textRed">*</span>
                      </label>
                      <input
                        className={style.form_control}
                        name="school_logo"
                        type="file"
                        accept="image/*"
                        style={{ paddingTop: "1rem", appearance: "none" }}
                        onChange={uploadSchoolLogo}
                      />
                      <small
                        className={`${style.redColor} ${style.captionPara}`}
                      >
                        Only image file allowed (less than 1 MB)
                      </small>
                    </div>

                    <div style={{ textAlign: "center" }}>
                      <>
                        {isLoading ? (
                          <button className={`${style.btn} ${style.greenBtn}`}>
                            Loading...
                          </button>
                        ) : (
                          <button
                            className={`${style.btn} ${style.greenBtn}`}
                            onClick={Submit}
                          >
                            Register Now
                          </button>
                        )}
                      </>
                    </div>
                  </form>
                </div>{" "}
              </>
            ) : (
              <>
                {spocStatus === "REJECTED" ? (
                  <>
                    <div className={style.infoConatiner}>
                      <div>
                        <img src="https://saxenaprachi.github.io/publicAssets/images/rejected.png" />
                      </div>
                      <div>
                        Sorry, your application to register as a SPOC is denied
                        due to mismatch in the data filled and consent letter
                        uploaded. You are requested to register again with the
                        correct data. For more info, contact us at
                        <br />
                        <span className={style.contactEmail}>
                          {/* rc3.mic@aicte-india.org; sih@aicte-india.org */}
                          schoolinnovation@aicte-india.org
                        </span>
                        <br />
                      </div>
                      <div
                        className={`btn orangeBtn`}
                        onClick={() => {
                          setReapply(true);
                        }}
                      >
                        REAPPLY
                      </div>
                      {/* <Link to="/role" className={style.goBack}>
                        Go Back
                      </Link> */}
                    </div>
                  </>
                ) : (
                  <>
                    <div className={style.infoConatiner}>
                      <div>
                        <img src="https://saxenaprachi.github.io/publicAssets/images/sandTimer.png" />
                      </div>
                      <div>
                        Your application is currently under review, <br />
                        Kindly check back later.
                      </div>
                      <p className={style.noteText}>
                        Note: You will receive an email upon approval of your
                        request.
                      </p>
                      {/* <Link to="/role" className={style.goBack}>
                        Go Back
                      </Link> */}
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
        <div class={style.clearfix}></div>
        <div className={style.poweredByH2sLogo}>
          Powered By :
          <Link to="https://hack2skill.com/" target="_blank">
            <img
              width={90}
              src="https://saxenaprachi.github.io/publicAssets/images/H2S_Gradient_Logo.png"
            />
          </Link>
        </div>
      </div>
    </>
  );
};

export default SchoolRegistration;
