import { h, Component } from "preact";

import style from "../noticeBoard/style.css";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";

import { useEffect, useState } from "preact/hooks";
import { postTicker } from "../../../modules/sih-admin/actions";

import toast from "react-hot-toast";
import { getTickerText } from "../../../modules/public/actions";
import Switch from "../../../components/switch";

const TickerManagement = () => {
  // Fetching SPOC Requests
  const [isLoading, setisLoading] = useState(false);

  const fetchData = async () => {
    try {
      setisLoading(true);
      const response = await getTickerText();
      // console.log("ticker text", response);
      setAllValues({ ...response?.data });
      setisLoading(false);
    } catch (e) {
      // console.log(e);
      setisLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateDashboard = async () => {
    try {
      setisLoading(true);
      if (allValues.text === "") {
        toast.error("Ticker Text is required");
        setisLoading(false);
      } else if (allValues.theme == "") {
        toast.error("Please Select Theme");
        setisLoading(false);
      } else {
        const response = await postTicker({
          noticeText: allValues.text,
          noticeTheme: allValues.theme,
          noticeVisibility: allValues.visibility,
        });
        // console.log(response);
        if (response?.success === true) {
          // console.log(response);
          toast.success("Landing Page Ticker Updated.");
          fetchData();
        } else {
          fetchData();
        }
      }
      setisLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const cancelDashboard = async () => {
    fetchData();
  };

  const [allValues, setAllValues] = useState({
    text: "",
    theme: "",
    visibility: false,
  });

  const changeHandler = (e) => {
    if (e.target.name === "visibility") {
      setAllValues((prevValues) => {
        return { ...prevValues, [e.target.name]: e.target.checked };
      });
    } else {
      setAllValues((prevValues) => {
        return { ...prevValues, [e.target.name]: e.target.value };
      });
    }
  };

  return (
    <>
      <Header />
      <div className={style.main}>
        <div className={style.sideBarConatiner}>
          <Sidebar role="sih-admin" activeTab="ticker" />
        </div>
        <div className={style.mainContent}>
          <h3>Landing Page Ticker</h3>
          {/* Landing Page Ticker */}
          <div className={style.createNoticeForm}>
            <h4>Create Ticker</h4>
            <form className={style.createForm}>
              {/* Visibility */}
              <div className={style.visibilityContainer}>
                <Switch
                  isOn={allValues.visibility}
                  handleToggle={changeHandler}
                  name="visibility"
                  colorOne="#52af52"
                  colorTwo="gray"
                />
                <label>
                  Ticker Visibility <span className="textRed">*</span>
                </label>
              </div>
              {/* Ticker */}
              <div className={style.inputGroup}>
                <label>
                  Ticker Text
                  {/* <span className="textRed">*</span> */}
                </label>
                <input
                  name="text"
                  id="text"
                  type="text"
                  onInput={changeHandler}
                  placeholder="Enter your notice"
                  value={allValues.text}
                />
              </div>

              <div className={style.radioGroup}>
                <label for="themeDanger" className={style.dangerTheme}>
                  <input
                    type="radio"
                    name="theme"
                    id="themeDanger"
                    value="DANGER"
                    onChange={changeHandler}
                    checked={allValues.theme == "DANGER"}
                  />
                  &nbsp; DANGER
                </label>
                <br />
                <label for="themeSuccess" className={style.successTheme}>
                  <input
                    type="radio"
                    name="theme"
                    id="themeSuccess"
                    value="SUCCESS"
                    onChange={changeHandler}
                    checked={allValues.theme == "SUCCESS"}
                  />
                  &nbsp; SUCCESS
                </label>
                <br />
                <label for="themeInfo" className={style.infoTheme}>
                  <input
                    type="radio"
                    name="theme"
                    id="themeInfo"
                    value="INFO"
                    onChange={changeHandler}
                    checked={allValues.theme == "INFO"}
                  />
                  &nbsp; INFO
                </label>
                <br />
                <label for="themeWarning" className={style.warningTheme}>
                  <input
                    type="radio"
                    name="theme"
                    id="themeWarning"
                    value="WARNING"
                    onChange={changeHandler}
                    checked={allValues.theme == "WARNING"}
                  />
                  &nbsp; WARNING
                </label>
              </div>

              <div className={style.buttonContainer}>
                {!isLoading ? (
                  <button
                    className={style.createButton}
                    onClick={updateDashboard}
                    type="button"
                  >
                    Update Ticker
                  </button>
                ) : (
                  <button className={style.createButton} type="button">
                    Loading...
                  </button>
                )}
                <div
                  className={style.cancelButton}
                  onClick={cancelDashboard}
                  type="button"
                >
                  Cancel
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TickerManagement;
