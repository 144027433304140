import { h, Component } from "preact";
import style from "./style.css";
import { Icon } from "@iconify/react";

const ActivityLog = ({ activity, key }) => {
  return (
    <>
      <div key={key} className={style.activityContainer}>
        <li className={style.activityTime}>
          {new Date(activity.timestamp).toLocaleString()}
        </li>
        <li className={style.activityAction}>{activity.action}</li>
      </div>
    </>
  );
};

export default ActivityLog;
