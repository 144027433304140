import { h, Component } from "preact";

const MailHTML = ({ body }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `<!DOCTYPE html>
            <html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="en">
            
            <head>
                <title></title>
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
                <style>
                    * {
                        box-sizing: border-box;
                    }
            
                    body {
                        margin: 0;
                        padding: 0;
                    }
            
                    a[x-apple-data-detectors] {
                        color: inherit !important;
                        text-decoration: inherit !important;
                    }
            
                    #MessageViewBody a {
                        color: inherit;
                        text-decoration: none;
                    }
            
                    p {
                        line-height: inherit
                    }
            
                    .desktop_hide,
                    .desktop_hide table {
                        mso-hide: all;
                        display: none;
                        max-height: 0px;
                        overflow: hidden;
                    }
            
                    .image_block img+div {
                        display: none;
                    }
            
                    @media (max-width:525px) {
            
                        .desktop_hide table.icons-inner,
                        .social_block.desktop_hide .social-table {
                            display: inline-block !important;
                        }
            
                        .icons-inner {
                            text-align: center;
                        }
            
                        .icons-inner td {
                            margin: 0 auto;
                        }
            
                        .mobile_hide {
                            display: none;
                        }
            
                        .row-content {
                            width: 100% !important;
                        }
            
                        .stack .column {
                            width: 100%;
                            display: block;
                        }
            
                        .mobile_hide {
                            min-height: 0;
                            max-height: 0;
                            max-width: 0;
                            overflow: hidden;
                            font-size: 0px;
                        }
            
                        .desktop_hide,
                        .desktop_hide table {
                            display: table !important;
                            max-height: none !important;
                        }
            
                        .row-1 .column-1 .block-10.paragraph_block td.pad,
                        .row-1 .column-1 .block-16.paragraph_block td.pad,
                        .row-1 .column-1 .block-3.paragraph_block td.pad,
                        .row-1 .column-1 .block-4.paragraph_block td.pad,
                        .row-1 .column-1 .block-5.paragraph_block td.pad,
                        .row-1 .column-1 .block-6.paragraph_block td.pad,
                        .row-1 .column-1 .block-7.paragraph_block td.pad,
                        .row-1 .column-1 .block-8.paragraph_block td.pad {
                            padding: 10px 25px !important;
                        }
            
                        .row-1 .column-1 .block-16.paragraph_block td.pad>div {
                            font-size: 12px !important;
                        }
            
                        .row-1 .column-1 .block-12.image_block td.pad {
                            padding: 0 20px 0 25px !important;
                        }
                    }
                </style>
            </head>
            
            <body style="background-color: #fff; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
                <table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation"
                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #fff;">
                    <tbody>
                        <tr>
                            <td>
                                <table class="row row-1" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
                                    role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0"
                                                    cellspacing="0" role="presentation"
                                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000; width: 505px; margin: 0 auto;"
                                                    width="505">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%"
                                                                style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <table class="image_block block-1" width="100%" border="0"
                                                                    cellpadding="20" cellspacing="0" role="presentation"
                                                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad">
                                                                            <div class="alignment" align="center"
                                                                                style="line-height:10px"><img
                                                                                    src="https://798afc2be1.imgdist.com/public/users/Integrators/BeeProAgency/1045403_1030556/SIH2023-logo%20%282%29.png"
                                                                                    style="display: block; height: auto; border: 0; max-width: 253px; width: 100%;"
                                                                                    width="253"></div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-2"
                                                                    style="height:15px;line-height:15px;font-size:1px;">&#8202;
                                                                </div>
                                                                <table class="paragraph_block block-3" width="100%" border="0"
                                                                    cellpadding="10" cellspacing="0" role="presentation"
                                                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                                                                    <tr>
                                                                        <td class="pad">
                                                                            ${body}
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-9"
                                                                    style="height:10px;line-height:10px;font-size:1px;">&#8202;
                                                                </div>
                                                                <table class="paragraph_block block-10" width="100%" border="0"
                                                                    cellpadding="10" cellspacing="0" role="presentation"
                                                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                                                                    <tr>
                                                                        <td class="pad">
                                                                            <div
                                                                                style="color:#4d4d4d;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:16px;font-weight:400;letter-spacing:0px;line-height:120%;text-align:left;mso-line-height-alt:19.2px;">
                                                                                <p style="margin: 0; margin-bottom: 16px;">Best
                                                                                    Regards,</p>
                                                                                <p style="margin: 0;">Team SIH Junior</p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-11"
                                                                    style="height:20px;line-height:20px;font-size:1px;">&#8202;
                                                                </div>
                                                                <table class="image_block block-12" width="100%" border="0"
                                                                    cellpadding="0" cellspacing="0" role="presentation"
                                                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad"
                                                                            style="width:100%;padding-right:0px;padding-left:0px;">
                                                                            <div class="alignment" align="right"
                                                                                style="line-height:10px"><img
                                                                                    src="https://798afc2be1.imgdist.com/public/users/Integrators/BeeProAgency/1045403_1030556/powered%20by.png"
                                                                                    style="display: block; height: auto; border: 0; max-width: 126px; width: 100%;"
                                                                                    width="126"></div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <table class="divider_block block-13" width="100%" border="0"
                                                                    cellpadding="20" cellspacing="0" role="presentation"
                                                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad">
                                                                            <div class="alignment" align="center">
                                                                                <table border="0" cellpadding="0" cellspacing="0"
                                                                                    role="presentation" width="100%"
                                                                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                                    <tr>
                                                                                        <td class="divider_inner"
                                                                                            style="font-size: 1px; line-height: 1px; border-top: 1px solid #BBBBBB;">
                                                                                            <span>&#8202;</span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <table class="social_block block-14" width="100%" border="0"
                                                                    cellpadding="10" cellspacing="0" role="presentation"
                                                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad">
                                                                            <div class="alignment" align="center">
                                                                                <table class="social-table" width="138px" border="0"
                                                                                    cellpadding="0" cellspacing="0"
                                                                                    role="presentation"
                                                                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; display: inline-block;">
                                                                                    <tr>
                                                                                        <td style="padding:0 7px 0 7px;"><a
                                                                                                href="https://www.facebook.com/mhrdInnovation/"
                                                                                                target="_blank"><img
                                                                                                    src="https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-color/facebook@2x.png"
                                                                                                    width="32" height="32"
                                                                                                    alt="Facebook" title="facebook"
                                                                                                    style="display: block; height: auto; border: 0;"></a>
                                                                                        </td>
                                                                                        <td style="padding:0 7px 0 7px;"><a
                                                                                                href="https://twitter.com/SIH2023_MIC"
                                                                                                target="_blank"><img
                                                                                                    src="https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-color/twitter@2x.png"
                                                                                                    width="32" height="32"
                                                                                                    alt="Twitter" title="twitter"
                                                                                                    style="display: block; height: auto; border: 0;"></a>
                                                                                        </td>
                                                                                        <td style="padding:0 7px 0 7px;"><a
                                                                                                href="https://www.instagram.com/mhrd.innovationcell/"
                                                                                                target="_blank"><img
                                                                                                    src="https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-color/instagram@2x.png"
                                                                                                    width="32" height="32"
                                                                                                    alt="Instagram"
                                                                                                    title="instagram"
                                                                                                    style="display: block; height: auto; border: 0;"></a>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <table class="paragraph_block block-15" width="100%" border="0"
                                                                    cellpadding="10" cellspacing="0" role="presentation"
                                                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                                                                    <tr>
                                                                        <td class="pad">
                                                                            <div
                                                                                style="color:#101112;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:14px;font-weight:400;letter-spacing:0px;line-height:120%;text-align:center;mso-line-height-alt:16.8px;">
                                                                                <p style="margin: 0;"><strong>Official Website: <a
                                                                                            href="https://junior.sih.gov.in/"
                                                                                            target="_blank"
                                                                                            style="text-decoration: underline; color: #7747FF;"
                                                                                            rel="noopener">junior.sih.gov.in</a></strong>
                                                                                </p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <table class="paragraph_block block-16" width="100%" border="0"
                                                                    cellpadding="10" cellspacing="0" role="presentation"
                                                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                                                                    <tr>
                                                                        <td class="pad">
                                                                            <div
                                                                                style="color:#101112;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:14px;font-weight:400;letter-spacing:0px;line-height:120%;text-align:center;mso-line-height-alt:16.8px;">
                                                                                <p style="margin: 0;"><strong>Contact
                                                                                        Support:</strong> <a
                                                                                        href="mailto:schoolinnovation@aicte-india.org"
                                                                                        target="_blank" rel="noopener"
                                                                                        title="schoolinnovation@aicte-india.org"
                                                                                        style="text-decoration: underline; color: #7747FF;">schoolinnovation@aicte-india.org</a><br>
                                                                                    </a>
                                                                                </p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table><!-- End -->
            </body>
            
            </html>`,
      }}
    />
  );
};

export default MailHTML;