import { axiosPrivate } from "../../api/axios";

// Get SPOC Reqs
export async function getSpocReqs(
  page,
  statusFilter,
  recordsPerPage,
  emailSearch
) {
  try {
    const res = await axiosPrivate.get(
      `/api/v1/admin/spoc/list?page=${page}&status=${statusFilter}&records=${recordsPerPage}&email=${emailSearch}`
    );
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Get SPOC Req Data
export async function getSpocReqData(data) {
  try {
    const res = await axiosPrivate.post(`/api/v1/admin/spoc/details`, data);
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Approve SPOC
export async function approveSpocReq(data) {
  try {
    const res = await axiosPrivate.post(`/api/v1/admin/spoc/verify`, data);
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Reject SPOC
export async function rejectSpocReq(data) {
  try {
    const res = await axiosPrivate.post(`/api/v1/admin/spoc/reject`, data);
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Get SPOC Notice Board
export async function getNoticeText(data) {
  try {
    const res = await axiosPrivate.get(`/api/v1/admin/spoc/notice-board`);
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Set SPOC Notice Board
export async function postNoticeText(data) {
  try {
    const res = await axiosPrivate.post(
      `/api/v1/admin/spoc/notice-board`,
      data
    );
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}
// Set Public Notice Board
export async function postTicker(data) {
  try {
    const res = await axiosPrivate.post(
      `api/v1/admin/public/notice-board`,
      data
    );
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Get SPOC Req Stats
export async function getSPOCReqStats(data) {
  try {
    const res = await axiosPrivate.get(`/api/v1/admin/spoc/request-stats`);
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Get platform stats
export async function getPlatformStats(data) {
  try {
    const res = await axiosPrivate.get(`/api/v1/admin/spoc/stats`);
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Send Emails
export async function sendEmails(data) {
  try {
    const res = await axiosPrivate.post(`/api/v1/admin/email-service`, data);
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Get Email Count -
export async function getEmailCount(data) {
  try {
    const res = await axiosPrivate.post(
      `/api/v1/admin/email-service-count`,
      data
    );
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// get email listing
export async function getEmailLogs(page) {
  try {
    const res = await axiosPrivate.get(
      `/api/v1/admin/email-service-logs?page=${page}`
    );
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// get email details - body
export async function getEmailDetails (id) {
  try {
    const res = await axiosPrivate.get(
      `/api/v1/admin/email-service-log?activity=${id}`
    );
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}