import toast from "react-hot-toast";
import Header from "../../../components/header";
import Modal from "../../../components/modal";
import Sidebar from "../../../components/sidebar";
import style from "./style.css";
import { useState, useEffect, useRef } from "preact/hooks";
import { getEmailCount, getEmailLogs, sendEmails } from "../../../modules/sih-admin/actions";
import TinymceEditor from "../../../components/editor";
import Table from "../../../components/table";
import Pagination from "../../../components/pagination";
import { getEmailDetails } from "../../../modules/sih-admin/actions";

const EmailService = () => {
  const quillObj=useRef(null);
  
  const [selectedOption, setSelectedOption] = useState("");
  const [isTestModal, setTestModalOpen] = useState(false);
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);
  const [allValues, setAllValues] = useState({
    emailUsers: "",
    emailBody: "",
    emailSubject: "",
    emails: "",
    testEmails: "",
    emailCount: 0,
    spocTeams: "all",
    spocNominations: "all",
    spocSubmissions: "all",
  });
  const changeHandler = (e) => {
    if (e.target.name === "emailUsers") {
      setSelectedOption(e.target.value);
    }
    setAllValues((prevValues) => {
      return { ...prevValues, [e.target.name]: e.target.value };
    });
  };

  const BodyChangeHandler = (e) => {
    setAllValues((prevValues) => {
      return { ...prevValues, emailBody: e.level.content };
    });
  };
  const sendEmail = async (sendTo) => {
    let data;
    if (sendTo === "all") {
      data = allValues;
    } else if (sendTo === "test") {
      const emailreg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      let emailArray = allValues.testEmails.split(",");
      let invalidEmail = false;
      emailArray.map((email) => {
        if (!emailreg.test(email.trim())) {
          toast.error("Emails entered are invalid");
          invalidEmail = true;
          return;
        }
      });
      if (invalidEmail) {
        return;
      }
      data = {
        ...allValues,
        emails: allValues.testEmails,
        emailUsers: "Email Address",
      };
    }
    try {
      const response = await sendEmails(data);
      if (response?.success === true) {
        toast.success("Emails sent Successfully!");
        if (sendTo === "all") {
          setSelectedOption("");
          setAllValues({
            emailUsers: "",
            emailBody: "",
            emailSubject: "",
            emails: "",
            testEmails: "",
            spocTeams: "all",
            spocNominations: "all",
            spocSubmissions: "all",
          });
          setPreviewModalOpen(false);
        }
        setTestModalOpen(false);
      } else {
        toast.error(response?.message);
        setPreviewModalOpen(false);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onpenPreviewModal = async () => {
    if (allValues.emailUsers === "") {
      toast.error("Select Reciever");
    } else if (
      allValues.emailUsers === "Email Address" &&
      allValues.emails === ""
    ) {
      toast.error("Enter Emails");
    } else if (allValues.emailSubject === "") {
      toast.error("Subject is Required.");
    } else if (allValues.emailBody === "") {
      toast.error("Email Body is required");
    } else {
      if (allValues.emailUsers === "Email Address") {
        const emailreg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        let emailArray = allValues.emails.split(",");
        if (emailArray.length > 3) {
          toast.error("Only up to 3 comma seprated values allowed");
          return;
        }
        let invalidEmail = false;
        emailArray.map((email) => {
          if (!emailreg.test(email.trim())) {
            toast.error("Emails entered are invalid");
            invalidEmail = true;
            return;
          }
        });
        if (invalidEmail) {
          return;
        }
      }
      try {
        setPreviewModalOpen(true);
        const response = await getEmailCount({
          emailUsers: allValues.emailUsers,
          emails: allValues.emails,
          spocTeams: allValues.spocTeams,
          spocNominations: allValues.spocNominations,
          spocSubmissions: allValues.spocSubmissions,
        });
        setAllValues((prevValues) => {
          return { ...prevValues, emailCount: response?.count };
        });
      } catch (e) {
        console.log(e);
      }
    }
  };
  const openTestModal = () => {
    if (allValues.emailSubject === "") {
      toast.error("Subject is Required.");
    } else if (allValues.emailBody === "") {
      toast.error("Email Body is required");
    } else {
      try {
        setTestModalOpen(true);
      } catch (e) {
        console.log(e);
      }
    }
  };
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tableContent, setTableContent] = useState([]);
  const fetchData = async(page)=>{
    try{
      const response = await getEmailLogs(page);

      if(response?.success === true){
        setTotalPages(response?.pages);
        setTableContent(response?.data);
      }

    }catch(e){
      console.log(e)
    }
  }
  useEffect(()=>{
    fetchData(1);
    setCurrentPage(1);
  },[])
  const pageChangeHandler =(page)=>{
    setCurrentPage(page);
  }
  useEffect(()=>{
    fetchData(currentPage)
  },[currentPage]);

  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [reviewValues, setReviewValues] = useState({
    emailSubject:'',
    emailBody:'',emailCount:0
  });
  const previewEmail = async (id)=>{
    try {
      setIsReviewModalOpen(true);
      const response = await getEmailDetails(id);
      setReviewValues((prevValues) => {
        return { ...prevValues, emailSubject: response?.data.subject, emailBody : response?.data.body, emailCount: response?.data.sentCount };
      });
    } catch (e) {
      console.log(e)
    }
  }


  return (
    <>
      <Header />
      <div className={style.main}>
        <div className={style.sideBarConatiner}>
          <Sidebar role="sih-admin" activeTab="emailService" />
        </div>
        <div className={style.mainContent}>
          <h3>Manage Send Bulk Email </h3>

          <div className={`${style.createNoticeForm} ${style.boxShadow}`}>
            <div className={style.leftBox}>
              <h4 className={`mb-1`}>Personalized Tags</h4>
              <small>
                *These tags can be used to dynamically add data to the mail .
              </small>
              <br />
              <br />
              <div className={style.tagsContainer}>
                <div className={style.tagBox}>
                  <h5 className={``}>
                    Name: <span className={`textLightOrange `}>@name</span>
                  </h5>
                  <small>*To add 'Name' variable.</small>
                </div>
                <div className={style.tagBox}>
                  <h5 className={``}>
                    Email: <span className={`textLightOrange `}>@email</span>
                  </h5>
                  <small>*To add 'Email' variable.</small>
                </div>
                <div className={style.tagBox}>
                  <h5 className={``}>
                    School Name:{" "}
                    <span className={`textLightOrange `}>@schoolName</span>
                  </h5>
                  <small>*To add 'School Name' variable.</small>
                </div>
                <div className={style.tagBox}>
                  <h5 className={``}>
                    School Code:{" "}
                    <span className={`textLightOrange `}>@schoolCode</span>
                  </h5>
                  <small>*To add 'School Code' variable.</small>
                </div>
                <div className={style.tagBox}>
                  <h5 className={``}>
                    Team Count:{" "}
                    <span className={`textLightOrange `}>@teamCount</span>
                  </h5>
                  <small>*To add 'Team Count' variable.</small>
                </div>
                <div className={style.tagBox}>
                  <h5 className={``}>
                    Submission Count:{" "}
                    <span className={`textLightOrange `}>@submissionCount</span>
                  </h5>
                  <small>*To add 'Submission Count' variable.</small>
                </div>
                <div className={style.tagBox}>
                  <h5 className={``}>
                    Nominated Ideas Count:{" "}
                    <span className={`textLightOrange `}>
                      @nominatedIdeasCount
                    </span>
                  </h5>
                  <small>*To add 'Nominated Ideas Count' variable.</small>
                </div>
              </div>
            </div>
            <div className={style.rightBox}>
              <form className={style.emailServiceForm}>
                <div className={`${style.commonBox}`}>
                  <label className={`${style.lableCommon}`}>To</label>
                  {/* emailUsers */}
                  <div className={`${style.outerBox}`}>
                    <select
                      className={`form_control`}
                      name="emailUsers"
                      id="emailUsers"
                      value={selectedOption}
                      onChange={changeHandler}
                    >
                      <option value="" selected disabled>
                        -- Select an Option ---
                      </option>
                      <option value="All Users">All Users</option>
                      <option value="Email Address">Enter custom Emails</option>
                      <option value="Verified SPOCs">
                        All SPOCs on the Platform
                      </option>
                      <option value="Non-Verified SPOCs">
                        Everyone Who has not applied for school registration
                      </option>
                      <option value="Rejected SPOCs">
                        Everyone whoes school registartion request was rejcted
                      </option>
                      <option value="Reapplied SPOCs">
                        Everyone who has reapplied for SPOC school registartion
                      </option>
                      <option value="0 teams SPOCs">
                        SPOCs who have not created any teams
                      </option>
                      <option value="0 submissions SPOCs">
                        SPOCs who have not made any submissions
                      </option>
                      <option value="0 nominations SPOCs">
                        SPOCs who have not nominated any ideas
                      </option>
                      <option value="Custom SPOCs">
                        Customise type of SPOC selection
                      </option>
                    </select>
                    {selectedOption === "Email Address" && (
                      <textarea
                        placeholder="Enter upto 3 emails seprated by Commas"
                        name="emails"
                        onChange={changeHandler}
                        value={allValues.emails}
                      ></textarea>
                    )}

                    {selectedOption === "Custom SPOCs" && (
                      <div className={style.ifverifiedSpoc}>
                        <div className={style.ifverifiedSpocRepeatBox}>
                          <label>Teams: </label>
                          <div>
                            <span>
                              <input
                                type="radio"
                                name="spocTeams"
                                onChange={changeHandler}
                                value="zero"
                              />{" "}
                              0
                            </span>
                            <span>
                              <input
                                type="radio"
                                name="spocTeams"
                                onChange={changeHandler}
                                value="atleastOne"
                              />{" "}
                              atleast 1
                            </span>
                            <span>
                              <input
                                type="radio"
                                name="spocTeams"
                                onChange={changeHandler}
                                value="all"
                                defaultChecked
                              />{" "}
                              all
                            </span>
                          </div>
                        </div>
                        <div className={style.ifverifiedSpocRepeatBox}>
                          <label>Ideas: </label>
                          <div>
                            <span>
                              <input
                                type="radio"
                                name="spocSubmissions"
                                onChange={changeHandler}
                                value="zero"
                              />{" "}
                              0
                            </span>
                            <span>
                              <input
                                type="radio"
                                name="spocSubmissions"
                                onChange={changeHandler}
                                value="atleastOne"
                              />{" "}
                              atleast 1
                            </span>
                            <span>
                              <input
                                type="radio"
                                name="spocSubmissions"
                                onChange={changeHandler}
                                value="all"
                                defaultChecked
                              />{" "}
                              all
                            </span>
                          </div>
                        </div>
                        <div className={style.ifverifiedSpocRepeatBox}>
                          <label>Nominations: </label>
                          <div>
                            <span>
                              <input
                                type="radio"
                                name="spocNominations"
                                onChange={changeHandler}
                                value="zero"
                              />{" "}
                              0
                            </span>
                            <span>
                              <input
                                type="radio"
                                name="spocNominations"
                                onChange={changeHandler}
                                value="atleastOne"
                              />{" "}
                              atleast 1
                            </span>
                            <span>
                              <input
                                type="radio"
                                name="spocNominations"
                                onChange={changeHandler}
                                value="all"
                                defaultChecked
                              />{" "}
                              all
                            </span>
                          </div>
                        </div>

                        <small className={`textLightBlue`}>
                          * Email will be sent to all spocs{" "}
                          {(allValues.spocTeams != "all" ||
                            allValues.spocNominations != "all" ||
                            allValues.spocSubmissions != "all") &&
                            "that have created"}{" "}
                          {allValues.spocTeams != "all" &&
                            (allValues.spocTeams === "zero"
                              ? "0 Teams"
                              : "at least one team")}{" "}
                          {(allValues.spocTeams != "all" &&
                            allValues.spocNominations != "all") ||
                          allValues.spocSubmissions != "all" ? (
                            <>
                              {allValues.spocNominations != "all" &&
                              allValues.spocSubmissions != "all"
                                ? ","
                                : "and"}
                            </>
                          ) : (
                            ""
                          )}{" "}
                          {allValues.spocSubmissions != "all" &&
                            (allValues.spocSubmissions === "zero"
                              ? "0 Submissions"
                              : "at least one Submission")}{" "}
                          {allValues.spocTeams != "all" &&
                            allValues.spocSubmissions != "all" &&
                            allValues.spocNominations != "all" &&
                            "and "}{" "}
                          {allValues.spocNominations != "all" &&
                            (allValues.spocNominations === "zero"
                              ? "0 Nominations"
                              : "at least one Nomination")}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                {/* emailSubject */}
                <div className={`${style.commonBox}`}>
                  <label className={`${style.lableCommon}`}>Subject</label>
                  <div className={`${style.outerBox}`}>
                    <input
                      className={`form_control`}
                      name="emailSubject"
                      id="emailSubject"
                      type="text"
                      placeholder="Enter Mail Subject"
                      onChange={changeHandler}
                      value={allValues.emailSubject}
                    />
                  </div>
                </div>
                {/* emailBody */}
                <div className={`${style.commonBox}`}>
                  <label className={`${style.lableCommon}`}>Body</label>
                  <div className={`${style.outerBox}`}>
                    <TinymceEditor changeHandler={BodyChangeHandler} />
                   {/* {allValues && (
                     <QuillEditor defaultt={allValues.emailBody} changeHandler={BodyChangeHandler} quillObj={quillObj}/>
                   )} */}
                  </div>
                </div>

                <div className={`d-flex align-center justify-between`}>
                  <div
                    className={`btn outlineBtnOrange`}
                    onClick={openTestModal}
                  >
                    Send Test Email
                  </div>
                  <div className={`d-flex align-center ${style.actionButtons}`}>
                    <div className={`btn secondaryBtn`}>Cancel</div>
                    <div
                      className={`btn orangeBtn`}
                      onClick={onpenPreviewModal}
                    >
                      Save & Next
                    </div>
                  </div>
                </div>

                {isTestModal && (
                  <Modal
                    close={() => {
                      setTestModalOpen(false);
                    }}
                    type="testMail"
                    changeHandler={changeHandler}
                    sendEmail={sendEmail}
                  />
                )}
                {isPreviewModalOpen && (
                  <Modal
                    close={() => {
                      setPreviewModalOpen(false);
                    }}
                    type="previewMail"
                    previewMailContent={allValues}
                    sendEmail={sendEmail}
                  />
                )}
              </form>
            </div>
          </div>

          <div className={style.tableConatiner}>
            <Table   tableName="emailLogs"
                tableHeader={[
                  "S.No.",
                  "Subject",
                  "Sent To",
                  "Recipients",
                  "Sent By",
                  "Sent On",
                  "Action",
                ]}
                tableContent={tableContent}
                page={currentPage}
                review={previewEmail}/>
                <div className={style.PaginationConatiner}>
                  {/* Pagination */}
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={pageChangeHandler}
                  />
                </div>
          </div>
        </div>
      </div>
      {isReviewModalOpen && (
                  <Modal
                    close={() => {
                      setIsReviewModalOpen(false);
                    }}
                    type="reviewMail"
                    previewMailContent={reviewValues}
                  />
                )}
    </>
  );
};
export default EmailService;
