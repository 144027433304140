import { h } from "preact";
import style from "./style.css";
import { Icon } from "@iconify/react";
import Header from "../../../components/header";
import SpocDashboardTop from "../../../components/spocDashboardTop";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
const Dashboard = () => {
  const teamsAdded = useSelector((state) => state.spocStep.teamsAdded);
  const submissionAdded = useSelector(
    (state) => state.spocStep.submissionAdded
  );
  const nomiantionAdded = useSelector(
    (state) => state.spocStep.nomiantionAdded
  );

  const [activeStep, setActiveStep] = useState(0);
  useEffect(() => {
    if (teamsAdded === false) {
      setActiveStep(0);
    } else if (teamsAdded === true && submissionAdded === false) {
      setActiveStep(1);
    } else if (submissionAdded === true && nomiantionAdded === false) {
      setActiveStep(2);
    } else if (nomiantionAdded === true) {
      setActiveStep(3);
    }
  }, [teamsAdded, submissionAdded, nomiantionAdded]);

  return (
    <>
      <Header page="spoc-dashboard" />
      <img
        src="https://saxenaprachi.github.io/publicAssets/images/shuttleRocket.webp"
        className={style.rocketRight}
      />
      <div className={style.spocDashboard}>
        <SpocDashboardTop />

        <div className={style.spocDasboardContent}>
          <div className={style.tabsOuterBox}>
            {/* Tab buttons */}
            <div className={style.tabs}>
              <Link to="/spoc/dashboard" className={`${style.active}`}>
                DASHBOARD
              </Link>
              <Link to="/spoc/stats">STATISTICS</Link>
              <Link to="/spoc/manage-teams">MANAGE TEAMS</Link>
              <Link to="/spoc/manage-submissions">MANAGE SUBMISSIONS</Link>
              {/* <Link to="/spoc/others">OTHERS</Link> */}
            </div>

            <hr className={style.horizontalLine} />
            {/* Tab content */}
            <div className={style.allTabsContentOuter}>
              <div>
                <h4 className={style.pageTitle}>GET STARTED</h4>

                <div className={style.timeline}>
                  <div className={style.outer}>
                    <div className={style.card}>
                      <div className={style.info}>
                        <h3 className={`${style.title} ${style.done}`}>
                          About SIH Junior 2023
                        </h3>
                        <ul>
                          <li>
                            SIH Junior 2023 is a Smart India Hackathon that
                            invites school students to contribute their
                            innovative ideas to solve real-world challenges
                            posed by different industries.
                          </li>
                          <li>
                            SPOC’s are required to download and understand
                            carefully the
                            <Link
                              className={style.guideLink}
                              to="https://docs.google.com/document/d/e/2PACX-1vTm4SnvypzxQmu6wvnxuKBsWDq1k6MxWPsHv1mjnt57Km_CYmIRp867Qk4XghQ_V8Y8lslBTYBo3BTj/pub"
                              target="__blank"
                            >
                              {" "}
                              Student Guideline{" "}
                            </Link>{" "}
                            as well as
                            <Link
                              className={style.guideLink}
                              to="https://docs.google.com/document/d/e/2PACX-1vSOUaPVJWGKwqWy_Z_H15nu_s5dm9BdlPxMZnQ5H1BStfcK0dLw6uU2ugeCEfRzblujBgcG6T9V8-lK/pub"
                              target="__blank"
                            >
                              {" "}
                              SPOC Guideline{" "}
                            </Link>{" "}
                            , to ensure a smooth and results oriented hackathon
                            experience.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className={style.card}>
                      <div className={style.info}>
                        <h3
                          className={`${style.title} ${
                            activeStep === 0 ? style.active : style.done
                          }`}
                        >
                          STEP 1: Curate Ideas
                        </h3>
                        <p>
                          In this step, SPOC are encouraged to brainstorm and
                          curate their innovative ideas for the SIH Jr 2023.
                          This is the foundation of your journey in hackathon,
                          here’s what you can do:
                        </p>
                        <ul>
                          <li>
                            Access a sample PPT to help you structure and
                            present your ideas effectively:{" "}
                          </li>
                          <Link
                            className={`btn ${style.createButton}`}
                            to="https://varsha685.github.io/publicAssets/SIH_Jr/Idea-Presentation-Format-SIH2023-School.pptx"
                            download
                          >
                            Download Sample PPT
                          </Link>
                        </ul>
                      </div>
                    </div>
                    <div className={style.card}>
                      <div className={style.info}>
                        <h3
                          className={`${style.title} ${
                            activeStep === 1
                              ? style.active
                              : activeStep > 1
                              ? style.done
                              : activeStep === 0
                              ? style.next
                              : ""
                          }`}
                        >
                          STEP 2: Create Teams
                        </h3>
                        <ul>
                          <li>
                            Great! Looks like you’ve curated your ideas already.
                            It’s time to create and organise your teams and
                            here’s your team formation guidelines to be
                            followed:
                            <ol>
                              <li>
                                A team can be formed of 3 students to 6 students
                                (at max); 2 Mentors (One Mentor Mandatory and
                                One Mentor is optional) (can be
                                teachers/parents/industry experts).
                              </li>
                              <li>
                                It is recommended to have at least one female
                                member to be a part of the team.
                              </li>
                              <li>
                                All students should be from the same school.
                                Inter-school teams are not allowed.
                              </li>
                              <li>
                                Team Name should be unique and not contain the
                                name of their institute in any form.
                              </li>
                              <li>
                                In Track 1, students who are in 6th to 9th
                                standard should form a team together.
                              </li>
                              <li>
                                As for Track 2, students who are in 10th to 12th
                                standard should create a team together.
                              </li>
                            </ol>
                          </li>
                          <li>
                            That’s all we’re now ready to onboard your teams to
                            the platform, click below:
                          </li>
                          <Link
                            to="/spoc/manage-teams"
                            className={`btn ${style.createButton}`}
                          >
                            CREATE & MANAGE TEAMS
                          </Link>
                          {/* <li>
                            Once you’ve onboarded the teams on the platform,
                            feel free to get started with submitting ideas by
                            clicking on the “Manage Submissions” button:
                          </li> */}
                          {/* <Link className={`btn ${style.disabledButton}`}>
                            MANAGE SUBMISSIONS
                          </Link> */}
                        </ul>
                      </div>
                    </div>
                    <div className={style.card}>
                      <div className={style.info}>
                        <h3
                          className={`${style.title} ${
                            activeStep === 2
                              ? style.active
                              : activeStep > 2
                              ? style.done
                              : activeStep === 1
                              ? style.next
                              : ""
                          }`}
                        >
                          STEP 3: Submit Ideas
                        </h3>
                        <ul>
                          <li>
                            Awesome! You have already finalised the team
                            creation workflow and we are equally excited to move
                            further in your SIH Jr 2023 Journey. It’s time to
                            upload idea submissions of your created teams and
                            here are your idea submission guidelines to be
                            followed:
                            <ol>
                              <li>
                                One team can make multiple idea submissions.
                              </li>
                              <li>
                                Total number of ideas submitted should be 30.
                              </li>
                              <li>
                                SPOC should finalise the 30 ideas to be uploaded
                                very efficiently.
                              </li>
                            </ol>
                          </li>
                          <li>
                            That’s all we’re now ready to onboard your teams to
                            the platform, click below:
                          </li>
                          <Link
                            className={`btn ${style.createButton}`}
                            to={`/spoc/manage-submissions`}
                          >
                            SUBMIT & MANAGE IDEAS
                          </Link>
                          {/* <li>
                            Once you’ve onboarded submitted the ideas on the
                            platform, feel free to get started with submitting
                            ideas by clicking on the “Manage Submissions”
                            button:
                          </li> */}
                          {/* <Link className={`btn ${style.disabledButton}`}>
                            START SUBMISSIONS
                          </Link> */}
                        </ul>
                      </div>
                    </div>
                    <div className={style.card}>
                      <div className={style.info}>
                        <h3
                          className={`${style.title} ${
                            activeStep === 3
                              ? style.active
                              : activeStep > 3
                              ? style.done
                              : activeStep === 2
                              ? style.next
                              : ""
                          }`}
                        >
                          STEP 4: Nominate Teams
                        </h3>
                        <ul>
                          <li>
                            Fast and Fierce! You have already finalised the idea
                            submission workflow and we are equally excited to
                            move further in your SIH Jr 2023 Journey. It’s time
                            to finally take the big leap and nominate the top
                            notch of your created teams.
                            <br />
                            The next step is as easy as a blink of an eye ;)
                            <ol type="1">
                              <li>Click on the Nominate Teams button below.</li>
                              <li>
                                Select submissions that you feel are feasibly
                                efficient for the Hack.
                              </li>
                            </ol>
                          </li>
                          <Link
                            to="/spoc/nominate-team"
                            className={`btn ${style.createButton}`}
                          >
                            NOMINATE TEAMS
                          </Link>
                          {/* <li>
                            For every theme from each state and UT the top
                            performing ideas will be shortlisted to the Grand
                            Finale of the Smart India hackathon
                          </li> */}
                          {/* <li>
                            30 teams will be shortlisted from track-1 (class 6th
                            to 9th)
                          </li> */}
                          {/* <li>
                            30 teams will be shortlisted from track-2 (class
                            10th to 12th)
                          </li> */}
                        </ul>
                      </div>
                    </div>
                    <div className={style.card}>
                      <div className={style.info}>
                        <h3
                          className={`${style.title}  ${
                            activeStep === 4
                              ? style.active
                              : activeStep > 4
                              ? style.done
                              : activeStep === 3
                              ? style.next
                              : ""
                          }`}
                        >
                          Check Team Status
                        </h3>
                        <ul>
                          <li>
                            Each team should be able to keep track of the
                            evaluation status of their ideas.
                          </li>
                          <Link className={`btn ${style.disabledButton}`}>
                            CHECK TEAM STATUS
                          </Link>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
