import { h } from "preact";
import style from "./style.css";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "preact/hooks";
import Header from "../../../components/header";
import SpocDashboardTop from "../../../components/spocDashboardTop";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../../components/table";
import Modal from "../../../components/modal";
import {
  deleteTeamById,
  getTeam,
  getTeams,
} from "../../../modules/spoc/actions";
import Pagination from "../../../components/pagination";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalLoading } from "../../../redux/slices/loadingSlice";
import GlobalLoader from "../../../components/globalLoader";

const ManageTeams = () => {
  const dispatch = useDispatch();
  const globalLoader = useSelector((state) => state.globalLoader.value);
  const navigate = useNavigate();

  const [tableContent, setTableContent] = useState([]);
  const [tier1Teams, setTier1Teams] = useState(0);
  const [tier2Teams, setTier2Teams] = useState(0);
  const [showTango, setShowTango] = useState(true);

  // Filter
  const [tierFilter, setTierFilter] = useState("Tier-2,Tier-1");
  const [submissionFilter, setSubmissionFilter] = useState("0,1,2,3");
  const [statusFilter, setStatusFilter] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const pageChangeHandler = (page) => {
    try {
      setCurrentPage(page);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    try {
      fetchData(
        currentPage,
        tierFilter,
        submissionFilter,
        statusFilter,
        searchFilter
      );
    } catch (e) {
      console.log(e);
    }
  }, [currentPage]);

  const fetchData = async (
    page,
    tierFilter,
    submissionFilter,
    statusFilter,
    searchFilter
  ) => {
    dispatch(setGlobalLoading(true));
    try {
      const response = await getTeams(
        page,
        tierFilter,
        submissionFilter,
        statusFilter,
        searchFilter
      );
      if (response?.success === true) {
        setTableContent(response?.data);
        setTotalPages(response?.pages);
        setTier1Teams(response?.tier1);
        setTier2Teams(response?.tier2);
      }
    } catch (e) {
      console.log(e);
    }
    dispatch(setGlobalLoading(false));
  };
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [teamToDelete, setTeamToDelete] = useState("");
  const confirmation = (id) => {
    setIsConfirmationModalOpen(true);
    setTeamToDelete(id);
  };
  const deleteTeam = async (id) => {
    try {
      const response = await deleteTeamById({
        team: id,
      });
      if (response?.success === true) {
        toast.success("Team Deleted Successfully!");
        fetchData(
          currentPage,
          tierFilter,
          submissionFilter,
          statusFilter,
          searchFilter
        );
        setIsConfirmationModalOpen(false);
      } else {
        toast.error(response?.message);
        setIsConfirmationModalOpen(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const viewEdit = (teamId) => {
    localStorage.setItem("teamId", teamId);
    navigate("/spoc/edit-team");
  };
  const filterFetching = () => {
    // console.log(statusFilter);
    let newStatusFilter = "";
    if (document.getElementById("pending").checked === true) {
      newStatusFilter = newStatusFilter + "PENDING" + ",";
    }
    if (document.getElementById("completed").checked === true) {
      newStatusFilter = newStatusFilter + "COMPLETED" + ",";
    }
    if (document.getElementById("nominated").checked === true) {
      newStatusFilter = newStatusFilter + "NOMINATED" + ",";
    }

    setStatusFilter(newStatusFilter);
    setCurrentPage(1);

    fetchData(1, tierFilter, submissionFilter, newStatusFilter, searchFilter);
  };
  const trackFilterFetching = () => {
    let newTierFilter = "";
    if (document.getElementById("Tier-1").checked === true) {
      newTierFilter = newTierFilter + "Tier-1" + ",";
    }
    if (document.getElementById("Tier-2").checked === true) {
      newTierFilter = newTierFilter + "Tier-2";
    }

    setTierFilter(newTierFilter);
    setCurrentPage(1);

    fetchData(1, newTierFilter, submissionFilter, statusFilter, searchFilter);
  };
  const submissionsFilterFetching = () => {
    let newSubmissionFilter = "";
    if (document.getElementById("0").checked === true) {
      newSubmissionFilter = newSubmissionFilter + "0" + ",";
    }
    if (document.getElementById("1").checked === true) {
      newSubmissionFilter = newSubmissionFilter + "1" + ",";
    }
    if (document.getElementById("2").checked === true) {
      newSubmissionFilter = newSubmissionFilter + "2" + ",";
    }
    if (document.getElementById("3").checked === true) {
      newSubmissionFilter = newSubmissionFilter + "3" + ",";
    }
    newSubmissionFilter = newSubmissionFilter.slice(0, -1);
    setSubmissionFilter(newSubmissionFilter);
    setCurrentPage(1);

    fetchData(1, tierFilter, newSubmissionFilter, statusFilter, searchFilter);
  };
  const [teamPreviewModal, setTeamPreviewModal] = useState(false);
  const [teamDetails, setTeamDetails] = useState(null);

  const previewTeam = async (id) => {
    try {
      const response = await getTeam(id);
      if (response?.success === true) {
        setTeamDetails(response?.data);
        // console.log("response:", response?.data);
        setTeamPreviewModal(true);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Header page="spoc-dashboard" />
      <img
        src="https://saxenaprachi.github.io/publicAssets/images/shuttleRocket.webp"
        className={style.rocketRight}
      />
      <div className={style.spocDashboard}>
        <SpocDashboardTop />

        <div className={style.spocDasboardContent}>
          <div className={style.tabsOuterBox}>
            {/* Tab buttons */}
            <div className={style.tabs}>
              <Link to="/spoc/dashboard">DASHBOARD</Link>
              <Link to="/spoc/stats">STATISTICS</Link>
              <Link to="/spoc/manage-teams" className={`${style.active}`}>
                MANAGE TEAMS
              </Link>

              <Link to="/spoc/manage-submissions">MANAGE SUBMISSIONS</Link>
              {/* <Link to="/spoc/others">OTHERS</Link> */}
            </div>

            <hr className={style.horizontalLine} />
            {/* Tab content */}
            <div className={style.allTabsContentOuter}>
              <div>
                <h4 className={style.pageTitle}>MANAGE TEAMS</h4>
                <div className={style.mainConatiner}>
                  {/* Stats */}
                  <div className={style.statConatiner}>
                    <div className={style.stat}>
                      Teams from Track 1{" "}
                      <div className={style.statNumber}> {tier1Teams} </div>
                    </div>
                    <div className={style.stat}>
                      Teams from Track 2{" "}
                      <div className={style.statNumber}> {tier2Teams} </div>
                    </div>
                    <div className={style.stat}>
                      Total Teams{" "}
                      <div className={style.statNumber}>
                        {" "}
                        {tier2Teams + tier1Teams}{" "}
                      </div>
                    </div>
                  </div>

                  {/* Search and Create Button */}
                  <div className={style.tableHeadConatiner}>
                    <div className={style.tableTitle}>Team Management</div>
                    <div className={style.searchConatiner}>
                      <div className={`search`}>
                        <input
                          name="search"
                          id="search"
                          placeholder="Search By Team Name,Mentor Name & Leader Name,"
                          defaultValue={searchFilter}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              document.getElementById("searchButton").click();
                            }
                          }}
                        />
                        <div
                          id="searchButton"
                          className="searchIcon"
                          onClick={() => {
                            setSearchFilter(
                              document.getElementById("search").value
                            );
                            setCurrentPage(1);
                            fetchData(
                              1,
                              tierFilter,
                              submissionFilter,
                              statusFilter,
                              document.getElementById("search").value
                            );
                          }}
                        >
                          <Icon icon="carbon:search" />
                        </div>
                      </div>
                      <div
                        className="filterContainer"
                        onClick={() => {
                          setFilterOpen(true);
                        }}
                      >
                        <img
                          src="https://saxenaprachi.github.io/publicAssets/icons/filter.svg"
                          alt="svg"
                        />
                        {filterOpen && (
                          <div
                            className="filter"
                            onMouseLeave={() => {
                              setFilterOpen(false);
                            }}
                          >
                            {/* Filter By Track */}
                            <div>
                              Filter By Track
                              <p>
                                {tierFilter.includes("Tier-1") ? (
                                  <input
                                    type="checkbox"
                                    value="Tier-1"
                                    id="Tier-1"
                                    onChange={trackFilterFetching}
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    value="Tier-1"
                                    id="Tier-1"
                                    onChange={trackFilterFetching}
                                  />
                                )}
                                &nbsp; <label htmlFor="Tier-1">Track 1</label>
                              </p>
                              <p>
                                {tierFilter.includes("Tier-2") ? (
                                  <input
                                    type="checkbox"
                                    value="Tier-2"
                                    id="Tier-2"
                                    onChange={trackFilterFetching}
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    value="Tier-2"
                                    id="Tier-2"
                                    onChange={trackFilterFetching}
                                  />
                                )}
                                &nbsp; <label htmlFor="Tier-2">Track 2</label>
                              </p>
                            </div>
                            {/* Filter By Status */}
                            <div>
                              Filter By Status
                              <p>
                                {statusFilter.includes("PENDING") ? (
                                  <input
                                    type="checkbox"
                                    value="pending"
                                    id="pending"
                                    onChange={filterFetching}
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    value="pending"
                                    id="pending"
                                    onChange={filterFetching}
                                  />
                                )}
                                &nbsp; <label htmlFor="pending">Pending</label>
                              </p>
                              <p>
                                {statusFilter.includes("COMPLETED") ? (
                                  <input
                                    type="checkbox"
                                    value="completed"
                                    id="completed"
                                    onChange={filterFetching}
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    value="completed"
                                    id="completed"
                                    onChange={filterFetching}
                                  />
                                )}
                                &nbsp;{" "}
                                <label htmlFor="completed">Completed</label>
                              </p>
                              <p>
                                {statusFilter.includes("NOMINATED") ? (
                                  <input
                                    type="checkbox"
                                    value="nominated"
                                    id="nominated"
                                    onChange={filterFetching}
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    value="nominated"
                                    id="nominated"
                                    onChange={filterFetching}
                                  />
                                )}
                                &nbsp;{" "}
                                <label htmlFor="nominated">Freezed</label>
                              </p>
                            </div>
                            {/* Filter By Submission */}
                            <div>
                              Filter By Submission
                              <p>
                                {submissionFilter.includes("0") ? (
                                  <input
                                    type="checkbox"
                                    value="0"
                                    id="0"
                                    onChange={submissionsFilterFetching}
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    value="0"
                                    id="0"
                                    onChange={submissionsFilterFetching}
                                  />
                                )}
                                &nbsp; <label htmlFor="0">0 Submissions</label>
                              </p>
                              <p>
                                {submissionFilter.includes("1") ? (
                                  <input
                                    type="checkbox"
                                    value="1"
                                    id="1"
                                    onChange={submissionsFilterFetching}
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    value="1"
                                    id="1"
                                    onChange={submissionsFilterFetching}
                                  />
                                )}
                                &nbsp; <label htmlFor="1">1 Submission</label>
                              </p>
                              <p>
                                {submissionFilter.includes("2") ? (
                                  <input
                                    type="checkbox"
                                    value="2"
                                    id="2"
                                    onChange={submissionsFilterFetching}
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    value="2"
                                    id="2"
                                    onChange={submissionsFilterFetching}
                                  />
                                )}
                                &nbsp; <label htmlFor="2">2 Submissions</label>
                              </p>
                              <p>
                                {submissionFilter.includes("3") ? (
                                  <input
                                    type="checkbox"
                                    value="3"
                                    id="3"
                                    onChange={submissionsFilterFetching}
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    value="3"
                                    id="3"
                                    onChange={submissionsFilterFetching}
                                  />
                                )}
                                &nbsp; <label htmlFor="3">3 Submissions</label>
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                      <Link
                        to={"/spoc/create-team"}
                        className={` btn orangeBtn`}
                      >
                        Create Team
                      </Link>
                    </div>
                  </div>

                  {/* Table  */}
                  <Table
                    tableName="teamList"
                    tableHeader={[
                      "Team Name",
                      "Mentor Name",
                      "Leader Name",
                      "Submission",
                      "Track",
                      "Status",
                      "Preview",
                      "Action",
                    ]}
                    tableContent={tableContent}
                    confirmation={confirmation}
                    viewEdit={viewEdit}
                    previewSubmission={previewTeam}
                  />

                  
                  <div className={style.paginationContainer}>
                    {/* Pagination */}
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={pageChangeHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Reference STATUS */}
          <div className={style.referenceBox}>
            <div className={style.referenceHeading}>REFERENCES</div>
            <table>
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Description</th>
                  <th>Edit Access</th>
                  <th>View Access</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span className={`${style.statusBtn} ${style.orangeStatus}`}>PENDING</span>
                  </td>
                  <td>Team creation was initiated, but all the necessary details were not provided.</td>
                  <td><Icon icon="charm:tick" /></td>
                  <td><Icon icon="charm:tick" /></td>
                </tr>
                <tr>
                  <td>
                    <span className={`${style.statusBtn} ${style.blueStatus}`}>COMPLETED</span>
                  </td>
                  <td>Team creation has been successfully finished.</td>
                  <td><Icon icon="charm:tick" /></td>
                  <td><Icon icon="charm:tick" /></td>
                </tr>
                <tr>
                  <td>
                    <span className={`${style.statusBtn} ${style.extraGreenStatus}`}>FREEZED</span>
                  </td>
                  <td>One or more ideas associated with the team have been nominated.</td>
                  <td className={style.crossIcon}><Icon icon="charm:cross"  /></td>
                  <td><Icon icon="charm:tick" /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isConfirmationModalOpen && (
        <Modal
          close={() => setIsConfirmationModalOpen(false)}
          confirmationMessage={"Do You want to delete this team?"}
          deleteTeam={deleteTeam}
          confirmationValue={"deleteTeam"}
          type="confimationMail"
          teamToDelete={teamToDelete}
        />
      )}
      {teamPreviewModal && teamDetails && (
        <Modal
          close={() => setTeamPreviewModal(false)}
          type="teamPreview"
          teamDetails={teamDetails}
        />
      )}
      {tier1Teams + tier2Teams === 0 && showTango && (
        <div className={style.modal}>
          <div className={style.modal_content}>
            <div className={style.headerOfModal}>
              <h4>Platform Guide - SIH Junior</h4>
              <span
                className={style.close}
                onClick={() => {
                  setShowTango(false);
                }}
              >
                &times;
              </span>
            </div>
            <div>
              <iframe
                src="https://app.tango.us/app/embed/8a8a3064-7813-47b5-92a1-d856e9652c85?skipCover=false&defaultListView=false&skipBranding=true"
                sandbox="allow-scripts allow-top-navigation-by-user-activation allow-popups allow-same-origin"
                security="restricted"
                title="SIH JuniorHackathon - Team Creation and Idea Submission"
                width="100%"
                height="100%"
                referrerpolicy="strict-origin-when-cross-origin"
                frameborder="0"
                webkitallowfullscreen="webkitallowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                allowfullscreen="allowfullscreen"
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageTeams;
