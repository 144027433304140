import { h, Component } from "preact";
import style from "./style.css";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { useEffect } from "preact/hooks";
import { getProfile, getNoticeBoard } from "../../modules/spoc/actions";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setNominationAdded,
  setSubmissionAdded,
  setTeamAdded,
} from "../../redux/slices/spocStepSlice";
import { setGlobalLoading } from "../../redux/slices/loadingSlice";

const SpocDashboardTop = () => {
  const dispatch = useDispatch();
  // Fetch Dash Top Data
  const [dashTopData, setDashTopData] = useState({
    city: "",
    consentLetter: "",
    country: "",
    email: "",
    facultyFirstName: "",
    facultyLastName: "",
    schoolBoardName: "",
    schoolBoardType: "",
    schoolCode: "",
    schoolLogo: "",
    schoolName: "",
    state: "",
    district: "",
    facultyPhone: "",
  });

  const [allValues, setAllValues] = useState({
    text: "",
    theme: "",
    visibility: false,
  });

  const fetchDashTopData = async () => {
    dispatch(setGlobalLoading(true));
    try {
      const response = await getProfile();
      if (response?.success === true) {
        setDashTopData(response?.body);
        dispatch(setTeamAdded(response?.body.teamsAdded));
        dispatch(setSubmissionAdded(response?.body.submissionsAdded));
        dispatch(setNominationAdded(response?.body.nominationsAdded));
      }

      const responseNotice = await getNoticeBoard();
      if (responseNotice?.success === true) {
        setAllValues(responseNotice?.data);
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setGlobalLoading(false));
  };
  useEffect(() => {
    fetchDashTopData();
  }, []);

  return (
    <>
      <div className={style.dashboardTopDiv}>
        <div className={style.threeCols}>
          <div className={`${style.column} ${style.col1}`}>
            <div className={`${style.leftImage}`}>
              <img
                src={dashTopData?.schoolLogo}
                // className={`${style.leftImage}`}
              />
            </div>
            <div className={style.rightContent}>
              <h5>School Details</h5>
              <p className={`${style.fontAndWeight}`}>
                {dashTopData?.schoolName}
              </p>
              <p className={style.locationIcon}>
                <Icon icon="fluent:location-12-filled" />
                {dashTopData.state} {dashTopData.city}, India
              </p>
              <p>
                School Registration Code:{" "}
                <span className="textBlue fontWeight600">
                  {dashTopData.schoolCode}
                </span>
              </p>
              <Link
                to="https://docs.google.com/document/d/e/2PACX-1vTm4SnvypzxQmu6wvnxuKBsWDq1k6MxWPsHv1mjnt57Km_CYmIRp867Qk4XghQ_V8Y8lslBTYBo3BTj/pub"
                className="btn orangeBtn"
                download
                target="_blank"
              >
                Student guidelines{" "}
              </Link>
            </div>
          </div>
          <hr className={style.verticleLine} />
          <hr className={style.hrCol1Mobile} />
          <div className={`${style.column} ${style.col2}`}>
            <h5>SPOC Details</h5>
            <p>
              Faculty Name:{" "}
              <span className={`${style.fontAndWeight}`}>
                {dashTopData.facultyFirstName} {dashTopData.facultyLastName}
              </span>
            </p>
            <p>
              Email:{" "}
              <span className={`${style.fontAndWeight}`}>
                {dashTopData.email}
              </span>
            </p>
            <p>
              Mobile Number:{" "}
              <span className={`${style.fontAndWeight}`}>
                +91 {dashTopData.facultyPhone}
              </span>
            </p>
            <Link
              to="https://docs.google.com/document/d/e/2PACX-1vSOUaPVJWGKwqWy_Z_H15nu_s5dm9BdlPxMZnQ5H1BStfcK0dLw6uU2ugeCEfRzblujBgcG6T9V8-lK/pub"
              className="btn  orangeBtn"
              download
              target="_blank"
            >
              SPOC guidelines
            </Link>
          </div>
          <hr className={`${style.verticleLine}`} />
          <div className={`${style.column} ${style.col3}`}>
            <div className={style.poweredByH2sLogo}>
              Powered By : &nbsp;
              <Link to="https://hack2skill.com" target="_blank">
                <img
                  width={90}
                  src="https://saxenaprachi.github.io/publicAssets/images/H2S_Gradient_Logo.webp"
                />
              </Link>
            </div>
          </div>
        </div>
        {allValues.visibility && (
          <div
            className={
              style.noticeText +
              " " +
              style[allValues?.theme?.toLowerCase() + "Theme"]
            }
          >
            <Icon icon="ph:info-fill" />
            <span>{allValues?.text}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default SpocDashboardTop;
