import axios from "../api/axios";
import { useSelector, useDispatch } from "react-redux";
import { setAccessToken, setEmail, setRole } from "../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";

const useRefresh = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const accessToken = useSelector((state) => state.user.accessToken);
  const refresh = async () => {
    try {
      const response = await axios.get("/api/v1/auth/refresh-token", {
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      if (response?.data?.accessToken) {
        dispatch(setAccessToken(response?.data?.accessToken));
        dispatch(setRole(response?.data?.roles));
        localStorage.setItem("isAuth", true);
      } else {
        localStorage.removeItem("isAuth");
        localStorage.removeItem("teamId");
        navigate("/");
      }

      return {
        authToken: response.data.accessToken,
      };
    } catch (error) {
      localStorage.removeItem("isAuth");
      localStorage.removeItem("teamId");
      navigate("/");
    }
  };

  return refresh;
};

export default useRefresh;
