import { axiosPrivate } from "../../api/axios";

// Add admin
export async function addAdmin(data) {
  try {
    // console.log("data: ", data);
    const res = await axiosPrivate.post("/api/v1/super-admin/add-admin", data);
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Get Admins
export async function getAdmins(page) {
  try {
    const res = await axiosPrivate.get(
      `/api/v1/super-admin/admin-list?page=${page}`
    );
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Disable Admin
export async function disableAdmin(data) {
  try {
    const res = await axiosPrivate.post(
      "/api/v1/super-admin/inactive-admin",
      data
    );
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Enable Admin
export async function enableAdmin(data) {
  try {
    const res = await axiosPrivate.post(
      "/api/v1/super-admin/active-admin",
      data
    );
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Get admin Activity Logs
export async function getActivity(id, page) {
  try {
    const res = await axiosPrivate.get(
      `/api/v1/super-admin/activity-logs/${id}?page=${page}`
    );
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}
