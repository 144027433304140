import React, { useEffect, useState } from "react";
import style from "./style.css";

const ProgressLine = ({
  label,
  backgroundColor = "#e5e5e5",
  visualParts = [
    {
      percentage: "0%",
      color: "white",
      submissionCount:""
    }
  ]
}) => {
  const [widths, setWidths] = useState(
    visualParts.map(() => {
      return 0;
    })
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      setWidths(
        visualParts.map(item => {
          return item.percentage;
        })
      );
    });
  }, [visualParts]);

  return (
    <>
      <div className={style.progressLabel}>{label}</div>
      <div
        className={`${style.progressVisualFull}`}
        style={{backgroundColor}}
      >
        <p className={style.ideaSubmitted}>30</p>
        {visualParts.map((item, index) => {
          return (
            <>
            <div
              key={index}
              style={{
                width: widths[index],
                backgroundColor: item.color
              }}
              className={`${style.progressVisualPart}`}
            >
            <p className={style.ideaSubmitted}>{item?.submissionCount==0 ? "" : item?.submissionCount}</p>
            </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default ProgressLine;
