import { axiosPrivate } from "../../api/axios";

// Add admin
export async function uploadFile(data) {
  try {
    const res = await axiosPrivate.postForm("/api/v1/upload", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}
