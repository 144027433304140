import axios from "../../api/axios";

// Get Ticker Text
export async function getTickerText() {
  try {
    const res = await axios.get(`/api/v1/public/notice-board`);
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}
