import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const HorizontalBarChart = ({submissionWiseTeamsTier}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    const teamCounts = submissionWiseTeamsTier;
    const data = {
      labels: [0,1,2,3],
    //   labels: ['0','1'],
      datasets: [
        {
          label: "Teams",
        //   data: [2,5,10,15,20,25,30],
        data:teamCounts,
        backgroundColor: "#1c1777",
        borderWidth: 1,
        borderRadius:9,
        },
      ],
    };
    new Chart(ctx, {
      type: "bar",
      backgroundColor: "#F0F0F0",
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: "y",
        scales: {
          x: {
            beginAtZero: true,
            
            position: "top",
            grid: {
                color: "transparent",
            },
            title: {
                display: true,
                text: "No. of teams", 
                padding: { top: 10, bottom: 15 },
              },
            precision: 0,
            ticks: {
                beginAtZero: true,
                callback: function(value) {if (value % 1 === 0) {return value;}}
              }
          },
        //   xAxes: [{
        //     ticks: {
        //       beginAtZero: true,
        //       callback: function(value) {if (value % 1 === 0) {return value;}}
        //     }
        //   }],
          y: {
            position: "left", 
            grid: {
                color: "transparent",
            },
            title: {
                display: true,
                text: "No. of submissions", 
                padding: { top: 8, bottom: 15 }, 
            },
            precision: 0,
          },
        },
        plugins: {
          legend: {
            display: false, 
            position: "top", 
          },
          
          datalabels: {
            display: true,
            align: "end",
            color: "black",
            anchor: "end", 
            font: {
              weight: "bold",
            },
          },

          
        },
      },
    });
  }, [submissionWiseTeamsTier]);
  return <canvas ref={chartRef} />;
};
export default HorizontalBarChart;
