import { h, Component } from "preact";
import style from "./style.css";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";

const SuperDashboard = () => {
  return (
    <>
      <Header />
      <div className={style.main}>
        <div className={style.sideBarConatiner}>
          <Sidebar role="super-admin" activeTab="dashboard" />
        </div>
        {/* <div className={style.mainContent}>yo</div> */}
      </div>
    </>
  );
};

export default SuperDashboard;
