import { Editor } from "@tinymce/tinymce-react";

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from "tinymce/tinymce";
// DOM model
import "tinymce/models/dom/model";
// Theme
import "tinymce/themes/silver";
// Toolbar icons
import "tinymce/icons/default";
// Editor styles
import "tinymce/skins/ui/oxide/skin.min.css";

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import "tinymce/plugins/advlist";
import "tinymce/plugins/anchor";
import "tinymce/plugins/autolink";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/autosave";
import "tinymce/plugins/code";
import "tinymce/plugins/directionality";
import "tinymce/plugins/help";
import "tinymce/plugins/image";
import "tinymce/plugins/importcss";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/save";
import "tinymce/plugins/table";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";

// importing plugin resources
import "tinymce/plugins/emoticons/js/emojis";

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
import contentCss from "!!raw-loader!tinymce/skins/content/default/content.min.css";
import contentUiCss from "!!raw-loader!tinymce/skins/ui/oxide/content.min.css";
import { uploadFile } from "../../modules/upload/actions";
import toast from "react-hot-toast";

export default function TinymceEditor({ changeHandler }) {
  return (
    <Editor
      onChange={changeHandler}
      apiKey="cshvuakluoyi5ye4249o7fmkxtnjh3xi5zkdipnln431f47z"
      init={{
        plugins: " anchor autolink image link lists table visualblocks",
        toolbar:
          "undo redo | blocks fontsize | bold italic underline strikethrough | link image  table  | align   | checklist numlist bullist indent outdent | removeformat",
        tinycomments_mode: "embedded",
        tinycomments_author: "Author name",
        block_unsupported_drop: true,
        mergetags_list: [
          { value: "First.Name", title: "First Name" },
          { value: "Email", title: "Email" },
        ],
        file_picker_types: "image",
        file_picker_callback: async (cb, value, meta) => {
          const input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");

          input.addEventListener("change", async (e) => {
            const file = e.target.files[0];
            const formData = new FormData();
            formData.append("file", file);
            const fileSize = file.size;
            const maxSize = 1024 * 1024 * 2;
            if (fileSize > maxSize) {
              toast.error("File size should be less than 2 MB.");
              cb("", { title: file.name });
              return;
            }
            try {
              const response = await uploadFile(formData);
              if (response?.success === true) {
                cb(response?.URL, { title: file.name });
              } else {
                failure(response?.message);
                cb("", { title: file.name });
              }
            } catch (e) {
              console.log(e);
            }
          });

          input.click();
        },
      }}
    />
  );
}
