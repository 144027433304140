import { h, Component } from "preact";
import style from "./style.css";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import Table from "../../../components/table";

const SPOCs = () => {
  return (
    <>
      <Header />
      <div className={style.main}>
        <div className={style.sideBarConatiner}>
          <Sidebar role="sih-admin" activeTab="spocs" />
        </div>
        <div className={style.mainContent}>
          <h3>SPOCS Listing</h3>
        </div>
      </div>
    </>
  );
};

export default SPOCs;
