import { configureStore } from "@reduxjs/toolkit";
import spocStepReducer from "./slices/spocStepSlice";
import userReducer from "./slices/userSlice";
import loadingReducer from "./slices/loadingSlice";

export const store = configureStore({
  reducer: {
    spocStep: spocStepReducer,
    user: userReducer,
    globalLoader: loadingReducer,
  },
});
