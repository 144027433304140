import { h, Component } from "preact";
import ProgressBar from "@ramonak/react-progress-bar";
import style from "./style.css";

const LineProgressGraph = ({ progress }) => {
  return (
    <ProgressBar
      completed={progress}
      className={style.progressBar}
      isLabelVisible={false}
      bgColor={
        "linear-gradient(270deg, #7435FF 0%, #306BFF 99.39%, #306BFF 100%)"
      }
    />
  );
};

export default LineProgressGraph;
