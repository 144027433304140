import { axiosPrivate } from "../../api/axios";

// makeSpoc
export async function makeSpoc() {
  try {
    const res = await axiosPrivate.post("/api/v1/spoc/add-role");
    let finData = res.data;
    // console.log(res.data);
    return finData;
  } catch (e) {
    return null;
  }
}
