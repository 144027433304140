import useRefresh from "./useRefresh";
import { useSelector } from "react-redux";

const usePersist = (fetchData) => {
  const refresh = useRefresh();

  const verifyRefreshToken = async () => {
    try {
      const response = await refresh();
      return response?.authToken;
    } catch (err) {
      console.error(err);
    }
  };
  return verifyRefreshToken;
};

export default usePersist;
