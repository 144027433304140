import { axiosPrivate } from "../../api/axios";

// updateSchool
export async function updateSchool(data) {
  try {
    // console.log("data: ", data);
    const res = await axiosPrivate.post("api/v1/spoc/update-profile", data);
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Get profile data
export async function getProfile() {
  try {
    const res = await axiosPrivate.get("/api/v1/spoc/get-profile");
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Create Team
export async function createTeam(data) {
  try {
    const res = await axiosPrivate.post("/api/v1/spoc/team/add-team", data);
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// update Team
export async function updateTeam(data) {
  try {
    const res = await axiosPrivate.post(`/api/v1/spoc/team/update-team`, data);
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Get Team
export async function getTeam(data) {
  try {
    const res = await axiosPrivate.get(`/api/v1/spoc/team/get-team/${data}`);
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Add team Member
export async function addTeamMember(data) {
  try {
    const res = await axiosPrivate.post(
      "/api/v1/spoc/team-member/add-team-member",
      data
    );
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Update team Member
export async function updateTeamMember(data) {
  try {
    const res = await axiosPrivate.post(
      "/api/v1/spoc/team-member/update-team-member",
      data
    );
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Delete Team member
export async function deleteTeamMember(data) {
  try {
    const res = await axiosPrivate.post(
      "/api/v1/spoc/team-member/delete-team-member",
      data
    );
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Get teams
export async function getTeams(page, tier, submission, status, search) {
  try {
    const res = await axiosPrivate.get(
      `/api/v1/spoc/team/team-list?page=${page}&tier=${tier}&submissions=${submission}&status=${status}&search=${search}`
    );
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// get all Teams id
export async function getTeamsForSubmission() {
  try {
    const res = await axiosPrivate.get(`/api/v1/spoc/submission/get-teams`);
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Delete Team
export async function deleteTeamById(data) {
  try {
    const res = await axiosPrivate.post("/api/v1/spoc/team/delete-team", data);
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}
// Update team Member
export async function getNoticeBoard(data) {
  try {
    const res = await axiosPrivate.get("/api/v1/spoc/notice-board");
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Create Idea
export async function createNewIdea(data) {
  try {
    const res = await axiosPrivate.post(
      "/api/v1/spoc/submission/add-submission",
      data
    );
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Idea listing

export async function getIdeas(page, search,filter) {
  try {
    const res = await axiosPrivate.get(
      `/api/v1/spoc/submission/submission-list?page=${page}&search=${search}&status=${filter}`
    );
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Get a submission

export async function getIdea(id) {
  try {
    const res = await axiosPrivate.get(
      `/api/v1/spoc/submission/get-submission/${id}`
    );
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// edit Idea
export async function editIdea(data) {
  try {
    const res = await axiosPrivate.post(
      "/api/v1/spoc/submission/update-submission",
      data
    );
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Delete  Idea
export async function deleteIdea(data) {
  try {
    const res = await axiosPrivate.post(
      "/api/v1/spoc/submission/delete-submission",
      data
    );
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Get SPOC Stats

export async function getSPOCStats() {
  try {
    const res = await axiosPrivate.get(`/api/v1/spoc/stats`);
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}
// Get Nomination Listing

export async function getNominationListing() {
  try {
    const res = await axiosPrivate.get(
      `/api/v1/spoc/submission/nomination-listing`
    );
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}

// Nominate Idea
export async function nominateIdea(data) {
  try {
    const res = await axiosPrivate.post(
      "/api/v1/spoc/submission/nominate-submissions",
      data
    );
    let finData = res.data;
    return finData;
  } catch (e) {
    return null;
  }
}
