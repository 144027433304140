import { axiosPrivate } from "../../api/axios";

// accept Cookies
export const  acceptCookies = async()=>{
    try {
      const res = await axiosPrivate.post("/api/v1/auth/accept-cookie", { consent: true });
      let finData = res.data;
      return finData;
    } catch (e) {
      return null;
    }

}