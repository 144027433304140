import { h, Component } from "preact";
import Chart from "chart.js/auto";
import { useEffect, useRef } from "preact/hooks";

const PieChart = ({ data, label, colors }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    new Chart(ctx, {
      type: "pie",
      data: {
        labels: label,
        datasets: [
          {
            data: data,
            backgroundColor: colors
              ? colors
              : [
                  "rgba(21, 73, 203, 1)",
                  "rgba(49, 104, 242, 1)",
                  "rgba(108, 147, 245, 1)",
                  "rgba(165, 191, 255, 1)",
                  "rgba(239, 244, 251, 1)",
                ],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }, []);

  return <canvas ref={chartRef} />;
};

export default PieChart;
