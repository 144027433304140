import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  teamsAdded: false,
  submissionAdded: false,
  nomiantionAdded: false,
};

export const spocStepSlice = createSlice({
  name: "spocStep",
  initialState,
  reducers: {
    setTeamAdded: (state, action) => {
      state.teamsAdded = action.payload;
    },
    setSubmissionAdded: (state, action) => {
      state.submissionAdded = action.payload;
    },
    setNominationAdded: (state, action) => {
      state.nomiantionAdded = action.payload;
    },
  },
});

export const { setTeamAdded, setSubmissionAdded, setNominationAdded } =
  spocStepSlice.actions;

export default spocStepSlice.reducer;
