import Header from "../../../components/header";
import style from "./style.css";
import { useState } from "preact/hooks";
import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../components/pagination";
import Modal from "../../../components/modal";
import {
  getNominationListing,
  nominateIdea,
} from "../../../modules/spoc/actions";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const NominateTeam = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/spoc/manage-submissions");
  };
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [list, setList] = useState([]);
  const [showTango, setShowTango] = useState(true);
  const nomiantionAdded = useSelector(
    (state) => state.spocStep.nomiantionAdded
  );
  const fetchData = async () => {
    try {
      const response = await getNominationListing();

      if (response?.success === true) {
        // console.log(response);
        setList(response?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const [checkedItems, setCheckedItems] = useState({});
  const [checkedTeamName, setCheckedTeamName] = useState({});
  const [checkedTitle, setCheckedTitle] = useState({});

  const handleCheckboxChange = (event, title, teamName) => {
    const { name, checked } = event.target;
    // console.log(name, checked);
    setCheckedItems((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
    setCheckedTeamName((prevState) => ({
      ...prevState,
      [name]: teamName,
    }));
    setCheckedTitle((prevState) => ({
      ...prevState,
      [name]: title,
    }));
  };

  const openNominationModal = () => {
    if (
      Object.keys(checkedItems).filter((key) => checkedItems[key]).length < 1
    ) {
      toast.error("Select Ideas to nominate");
    } else {
      setConfirmationModalOpen(true);
    }
  };
  const nominateTeams = async () => {
    try {
      const data = {
        submissions: Object.keys(checkedItems).filter(
          (key) => checkedItems[key]
        ),
      };
      // console.log("data mera yeh h: ", data);
      const response = await nominateIdea(data);
      // console.log(response);
      if (response?.success === true) {
        setConfirmationModalOpen(false);
        goBack();
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Header page="spoc-dashboard" />
      <img
        src="https://saxenaprachi.github.io/publicAssets/images/shuttleRocket.webp"
        className={style.rocketRight}
      />
      <div className={style.noticeText + " " + style.dangerTheme}>
        <img src="https://saxenaprachi.github.io/publicAssets/home/alert.png" />
        <span>
          ATTENTION! Actions made in this section are irreversible . Nominate
          Carefully!
        </span>
      </div>
      <div className={style.pageContainer}>
        <div className={style.formConatiner}>
          <div onClick={goBack} className={style.close}>
            <Icon icon="maki:cross" />
          </div>
          {/* Heading */}
          <div className={style.Heading}> Nominate Idea</div>
          <p className={style.description}>
            Select the " <input type="checkbox" checked disabled /> " checkboxes
            below to nominate ideas for the final evaluation, and then click the
            "Nominate Idea" button to confirm.
          </p>
          <br />
          {/* TableConatiner */}
          <div className={style.tableContainer}>
            {/* Header */}
            <div className={style.header}>
              <div>TEAM NAME/TRACK</div>
              <div>IDEA</div>
              <div>CATEGORY</div>
              <div>THEME</div>
              <div>PREVIEW</div>
            </div>
            {/* Body */}
            {list.map((item, key) => (
              <div className={style.nominationItem} key={key}>
                <div className={`${style.teamNameConatier}`}>
                  <span className={style.turncate}>{item.teamName}</span> &nbsp;
                  ({item.tier === "Tier-1" ? "Track 1" : "Track 2"})
                </div>
                <div className={style.ideasFromTeam}>
                  {item.submissions.map((submission, key) => (
                    <div className={style.ideaContainer} key={key}>
                      <div className={style.trackName}>
                        <div>
                          <input
                            type="checkbox"
                            name={submission._id}
                            onChange={(e) => {
                              handleCheckboxChange(
                                e,
                                submission.title,
                                item.teamName
                              );
                            }}
                          />
                        </div>
                        <div>
                          <span className={style.turncate}>
                            {submission.title}
                          </span>
                        </div>
                      </div>
                      <div>{submission.category}</div>
                      <div>{submission.theme}</div>
                      <div>
                        <Link
                          className={`clickable`}
                          to={submission.document}
                          target="_blank"
                        >
                          Preview
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className={style.paginationContainer}>
            <div className={`btn orangeBtn`} onClick={openNominationModal}>
              NOMINATE IDEA
            </div>
          </div>
        </div>
      </div>
      {confirmationModalOpen && (
        <>
          <Modal
            close={() => {
              setConfirmationModalOpen(false);
            }}
            type={"nominateTeams"}
            objectToTeamName={checkedTeamName}
            submissionIds={Object.keys(checkedItems).filter(
              (key) => checkedItems[key]
            )}
            submissionTitles={checkedTitle}
            nominateIdeaSubmission={nominateTeams}
          />
        </>
      )}
      {showTango && !nomiantionAdded && (
        <div className={style.modal}>
          <div className={style.modal_content}>
            <div className={style.headerOfModal}>
              <h4>Platform Guide - SIH Junior</h4>
              <span
                className={style.close}
                onClick={() => {
                  setShowTango(false);
                }}
              >
                &times;
              </span>
            </div>
            <div>
              <iframe
                src="https://app.tango.us/app/embed/f2f30870-39a6-4d03-9e04-b944d87f74be"
                sandbox="allow-scripts allow-top-navigation-by-user-activation allow-popups allow-same-origin"
                security="restricted"
                title="Nominating Ideas for SIH Hackathon"
                width="100%"
                height="100%"
                referrerpolicy="strict-origin-when-cross-origin"
                frameborder="0"
                webkitallowfullscreen="webkitallowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                allowfullscreen="allowfullscreen"
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NominateTeam;
