import { h, Component } from "preact";
import { Icon } from "@iconify/react";
import style from "./style.css";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (page) => {
    onPageChange(page);
  };

  const renderPaginationButtons = () => {
    const pageButtons = [];
    const maxButtonsToShow = 5;
    if (totalPages <= maxButtonsToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageButtons.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            disabled={i === currentPage}
            className={i === currentPage ? style.active : ""}
          >
            {i}
          </button>
        );
      }
    } else {
      let start = Math.max(1, currentPage - 2);
      let end = Math.min(start + maxButtonsToShow - 1, totalPages);

      if (start > 1) {
        pageButtons.push(
          <button
            key="1"
            onClick={() => handlePageChange(1)}
            className={1 === currentPage ? style.active : ""}
          >
            1
          </button>
        );

        if (start > 2) {
          pageButtons.push(<span key="ellipsis-start">...</span>);
        }
      }

      for (let i = start; i <= end; i++) {
        pageButtons.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            disabled={i === currentPage}
            className={i === currentPage ? style.active : ""}
          >
            {i}
          </button>
        );
      }

      if (end < totalPages) {
        if (end < totalPages - 1) {
          pageButtons.push(<span key="ellipsis-end">...</span>);
        }

        pageButtons.push(
          <button key={totalPages} onClick={() => handlePageChange(totalPages)}>
            {totalPages}
          </button>
        );
      }
    }

    return pageButtons;
  };

  return (
    <div className="pagination">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={style.prevNextBtn}
      >
        Prev
      </button>
      {renderPaginationButtons()}
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={style.prevNextBtn}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
