import React from "react";

const Switch = ({ isOn, handleToggle, name, colorOne, colorTwo }) => {
  return (
    <>
      <input
        defaultChecked={isOn}
        onChange={handleToggle}
        className="switch-checkbox"
        id={`switch`}
        type="checkbox"
        name={name}
      />
      <label
        style={{ background: isOn ? colorOne : colorTwo }}
        className="switch-label"
        htmlFor={`switch`}
      >
        <span className={`switch-button`} />
      </label>
    </>
  );
};

export default Switch;
