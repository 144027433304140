import useAuthAxiosPrivate from "./useAuthAxiosPrivate";
import { useSelector, useDispatch } from "react-redux";
import { setuser, setAccessToken } from "../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const axiosPrivate = useAuthAxiosPrivate();
  const logout = async () => {
    dispatch(
      setuser({
        email: "",
        accessToken: "",
        role: "",
      })
    );
    try {
      const response = await axiosPrivate.get("api/v1/auth/logout");
      if (response.data.success === true) {
        dispatch(setAccessToken(""));
        localStorage.removeItem("isAuth");
        localStorage.removeItem("teamId");
        if (window.location.pathname === "/") {
          window.location.reload();
        } else {
          navigate("/");
        }
      } else {
        dispatch(setAccessToken(""));
        localStorage.removeItem("isAuth");
        localStorage.removeItem("teamId");
        if (window.location.pathname === "/") {
          window.location.reload();
        } else {
          navigate("/");
        }
      }
    } catch (err) {
      console.error(err.code);
    }
  };

  return logout;
};
export default useLogout;
