import { h, Component } from "preact";
import Chart from "chart.js/auto";
import { useEffect, useRef } from "preact/hooks";

const DonutChart = ({ label, data, colors, cutout }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: label,
        datasets: [
          {
            data: data,
            backgroundColor: colors
              ? colors
              : [
                  "rgba(21, 73, 203, 1)",
                  "rgba(49, 104, 242, 1)",
                  "rgba(108, 147, 245, 1)",
                  "rgba(165, 191, 255, 1)",
                  "rgba(239, 244, 251, 1)",
                ],
            borderWidth: 0,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutout: cutout ? cutout : "85%",

        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }, []);

  return <canvas ref={chartRef} />;
};

export default DonutChart;
