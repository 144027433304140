import { h, Component } from "preact";
import style from "./style.css";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "preact/hooks";
import { useLoading } from "../../hooks/useLoading";
import GlobalLoader from "../globalLoader";
import CookieConsent from "../cookieConsent";

const Footer = () => {
  const phoneNumberFirst = "tel:+91 81306 17707";
  const phoneNumberSecond = "tel:+916388554380";
  const emailFirst = "mailto:schoolinnovation@aicte-india.org";
  // const emailSecond = "mailto:hackathon@aicte-india.org";
  // const emailSupportH2S = "mailto:junior-sih@hack2skill.com";

  const [selectedSection, setSelectedSection] = useState("");
  const globalLoader = useLoading();

  const handleLinkClick = (section) => {
    setSelectedSection(section);
    setTimeout(() => {
      const targetElement = document.getElementById(section);
      if (targetElement) {
        const headerHeight = document.querySelector("header").offsetHeight;
        const targetPosition = targetElement.offsetTop - headerHeight;
        window.scrollTo({
          top: targetPosition,
          behavior: "smooth",
        });
      }
    }, 10); 
  };

  return (
    <>
      {globalLoader && <GlobalLoader />}
      <CookieConsent />
      <div className={style.footer}>
        <div className={style.footerLeft}>
          <div className={style.footerContent}>
            <div className={style.footerHeading}>Follow Us</div>
            <div className={style.socialContainer}>
              <Link
                target="_blank"
                to="https://www.facebook.com/mhrdInnovation/"
              >
                <Icon icon="ri:facebook-fill" />
              </Link>
              <Link
                to="https://www.instagram.com/mhrd.innovationcell/"
                target="_blank"
              >
                <Icon icon="ri:instagram-line" />
              </Link>
              <Link to="https://twitter.com/SIH2023_MIC" target="_blank">
                <Icon icon="simple-icons:x" />
              </Link>
            </div>
            <div className={style.copyrightReal}>
              <Icon icon="mingcute:copyright-line" /> &nbsp;2023-24 Smart India
              Hackathon. All Rights Reserved
            </div>
          </div>
          <div>
            <img
              src="https://saxenaprachi.github.io/publicAssets/icons/sih.webp"
              alt="sih"
            />
          </div>
        </div>
        <div className={style.footerContent}>
          <div className={style.footerHeading}>Contact Us</div>
          {/* <div className={style.copyright}>
            <Icon className="textBlue" icon="mdi:phone" /> &nbsp;
            <Link to={phoneNumberFirst}>+91 81306 17707</Link>
            <span className={style.comma}> , </span>
            <Link to={phoneNumberSecond}>+91 63885 54380</Link>
          </div> */}

          <small className={style.supportEmails}>For Support:</small>
          <div className={style.copyright}>
            <Icon className="textBlue" icon="ic:round-email" />
            &nbsp; <Link to={emailFirst}>schoolinnovation@aicte-india.org</Link>{" "}
            {/* <span className={style.comma}> , </span>{" "}
            <Link to={emailSecond}> hackathon@aicte-india.org </Link> */}
          </div>
          {/* <small className={style.supportEmails}>For Technical Support:</small>
          <div className={style.copyright}>
            <Icon className="textBlue" icon="ic:round-email" />
            &nbsp; <Link to={emailSupportH2S}>junior-sih@hack2skill.com</Link>
          </div> */}
        </div>
        <div className={style.copyrightReal}>
          <Icon icon="mingcute:copyright-line" /> &nbsp;2023-24 Smart India
          Hackathon. All Rights Reserved
        </div>
      </div>
    </>
  );
};

export default Footer;