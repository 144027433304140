import { h, Component } from "preact";
import style from "./style.css";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import { getPlatformStats } from "../../../modules/sih-admin/actions";
import { useEffect, useState } from "react";
import PieChart from "../../../components/graphs/pieChart";
import BarChart from "../../../components/graphs/barChart";
import DonutChart from "../../../components/graphs/donutChart";
import LineProgressGraph from "../../../components/graphs/lineProgressChart";
import MapChart from "../../../components/graphs/MapChart";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../../redux/slices/loadingSlice";

const SihAdminDashboard = () => {
  const [statCard, setStatCard] = useState([
    {
      title: "No. of SPOCs",
      img: "https://saxenaprachi.github.io/publicAssets/admin-stats/spoc.png",
      total: 15,
      today: "",
    },
    {
      title: "No. of Teams",
      img: "https://saxenaprachi.github.io/publicAssets/admin-stats/team.png",
      total: 15,
      today: 2,
    },
    {
      title: "No. of Submissions",
      img: "https://saxenaprachi.github.io/publicAssets/admin-stats/mentor.png",
      total: 15,
      today: 2,
    },
    {
      title: "No. of Participants",
      img: "https://saxenaprachi.github.io/publicAssets/admin-stats/participants.svg",
      total: 15,
      today: "",
    },
  ]);
  const [displayRow4, setDisplayRow4] = useState(false);
  const [row4, setRow4] = useState([
    {
      title: "Submission Count Distribution across teams",
      displayLabel1: "0 Submissions",
      displayLabel2: "1 Submission",
      displayLabel3: "2 Submissions",
      displayLabel4: "3 Submissions",
      data: [0, 0, 0, 0],
      label: [
        "0 Submissions",
        "1 Submission",
        "2 Submissions",
        "3 Submissions",
      ],
    },
    {
      title: "Member count distribution across teams",
      displayLabel1: "3 Members",
      displayLabel2: "4 Members",
      displayLabel3: "5 Members",
      displayLabel4: "6 Members",
      data: [0, 0, 0, 0],
      label: ["3 Members", "4 Members", "5 Members", "6 Members"],
    },
  ]);
  const [pieChartCard, setPieChartCard] = useState([
    {
      title: "Team Stats",
      displayLabel1: "Not Nominated - Track 1",
      displayLabel2: "Nominated - Track 1",
      displayLabel3: "Not Nominated - Track 2",
      displayLabel4: "Nominated - Track 2",
      data: [45, 30, 23, 33],
      label: [
        "Track 1 - Not Nominated",
        "Track 1 - Nominated",
        "Track 2 - Not Nominated",
        "Track 2 - Nominated",
      ],
    },
    {
      title: "Submission Stats",
      displayLabel1: "Not Nominated - Track 1",
      displayLabel2: "Nominated - Track 1",
      displayLabel3: "Not Nominated - Track 2",
      displayLabel4: "Nominated - Track 2",
      data: [45, 30, 23, 33],
      label: [
        "Track 1 - Not Nominated",
        "Track 1 - Nominated",
        "Track 2 - Not Nominated",
        "Track 2 - Nominated",
      ],
    },
  ]);
  const [participantsByClass, setParticipantsByClass] = useState({
    label: ["6", "7", "8", "9", "10", "11", "12"],
    data: [10, 30, 50, 23, 58, 12, 56],
  });
  const [participantsByStream, setParticipantsByStream] = useState({
    label: ["Science", "Commerce", "Arts", "Others"],
    data: [10, 30, 50, 23],
  });
  const [participantsByGender, setParticipantsByGender] = useState({
    label: ["Male", "Female"],
    data: [10, 30],
  });
  const [spocReqDisplay, setSPOCReqDisplay] = useState(false);
  const [spocReqStats, setSpocReqStats] = useState({
    title: "SPOC Request Distribution",
    data: [45, 30, 22, 43],
    label: ["Verified", "Rejected", "Reapplied", "Pending"],
  });
  const [STATES, setSTATES] = useState({
    "Andaman & Nicobar Island": {
      value: 0,
    },
    "Andhra Pradesh": {
      value: 0,
    },
    "Arunanchal Pradesh": {
      value: 0,
    },
    Assam: {
      value: 0,
    },
    Bihar: {
      value: 0,
    },
    Chandigarh: {
      value: 0,
    },
    Chhattisgarh: {
      value: 0,
    },
    Delhi: {
      value: 0,
    },
    Goa: {
      value: 0,
    },
    Gujarat: {
      value: 0,
    },
    Haryana: {
      value: 0,
    },
    "Himachal Pradesh": {
      value: 0,
    },
    "Jammu & Kashmir": {
      value: 0,
    },
    Jharkhand: {
      value: 0,
    },
    Karnataka: {
      value: 0,
    },
    Kerala: {
      value: 0,
    },
    Lakshadweep: {
      value: 0,
    },
    "Madhya Pradesh": {
      value: 0,
    },
    Maharashtra: {
      value: 0,
    },
    Manipur: {
      value: 0,
    },
    Meghalaya: {
      value: 0,
    },
    Mizoram: {
      value: 0,
    },
    Nagaland: {
      value: 0,
    },
    Odisha: {
      value: 0,
    },
    Puducherry: {
      value: 0,
    },
    Punjab: {
      value: 0,
    },
    Rajasthan: {
      value: 0,
    },
    Sikkim: {
      value: 0,
    },
    "Tamil Nadu": {
      value: 0,
    },
    Telangana: {
      value: 0,
    },
    Tripura: {
      value: 0,
    },
    "Uttar Pradesh": {
      value: 0,
    },
    Uttarakhand: {
      value: 0,
    },
    "West Bengal": {
      value: 0,
    },
  });
  const [noOfSPOCs, setNoOfSPOCs] = useState(1000);
  const display = (page) => {
    let StateStats = [];
    let keys = Object.keys(STATES);
    for (let i = (page - 1) * 10; i < (page - 1) * 10 + 10; i++) {
      StateStats.push(
        <>
          {STATES[keys[i]] && (
            <div className={style.verticalStatContiner} key={i}>
              <div className={style.verticalStatTitle}>{keys[i]}</div>
              <div className={style.numberAndProgressContainer}>
                {STATES[keys[i]]?.value} &nbsp;
                <LineProgressGraph
                  progress={(STATES[keys[i]]?.value / noOfSPOCs) * 100}
                />
              </div>
            </div>
          )}
        </>
      );
    }
    return StateStats;
  };
  const [statesPage, setStatesPage] = useState(1);

  const settingSPOCData = (spocByStatus) => {
    let verifiedSPOC = 0;
    let notVerifiedSPOC = 0;
    let rejectedSPOC = 0;
    let reappliedSPOC = 0;
    let pendignSPOC = 0;

    for (let i = 0; i < 5; i++) {
      if (spocByStatus[i]?._id == "IN REVIEW") {
        pendignSPOC = spocByStatus[i]?.count;
      }
      if (spocByStatus[i]?._id == "REAPPLIED") {
        reappliedSPOC = spocByStatus[i]?.count;
      }
      if (spocByStatus[i]?._id == "REJECTED") {
        rejectedSPOC = spocByStatus[i]?.count;
      }
      if (spocByStatus[i]?._id == "VERIFIED") {
        verifiedSPOC = spocByStatus[i]?.count;
      }
    }
    setSpocReqStats((prevValues) => {
      return {
        ...prevValues,
        data: [verifiedSPOC, rejectedSPOC, reappliedSPOC, pendignSPOC],
      };
    });
    setSPOCReqDisplay(true);
    let newStatsCard = [...statCard];
    newStatsCard[0]["total"] = verifiedSPOC;

    setStatCard(newStatsCard);
    setNoOfSPOCs(verifiedSPOC);
  };
  const settingStateData = (stateData) => {
    let newStates = STATES;
    for (let i = 0; i < stateData.length; i++) {
      newStates[stateData[i]._id] = { value: stateData[i].count };
    }
    setSTATES(newStates);
  };
  const [row2Display, setRow2Display] = useState(false);
  const settingDataByTier = (teamTierData, submissionTierData) => {
    let teamData = pieChartCard[0];
    let submissionData = pieChartCard[1];
    let teamArray = [0, 0];
    let submissionArray = [0, 0];
    let teamsToday = 0;
    let submissionsToday = 0;

    for (let i = 0; i < 2; i++) {
      if (teamTierData[i]._id === "Tier-1") {
        teamArray[0] = teamTierData[i].total - teamTierData[i].nominated;
        teamArray[1] = teamTierData[i].nominated;
        teamsToday += teamTierData[i].today;
      }
      if (teamTierData[i]._id === "Tier-2") {
        teamArray[2] = teamTierData[i].total - teamTierData[i].nominated;
        teamArray[3] = teamTierData[i].nominated;
        teamsToday += teamTierData[i].today;
      }
      if (submissionTierData[i]._id === "Tier-1") {
        submissionArray[0] =
          submissionTierData[i].total - submissionTierData[i].nominated;
        submissionArray[1] = submissionTierData[i].nominated;
        submissionsToday += submissionTierData[i].today;
      }
      if (submissionTierData[i]._id === "Tier-2") {
        submissionArray[2] =
          submissionTierData[i].total - submissionTierData[i].nominated;
        submissionArray[3] = submissionTierData[i].nominated;
        submissionsToday += submissionTierData[i].today;
      }
    }
    teamData.data = teamArray;
    submissionData.data = submissionArray;
    setPieChartCard([teamData, submissionData]);
    setRow2Display(true);

    // Today Data
    let newStatsCard = [...statCard];
    newStatsCard[1]["today"] = teamsToday;
    newStatsCard[1]["total"] = teamArray.reduce((acc, curr) => acc + curr, 0);
    newStatsCard[2]["today"] = submissionsToday;
    newStatsCard[2]["total"] = submissionArray.reduce(
      (acc, curr) => acc + curr,
      0
    );
    setStatCard(newStatsCard);
  };

  const [chartDisplay, setchartDisplay] = useState(false);
  const settingParticipantsByClass = (participantsByClasses) => {
    let newParticipantsByClass = participantsByClass;
    let newData = [0, 0, 0, 0, 0, 0, 0];
    for (let i = 0; i < participantsByClasses.length; i++) {
      if (participantsByClasses[i]._id === "6") {
        newData[0] = participantsByClasses[i].total;
      }
      if (participantsByClasses[i]._id === "7") {
        newData[1] = participantsByClasses[i].total;
      }
      if (participantsByClasses[i]._id === "8") {
        newData[2] = participantsByClasses[i].total;
      }
      if (participantsByClasses[i]._id === "9") {
        newData[3] = participantsByClasses[i].total;
      }
      if (participantsByClasses[i]._id === "10") {
        newData[4] = participantsByClasses[i].total;
      }
      if (participantsByClasses[i]._id === "11") {
        newData[5] = participantsByClasses[i].total;
      }
      if (participantsByClasses[i]._id === "12") {
        newData[6] = participantsByClasses[i].total;
      }
    }
    newParticipantsByClass.data = newData;
    setParticipantsByClass(newParticipantsByClass);
    setchartDisplay(true);
  };
  const [streamChartDisplay, setStreamChartDisplay] = useState(false);
  const settingParticipantsByStream = (participantsByStreams) => {
    let newParticipantsByStream = participantsByStream;
    let newData = [0, 0, 0, 0, 0, 0, 0];
    for (let i = 0; i < participantsByStreams.length; i++) {
      if (participantsByStreams[i]._id === "Science") {
        newData[0] = participantsByStreams[i].total;
      }
      if (participantsByStreams[i]._id === "Commerce") {
        newData[1] = participantsByStreams[i].total;
      }
      if (participantsByStreams[i]._id === "Arts") {
        newData[2] = participantsByStreams[i].total;
      }
      if (participantsByStreams[i]._id === "Others") {
        newData[3] = participantsByStreams[i].total;
      }
    }
    newParticipantsByStream.data = newData;
    setParticipantsByStream(newParticipantsByStream);
    setStreamChartDisplay(true);
  };

  const [genderChartDisplay, setGenderChartDisplay] = useState(false);
  const settingParticipantsByGender = (participantsByGenders) => {
    let newParticipantsByGender = participantsByGender;
    let newData = [0, 0];
    for (let i = 0; i < participantsByGenders.length; i++) {
      if (participantsByGenders[i]._id.gender === "Male") {
        newData[0] += participantsByGenders[i].total;
      }
      if (participantsByGenders[i]._id.gender === "Female") {
        newData[1] += participantsByGenders[i].total;
      }
    }
    newParticipantsByGender.data = newData;
    setParticipantsByGender(newParticipantsByGender);
    setGenderChartDisplay(true);

    // Participants Number
    let newStatsCard = [...statCard];
    newStatsCard[3]["total"] = newData.reduce((acc, curr) => acc + curr, 0);

    setStatCard(newStatsCard);
  };
  const settingRow4Data = (submissionDistribution, memberDistribution) => {
    let submisionData = { ...row4[0] };
    let memberData = { ...row4[1] };
    for (let i = 0; i < 4; i++) {
      if (submissionDistribution[i]?._id === 0) {
        submisionData.data[0] = submissionDistribution[i]?.total;
      }
      if (submissionDistribution[i]?._id === 1) {
        submisionData.data[1] = submissionDistribution[i]?.total;
      }
      if (submissionDistribution[i]?._id === 2) {
        submisionData.data[2] = submissionDistribution[i]?.total;
      }
      if (submissionDistribution[i]?._id === 3) {
        submisionData.data[3] = submissionDistribution[i]?.total;
      }
      if (memberDistribution[i]?._id === 3) {
        memberData.data[0] = memberDistribution[i]?.total;
      }
      if (memberDistribution[i]?._id === 4) {
        memberData.data[1] = memberDistribution[i]?.total;
      }
      if (memberDistribution[i]?._id === 5) {
        memberData.data[2] = memberDistribution[i]?.total;
      }
      if (memberDistribution[i]?._id === 6) {
        memberData.data[3] = memberDistribution[i]?.total;
      }
    }
    setRow4([submisionData, memberData]);
    setDisplayRow4(true);
  };
  const [mainLoading, setMainLoading] = useState(false);
  const dispatch = useDispatch();
  const fetchData = async () => {
    dispatch(setGlobalLoading(true));
    // setMainLoading(true);
    try {
      const response = await getPlatformStats();
      if (response?.success === true) {
        settingSPOCData(response?.data.spocStatusWiseDistribution);
        settingStateData(response?.data.spocStateWiseDistribution);
        settingParticipantsByClass(
          response?.data.memberDistributionAcrossClass
        );
        settingParticipantsByStream(
          response?.data.memberDistributionAcrossStream
        );
        settingDataByTier(
          response?.data.teamDistributionAcrossTier,
          response?.data.submissionDistributionAcrossTier
        );
        settingParticipantsByGender(
          response?.data.memberDistributionAcrossGender
        );
        settingRow4Data(
          response?.data.submissionDistributionAcrossTeams,
          response?.data.memberDistributionAcrossTeams
        );
      }
    } catch (e) {
      console.log(e);
      dispatch(setGlobalLoading(false));
    }
    // setMainLoading(false);
    dispatch(setGlobalLoading(false));
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <>
        <Header />
        <div className={style.main}>
          <div className={style.sideBarConatiner}>
            <Sidebar role="sih-admin" activeTab="dashboard" />
          </div>
          <div className={style.mainContent}>
            {mainLoading ? (
              <></>
            ) : (
              <>
                <h3 className={style.title}>SIH Admin Dashboard</h3>
                <div className={style.graphsContainer}>
                  {/* Stat Cards */}
                  <div className={style.statCardsConatiner}>
                    {statCard.map((stat, key) => (
                      <div className={style.statsCard}>
                        <div className={style.statsIcon}>
                          <img src={stat.img} width="20" />
                        </div>
                        <div className={style.statInfo}>
                          <p>{stat.title}</p>
                          <div className={style.statArray}>
                            <div className={style.statItem}>
                              <div className={style.number}>{stat.total}</div>
                              <p>Total</p>
                            </div>
                            <div className={style.statItem}>
                              <div className={style.number}>{stat.today}</div>
                              <p>{stat?.today === "" ? "" : "Today"}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* Row 2 */}
                  {row2Display && (
                    <div className={style.statRow}>
                      {pieChartCard.map((card, key) => (
                        <>
                          <div className={`${style.statsCard}`} key={key}>
                            <div className={`${style.numericStats}`}>
                              <h4>{card.title}</h4>
                              <div>
                                <div className={style.individualStat}>
                                  <div className={style.statHeading}>
                                    {card.displayLabel1}
                                  </div>
                                  <div className={style.statCount}>
                                    {card.data[0]}
                                  </div>
                                </div>
                                <div className={style.individualStat}>
                                  <div
                                    className={`${style.statHeading} ${style.two}`}
                                  >
                                    {card.displayLabel2}
                                  </div>
                                  <div
                                    className={`${style.statCount} ${style.two}`}
                                  >
                                    {card.data[1]}
                                  </div>
                                </div>
                                <div className={style.individualStat}>
                                  <div
                                    className={`${style.statHeading} ${style.three} ${style.custom}`}
                                  >
                                    {card.displayLabel3}
                                  </div>
                                  <div
                                    className={`${style.statCount} ${style.three} ${style.custom}`}
                                  >
                                    {card.data[2]}
                                  </div>
                                </div>
                                <div className={style.individualStat}>
                                  <div
                                    className={`${style.statHeading} ${style.four} ${style.custom}`}
                                  >
                                    {card.displayLabel4}
                                  </div>
                                  <div
                                    className={`${style.statCount} ${style.four} ${style.custom}`}
                                  >
                                    {card.data[3]}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={style.graphContiner}>
                              <PieChart
                                data={card.data}
                                label={card.label}
                                colors={[
                                  "rgba(21, 73, 203, 1)",
                                  "rgba(49, 104, 242, 1)",
                                  "#5F9D80",
                                  "#17643F",
                                ]}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  )}
                  {/* Row 3 */}
                  <div className={style.statRow}>
                    <div className={`${style.statsCard}`}>
                      <div className={`${style.numericStats}`}>
                        <h4>Participants By Class</h4>
                        <p className={style.barChartContainer}>
                          {chartDisplay && (
                            <BarChart
                              label={participantsByClass.label}
                              data={participantsByClass.data}
                            />
                          )}
                        </p>
                      </div>
                    </div>
                    <div className={style.halfScreenContainer}>
                      <div className={`${style.statsCard} `}>
                        <div className={`${style.numericStats}`}>
                          <h4>Participants By Stream</h4>
                          <p className={style.barChartContainer}>
                            {streamChartDisplay && (
                              <BarChart
                                label={participantsByStream.label}
                                data={participantsByStream.data}
                              />
                            )}
                          </p>
                        </div>
                      </div>
                      <div className={`${style.statsCard} `}>
                        <div className={`${style.numericStats}`}>
                          <h4>Participants By Gender</h4>
                          <p className={style.barChartContainer}>
                            {genderChartDisplay && (
                              <BarChart
                                label={participantsByGender.label}
                                data={participantsByGender.data}
                              />
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Row 4 */}
                  <div className={style.statRow}>
                    {displayRow4 &&
                      row4.map((card, key) => (
                        <>
                          <div className={`${style.statsCard}`} key={key}>
                            <div className={`${style.numericStats}`}>
                              <h4>{card.title}</h4>
                              <div>
                                <div className={style.individualStat}>
                                  <div className={style.statHeading}>
                                    {card.displayLabel1}
                                  </div>
                                  <div className={style.statCount}>
                                    {" "}
                                    {card.data[0]}
                                  </div>
                                </div>
                                <div className={style.individualStat}>
                                  <div
                                    className={`${style.statHeading} ${style.two}`}
                                  >
                                    {card.displayLabel2}
                                  </div>
                                  <div
                                    className={`${style.statCount} ${style.two}`}
                                  >
                                    {card.data[1]}
                                  </div>
                                </div>
                                <div className={style.individualStat}>
                                  <div
                                    className={`${style.statHeading} ${style.three}`}
                                  >
                                    {card.displayLabel3}
                                  </div>
                                  <div
                                    className={`${style.statCount} ${style.three}`}
                                  >
                                    {card.data[2]}
                                  </div>
                                </div>
                                <div className={style.individualStat}>
                                  <div
                                    className={`${style.statHeading} ${style.four}`}
                                  >
                                    {card.displayLabel4}
                                  </div>
                                  <div
                                    className={`${style.statCount} ${style.four}`}
                                  >
                                    {card.data[3]}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={style.graphContiner}>
                              <PieChart data={card.data} label={card.label} />
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                  {/* Row 5 */}
                  <div className={`${style.statRow} ${style.last}`}>
                    {/* SPOC REQ STATS */}
                    {spocReqDisplay && (
                      <div className={`${style.statsCard} ${style.spocReqDis}`}>
                        <div className={`${style.numericStats}`}>
                          <h4>{spocReqStats.title}</h4>
                          <div className={style.graphAndStatContainer}>
                            <div className={style.graphContiner}>
                              <DonutChart
                                label={spocReqStats.label}
                                data={spocReqStats.data}
                              />
                              <div className={style.donutGraphTitle}>
                                <div className={style.title}>Total SPOC</div>
                                <div className={style.totalDisplay}>
                                  {spocReqStats.data.reduce(
                                    (total, current) => total + current,
                                    0
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className={style.verticalStats}>
                              {spocReqStats.label.map((l, key) => (
                                <div
                                  className={style.verticalStatContiner}
                                  key={key}
                                >
                                  <div className={style.verticalStatTitle}>
                                    {l}
                                  </div>
                                  <div
                                    className={style.numberAndProgressContainer}
                                  >
                                    {spocReqStats.data[key]} &nbsp;
                                    <LineProgressGraph
                                      progress={
                                        (spocReqStats.data[key] /
                                          spocReqStats.data.reduce(
                                            (total, current) => total + current,
                                            0
                                          )) *
                                        100
                                      }
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className={style.donutLegendContainer}>
                            {spocReqStats.label.map((l, key) => (
                              <div
                                className={`${style.legendTitle} ${
                                  key === 1
                                    ? style.two
                                    : key === 2
                                    ? style.three
                                    : key === 3
                                    ? style.four
                                    : ""
                                }`}
                                key={key}
                              >
                                {l}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className={`${style.statRow} ${style.last} ${style.stateMapRow}`}
                  >
                    {/* MAP STATS */}
                    <div className={`${style.statsCard}`}>
                      <div className={`${style.numericStats} `}>
                        <h4>State wise SPOC distribution</h4>
                        <div
                          className={`${style.graphAndStatContainer} ${style.stateSpoc}`}
                        >
                          <div className={style.graphContiner}>
                            <MapChart STATES={STATES} />
                          </div>
                          <div className={style.verticalStats}>
                            {display(statesPage)}
                          </div>
                        </div>
                        <div className={style.donutLegendContainer}>
                          <div
                            onClick={() => {
                              if (statesPage > 1) {
                                setStatesPage(statesPage - 1);
                              }
                            }}
                          >
                            <Icon icon="grommet-icons:form-previous" />
                          </div>
                          <div>
                            Showing {(statesPage - 1) * 10 + 1} -{" "}
                            {(statesPage - 1) * 10 + 10 == 40
                              ? 37
                              : (statesPage - 1) * 10 + 10}{" "}
                            of 37 Regions
                          </div>
                          <div
                            onClick={() => {
                              if (statesPage < 4) {
                                setStatesPage(statesPage + 1);
                              }
                            }}
                          >
                            <Icon icon="ic:round-navigate-next" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default SihAdminDashboard;
