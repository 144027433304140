import { Icon } from '@iconify/react';
import style from "./style.css";
import { Link } from 'react-router-dom';
import { acceptCookies } from '../../modules/user/actions';
import { useState } from 'preact/hooks';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
const CookieConsent = () => {
    const [consent, setConsent] = useState(false);
    const auth = useSelector((state) => state.user.accessToken);
    const acceptAndClose = async () => {
        try {
            const response = await acceptCookies();
            console.log(response);
            if (response?.success === true) {
                window.localStorage.setItem('consent', true);
                window.localStorage.setItem('consent-uploaded', true);
                setConsent(true);
            } else {
                window.localStorage.setItem('consent', true);
                window.localStorage.setItem('consent-uploaded', false);
                setConsent(true);
            }

        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        let con = window.localStorage.getItem('consent');
        if (con && con != 'false') {
            setConsent(true);
        }
    }, [])
    useEffect(() => {
        let con = window.localStorage.getItem('consent');
        let conUploaded = window.localStorage.getItem('consent-uploaded');
        if (auth) {
            if (con && con != 'false') {
                if (conUploaded === 'false') {
                    setConsent(true);
                    acceptAndClose();
                }
            }
        }
    }, [auth])
    return (
        <>
            {!consent && (
                <>
                    <div className={style.overlay}></div>
                    <div className={style.cookieConsentMain}>
                        <Icon icon="material-symbols-light:cookie-outline" />
                        <p>
                            We use cookies to personalize our website and offerings to your interests and for measurement and
                            analytics purposes. By using our website and our products, you agree to our use of cookies.
                            {/* <Link to="" className={style.learnMore} target='_blank'>Learn More</Link> */}
                        </p>
                        <div className={`btn btnWhite ${style.acceptBtn}`} onClick={acceptAndClose}>ACCEPT AND CLOSE</div>
                    </div>
                </>
            )}
        </>
    )
}

export default CookieConsent;